import { FetchResult, MutationFunctionOptions, gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import * as React from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { AppText, Region } from "../../UI";
import { LiveCallSegment } from "./LiveCallSegment";
import { WaveformV2 } from "./WaveformV2";
import { useFlags } from "launchdarkly-react-client-sdk";

const SEND_REP_FEEDBACK = gql`
  mutation sendReviewCallReportToRep($conference_id: String!) {
    sendReviewCallReportToRep(conference_id: $conference_id)
  }
`;

interface Lead {
  id: string;
  business_name: string;
  channel: string;
  first_name: string;
  last_name: string;
  industry_label: string;
  lead_source_label: string;
  current_lead_type: string;
  call_notes: { created_at: string; notes: string; id: string }[];
}

interface CallReportProps {
  coachingNoteId?: string;
  salesperson: string;
  conference_id: string;
  url?: string;
  rendered_video_url?: string;
  rendered_waveform_data?: number[];
  peakData?: number[];
  is_call_live: boolean;
  lead_data: Lead;
  coaching_notes: Region[];
  refetch_call_report: () => void;
  start_time: string;
  deleteCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  addCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  editCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  aiProcessing?: boolean;
  showTimeline?: boolean;
  callDuration?: number;
  aiData?: any;
  conferenceData?: any;
  setHandleTimestampSet?: React.Dispatch<React.SetStateAction<() => void>>;
  onWaveformRef?: (waveform: WaveSurfer | null) => void;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  handleSkipToTimestamp?: (timestamp: number) => void;
}

const CallReportSegment: React.FC<CallReportProps> = ({
  coachingNoteId,
  url,
  rendered_video_url,
  peakData,
  lead_data,
  coaching_notes,
  refetch_call_report,
  deleteCoachingNote,
  addCoachingNote,
  editCoachingNote,
  salesperson,
  conference_id,
  is_call_live,
  start_time,
  aiProcessing,
  showTimeline,
  callDuration,
  aiData,
  conferenceData,
  setHandleTimestampSet,
  onWaveformRef,
  isPlaying,
  setIsPlaying,
  handleSkipToTimestamp,
}) => {
  const { aiPhase1 } = useFlags();

  const [sendReviewCallReportToRep, { loading: repLoading, error: repError }] = useMutation(SEND_REP_FEEDBACK, {
    onCompleted({ sendReviewCallReportToRep }) {
      console.log("sendReviewCallReportToRep: ", sendReviewCallReportToRep);
      //window.location.reload(false);
      appToast("Feedback sent!");
    },
    onError({ message }) {
      console.log(message);
      appToast("Feedback sending feedback!");
      Sentry.captureEvent({
        message: `sendReviewCallReportToRep GraphQL Error: ${message}`,
      });
    },
  });

  return (
    <CallReportContainer style={{ marginTop: !!is_call_live ? 140 : !aiPhase1 ? 94 : 0 }}>
      {is_call_live ? (
        <LiveCallSegment
          conference_id={conference_id}
          regions={coaching_notes}
          lead_data={lead_data}
          refetch_call_report={refetch_call_report}
          deleteCoachingNote={deleteCoachingNote}
          addCoachingNote={addCoachingNote}
          editCoachingNote={editCoachingNote}
          salesperson={salesperson}
          start_time={start_time}
        />
      ) : url ? (
        <WaveformV2
          url={url}
          rendered_video_url={rendered_video_url}
          peakData={peakData}
          lead_data={lead_data}
          showCoachingNotes={!aiPhase1}
          regions={coaching_notes}
          conference_id={conference_id}
          refetch_call_report={refetch_call_report}
          addCoachingNote={addCoachingNote}
          editCoachingNote={editCoachingNote}
          deleteCoachingNote={deleteCoachingNote}
          aiProcessing={aiProcessing}
          showTimeline={showTimeline}
          callDuration={callDuration}
          aiData={aiData}
          conferenceData={conferenceData}
          setHandleTimestampSet={setHandleTimestampSet}
          onWaveformRef={onWaveformRef}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          handleSkipToTimestamp={handleSkipToTimestamp}
        />
      ) : (
        <NoURLDiv>
          <NoURLText>No Recording URL</NoURLText>
        </NoURLDiv>
      )}
    </CallReportContainer>
  );
};

export { CallReportSegment };

const NoURLDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoURLText = styled(AppText)`
  font-size: 12px;
  font-weight: 600;
  color: ${theme.BLACK_COLOR};
`;

const CallReportContainer = styled.div`
  max-width: 100%;
  max-height: calc(100vh);
`;
