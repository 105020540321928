import React, { useState, useMemo, useContext } from "react";
import styled, { keyframes } from "styled-components";
import { theme } from "../../../utils/theme";
import { AppText, SkeletonBlock } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { PhoenixAppButton, PhoenixIcon } from "../../UI/Phoenix";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { plus } from "src/images/NewDesign";
import { useFirstRender } from "src/utils/hooks";
import useCustomFields from "src/query-hooks/useCustomFields";
import { gql, useMutation } from "@apollo/client";
import { errorToast, successToast } from "src/utils/toast";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from "@dnd-kit/sortable";
import useFetchPinnedLeadFields from "src/query-hooks/useFetchPinnedLeadFields";
import { LeadCardContext } from "src/context";
import { LeadCardPreview } from "../LeadCardConfig/LeadCardPreview";
import { FieldSelect } from "../LeadCardConfig/FieldSelect";

const UPDATE_PINNED_LEAD_FIELDS = gql`
  mutation updatePinnedLeadFields($title_is_business_name: Boolean, $pinned_lead_fields: [PinnedLeadFieldInput!]!) {
    updatePinnedLeadFields(title_is_business_name: $title_is_business_name, pinned_lead_fields: $pinned_lead_fields)
  }
`;

export const StepCustomizeLeadCard: React.FC = () => {
  const { leadCardFields, setLeadCardFields } = useContext(LeadCardContext);

  const [doAnimation, setDoAnimation] = useState(false);

  const emptyField = useMemo(() => leadCardFields.some((e) => !e.label), [leadCardFields]);

  const { data: allCustomFieldData } = useCustomFields();
  const customFieldData = useMemo(
    () => allCustomFieldData.filter((e) => e.visible && !["Dropdown", "MultiDropdown"].includes(e.type)),
    [allCustomFieldData],
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const { loading: loadingPinnedLeadFields } = useFetchPinnedLeadFields({ setLeadCardFields });
  const [updatePinnedLeadFields, { loading: loadingUpdatePinnedLeadFields }] = useMutation(UPDATE_PINNED_LEAD_FIELDS, {
    onCompleted() {
      successToast("Lead fields updated successfully");
    },
    onError({ message, name }) {
      errorToast(message);
      console.log(`Error in ${name}: `, message);
    },
  });

  const handleSubmit = async () => {
    const formattedSelectedFields = leadCardFields.map((e) => ({
      key: e.value,
      label: e.label,
      type: customFieldData.find((cf: any) => cf.key === e.value) ? "Custom" : "System",
    }));
    await updatePinnedLeadFields({
      variables: {
        pinned_lead_fields: formattedSelectedFields,
      },
    });
  };
  const handleDragStart = (event: DragStartEvent) => {
    setDoAnimation(true);
  };
  const handleDragEnd = (event: DragEndEvent) => {
    setDoAnimation(false);
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setLeadCardFields((items) => {
        const oldIndex = items.findIndex((item) => item.value === active?.id);
        const newIndex = items.findIndex((item) => item.value === over?.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <PageWrap>
      <PhoenixStyledTooltip id="product-table-cell-overflow" lineHeight={16} />

      <Main>
        <Header>
          <AppText fontSize={22} fontWeight={500} lineHeight={28}>
            Customize Lead Card
          </AppText>

          <PhoenixAppButton
            variant="brand"
            buttonType="secondary"
            onClick={handleSubmit}
            disabled={loadingUpdatePinnedLeadFields || loadingPinnedLeadFields || emptyField}
          >
            <AppText fontSize={10} fontWeight={600} uppercase letterSpacing={1} color={theme.WHITE_COLOR}>
              Save Changes
            </AppText>
          </PhoenixAppButton>
        </Header>

        <Body>
          <CustomizeBody>
            <AppText style={{ width: "475px" }}>
              Customize your organization's lead cards with preferred fields to prioritize key lead information.
            </AppText>

            <FlexDiv gap={8} wrap="wrap" style={{ width: "496px", position: "relative" }}>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                {!loadingPinnedLeadFields && (
                  <SortableContext items={leadCardFields.map(({ value }) => value)} strategy={rectSortingStrategy}>
                    {leadCardFields.map((e, i) => (
                      <FieldSelect
                        e={e}
                        i={i}
                        listLen={leadCardFields.length}
                        customFieldData={customFieldData}
                        leadCardFields={leadCardFields}
                        setLeadCardFields={setLeadCardFields}
                        doAnimation={doAnimation}
                      />
                    ))}
                  </SortableContext>
                )}
                {leadCardFields.length < 12 && !loadingPinnedLeadFields && (
                  <AddField
                    handleClick={() => {
                      setLeadCardFields((prev) => [
                        ...prev,
                        {
                          label: "",
                          value: `SELECT_A_FIELD-${leadCardFields.length}`,
                        },
                      ]);
                    }}
                  />
                )}
              </DndContext>
              {loadingPinnedLeadFields && (
                <>
                  <SkeletonBlock width={220} height={40} borderRadius={3} delayNumber={0.25} />
                  <SkeletonBlock width={220} height={40} borderRadius={3} delayNumber={0.75} />
                  <SkeletonBlock width={220} height={40} borderRadius={3} delayNumber={0.75} />
                  <SkeletonBlock width={220} height={40} borderRadius={3} delayNumber={1} />
                  <SkeletonBlock width={220} height={40} borderRadius={3} delayNumber={1} />
                  <SkeletonBlock width={220} height={40} borderRadius={3} delayNumber={1.25} />
                </>
              )}
            </FlexDiv>
          </CustomizeBody>

          <PreviewBody>
            <LeadCardPreview />
          </PreviewBody>
        </Body>
      </Main>
    </PageWrap>
  );
};

const PageWrap = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 24px;
`;

const Main = styled.div`
  background-color: ${theme.WHITE_COLOR};
  border-radius: 16px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 40px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const CustomizeBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  height: 100%;
  padding: 40px;
`;

const PreviewBody = styled.div`
  display: flex;
  justify-content: center;

  width: 708px;
  min-width: 480px;
  height: 100%;
  padding: 40px;

  background-color: ${theme.surface.brand.primary};
`;

interface IAddField {
  handleClick: () => void;
}

const AddField: React.FC<IAddField> = ({ handleClick }) => {
  const isFirstRender = useFirstRender();
  return (
    <AddFieldContainer onClick={handleClick} isFirstRender={isFirstRender}>
      <PhoenixIcon
        svg={plus}
        size={12}
        color={theme.icon.brand.default}
        hoverColor={theme.icon.brand.default}
        pointer
      />
      <AppText fontSize={10} fontWeight={600} color={theme.buttontext.brand_outline.default}>
        Add Field
      </AppText>
    </AddFieldContainer>
  );
};

const borderdance = keyframes`
0% {
  background-position: 0px 0px, 0px 100%, 0px 0px, 100% 0px;
}
100% {
  background-position: 12px 0px, -12px 100%, 0px -12px, 100% 12px;
}
`;

const AddFieldContainer = styled.div<{ isFirstRender: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  width: 220px;
  height: 40px;
  margin-left: 24px;

  /* dotted border with background. This allows us to animate the border on hover */
  position: relative;
  background: linear-gradient(90deg, ${theme.border.brand.primary} 50%, transparent 50%),
    linear-gradient(90deg, ${theme.border.brand.primary} 50%, transparent 50%),
    linear-gradient(0deg, ${theme.border.brand.primary} 50%, transparent 50%),
    linear-gradient(0deg, ${theme.border.brand.primary} 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 4px 1px, 4px 1px, 1px 4px, 1px 4px;
  background-position: 0px 0px, 0px 100%, 0px 0px, 100% 0px;

  border-radius: 3px;

  :hover {
    cursor: pointer;
    background-color: ${theme.surface.brand.primary};
    animation: ${borderdance} 2s infinite linear;
  }
  transition: background-color 0.2s ease, animation-duration 5s ease;
`;
