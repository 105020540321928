import React, { useMemo } from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppText, FlexDiv } from "../../../UI";

import RenderAssociateTitle from "../../../Pages/LeadDetail/RenderAssociateTitle";

import {
  chat,
  download,
  folder,
  mail,
  outgoing_phone_white,
  plain_phone_white,
  play_circle_blue,
} from "../../../../images/NewDesign";

import moment from "moment";
import { useContext } from "react";
import { CallResultPill, DispositionReasonPill, PersonSpokeToPill } from "src/Components/UI/Pills";
import { ModalContext } from "../../../../context";
import { formatCallDurationShortened, formatPhoneNumber } from "../../../../utils/format";
import { downloadResourceCors } from "../../../../utils/misc";
import { PhoenixIcon } from "../../../UI/Phoenix";
import { ProfileImageWithText } from "../../SettingSegments/ProfileImageWithText";
import { ILeadCardLeadHistoryItem } from "./LeadCardV2";

const LeadCardLeadHistoryItem = ({
  item,
  primary_lead_id,
  associate_lead_ids,
  contact_id,
  setCurrentConferenceID = () => {},
  setShowSaveToFolderModal = () => {},
  showExtendedInfoSections,
  backgroundColor,
  showRepIcon,
  isAssociatedLead = false,
}: {
  item: Partial<ILeadCardLeadHistoryItem>;
  primary_lead_id: string;
  associate_lead_ids: string[];
  contact_id: string;
  setCurrentConferenceID?: (id: string) => void;
  setShowSaveToFolderModal?: (show: boolean) => void;
  showExtendedInfoSections: boolean;
  backgroundColor?: string;
  showRepIcon: boolean;
  isAssociatedLead: boolean;
}) => {
  const { setShowSMSAndEmailDetailsModal, setSMSAndEmailDetailsModalData } = useContext(ModalContext);
  const repSentCommunication =
    item.communication_type === "ExternalTransfer" ||
    item.communication_type === "NonCall" ||
    ((item.communication_type === "Phone" ||
      item.communication_type === "SMS" ||
      item.communication_type === "Email") &&
      item.channel === "Outbound");

  const repReceivedCommunication =
    (item.communication_type === "Phone" || item.communication_type === "SMS" || item.communication_type === "Email") &&
    item.channel === "Inbound";

  console.log("item", item);
  return (
    <HistoryItemDiv key={item.id} backgroundColor={backgroundColor} showRepIcon={showRepIcon}>
      <InnerRow>
        {(() => {
          if (item.communication_type === "Phone" && (item?.conference?.duration || 0) > 0) {
            return (
              <AppText fontSize={14} fontWeight={600}>
                <StyledLink
                  href={`/call-report/${item.conference?.id}`}
                  target="_blank"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {!!item.related_disposition?.label ? item.related_disposition?.label : "NA"} (
                  {item?.conference?.duration && formatCallDurationShortened(item?.conference?.duration)})
                </StyledLink>
              </AppText>
            );
          } else if (item.communication_type === "ExternalTransfer") {
            return (
              <AppText fontSize={14} fontWeight={600}>
                External Transfer
              </AppText>
            );
          } else if (item.communication_type === "Phone" && (item?.conference?.duration || 0) === 0) {
            return (
              <AppText fontSize={14} fontWeight={600}>
                {!!item.related_disposition?.label ? item.related_disposition?.label : "NA"}
              </AppText>
            );
          } else {
            return (
              <AppText fontSize={14} fontWeight={600}>
                {item.snooze_remove_task_message
                  ? item.snooze_remove_task_message
                  : item.title
                  ? item.title
                  : item.type === "Off Call Note"
                  ? "Off Call Note"
                  : "NA"}
              </AppText>
            );
          }
        })()}
      </InnerRow>
      <MainGrid>
        <LeftDateDiv>
          <AppText fontSize={12} fontWeight={400}>
            {!!item.created_at ? moment(item.created_at).format("M/D/YYYY") : ""}{" "}
          </AppText>
          <AppText fontSize={12} fontWeight={400} color={theme.text.neutral.secondary}>
            {!!item.created_at ? moment(item.created_at).format("h:mm A") : ""}
          </AppText>
        </LeftDateDiv>
        <RightDateDiv>
          {
            // if the call has a recording render the recording info component,
            // if the sms has a email or sms item render text to open the modal
          }
          {showExtendedInfoSections &&
          (item?.communication_type === "Phone" || item?.communication_type === "ExternalTransfer") &&
          item?.conference?.duration &&
          !!item?.conference?.recording_url_computed ? (
            <>
              <StyledLink href={`/call-report/${item.conference?.id}`} target="_blank">
                <ListItemDuration>
                  <PhoenixIcon svg={play_circle_blue} variant="brand" alt="Play" size={16} />
                  <AppText fontWeight={400} fontSize={12}>
                    {`(${formatCallDurationShortened(item.conference.duration)})`}
                  </AppText>
                </ListItemDuration>
              </StyledLink>

              <PhoenixIcon
                svg={folder}
                variant="brand"
                alt="Save"
                size={16}
                data-tip="Save"
                pointer
                onClick={() => {
                  setCurrentConferenceID(item.conference?.id || "");
                  setShowSaveToFolderModal(true);
                }}
              />

              <PhoenixIcon
                svg={download}
                variant="brand"
                alt="Download"
                size={16}
                data-tip="Download"
                pointer
                onClick={() => downloadResourceCors(item?.conference?.recording_url_computed)}
              />
            </>
          ) : null}
          {showExtendedInfoSections && item.communication_type === "Email" ? (
            <div
              style={{
                width: "max-content",
              }}
              onClick={() => {
                const currentItem = JSON.parse(JSON.stringify(item));
                currentItem.modal_primary_lead_id = primary_lead_id;
                currentItem.modal_associate_lead_ids = associate_lead_ids;
                currentItem.modal_contact_id = contact_id;
                currentItem.modal_is_associate_lead = isAssociatedLead;
                setSMSAndEmailDetailsModalData(currentItem);
                setShowSMSAndEmailDetailsModal(true);
              }}
            >
              <AppText variant="primary" fontWeight={400} fontSize={12} style={{ cursor: "pointer" }}>
                View Message
              </AppText>
            </div>
          ) : null}
          {showExtendedInfoSections && item.communication_type === "SMS" ? (
            <div
              style={{
                width: "max-content",
              }}
              onClick={() => {
                const currentItem = JSON.parse(JSON.stringify(item));
                currentItem.modal_primary_lead_id = primary_lead_id;
                currentItem.modal_associate_lead_ids = associate_lead_ids;
                currentItem.modal_contact_id = contact_id;
                currentItem.modal_is_associate_lead = isAssociatedLead;
                setSMSAndEmailDetailsModalData(currentItem);
                setShowSMSAndEmailDetailsModal(true);
              }}
            >
              <AppText variant="primary" fontWeight={400} fontSize={12} style={{ cursor: "pointer" }}>
                View Message
              </AppText>
            </div>
          ) : null}
        </RightDateDiv>
        <InnerRow>
          <AppText fontSize={12} fontWeight={400} color={theme.text.neutral.tertiary}>
            Contact
          </AppText>
        </InnerRow>
        <FlexDiv gap={8} align="center">
          <RenderAssociateTitle
            iconSize={12}
            padding={"10px"}
            primary_lead_id={primary_lead_id}
            associate_lead_ids={associate_lead_ids}
            contact_id={contact_id}
            isAssociatedLead={isAssociatedLead || !!item?.associate_child}
            lead_activity_data={item}
          />

          <AppText fontSize={12} fontWeight={600}>
            {!item.associate_child ? item?.lead?.full_name || "NA" : item?.associate_child?.full_name || "NA"}
          </AppText>
        </FlexDiv>
        {(item.communication_type === "Phone" ||
          item?.communication_type === "ExternalTransfer" ||
          item?.communication_type === "NonCall") && (
          <>
            <InnerRow>
              <AppText fontSize={12} fontWeight={400} color={theme.text.neutral.tertiary}>
                Disposition
              </AppText>
            </InnerRow>

            <RepAndResultDiv>
              <PersonSpokeToPill
                person={item?.related_disposition?.computed_call_history_theme?.completed_with_theme?.label ?? "NA"}
                backgroundColorOverride={
                  item?.related_disposition?.computed_call_history_theme?.completed_with_theme?.bg_color ??
                  theme.PILL_LIGHT_GRAY
                }
                colorOverride={
                  item?.related_disposition?.computed_call_history_theme?.completed_with_theme?.font_color ?? "#000000"
                }
              />
              <CallResultPill
                result={item?.related_disposition?.computed_call_history_theme?.call_result_theme?.label ?? "NA"}
                backgroundColorOverride={
                  item?.related_disposition?.computed_call_history_theme?.call_result_theme?.bg_color ??
                  theme.PILL_LIGHT_GRAY
                }
                colorOverride={
                  item?.related_disposition?.computed_call_history_theme?.call_result_theme?.font_color ??
                  theme.BLACK_COLOR
                }
              />
              {item.related_disposition_reason && (
                <DispositionReasonPill
                  reason={item.related_disposition_reason ?? "N/A"}
                  disposition={item?.related_disposition?.label || ""}
                />
              )}
            </RepAndResultDiv>
          </>
        )}
        {/*  title */}
        <InnerRow>
          {item.communication_type === "Phone" ? (
            <IconAndTitleDiv>
              {<img src={plain_phone_white} alt="Phone" style={{ height: "16px" }} />}
              <CommunicationTitleText>Recipient Number</CommunicationTitleText>
            </IconAndTitleDiv>
          ) : item.communication_type === "Email" ? (
            <IconAndTitleDiv>
              <PhoenixIcon svg={mail} variant="brand" alt="Email" size="small" />
              <CommunicationTitleText>Recipient Email</CommunicationTitleText>
            </IconAndTitleDiv>
          ) : item.communication_type === "Internal" ? (
            <IconAndTitleDiv>
              {<img src={plain_phone_white} alt="Phone" style={{ height: "16px" }} />}
              <CommunicationTitleText>NA</CommunicationTitleText>
            </IconAndTitleDiv>
          ) : item.communication_type === "SMS" ? (
            <IconAndTitleDiv>
              <img src={chat} alt="SMS" style={{ height: "16px" }} />
              <CommunicationTitleText>Recipient Number</CommunicationTitleText>
            </IconAndTitleDiv>
          ) : item?.communication_type === "NonCall" ? (
            <IconAndTitleDiv>
              <img src={chat} alt="SMS" style={{ height: "16px" }} />
              <CommunicationTitleText>Recipient Number</CommunicationTitleText>
            </IconAndTitleDiv>
          ) : (
            item.communication_type === "ExternalTransfer" && (
              <IconAndTitleDiv>
                <img src={plain_phone_white} alt="Phone" style={{ height: "16px" }} />
                <CommunicationTitleText>Transfer Number</CommunicationTitleText>
              </IconAndTitleDiv>
            )
          )}
        </InnerRow>

        <FlexDiv justify="space-between" align="center">
          {item.communication_type === "Internal" && <WrappingText>NA</WrappingText>}
          {item.communication_type === "Email" && <WrappingText>{item.email_item?.to_email ?? ""} </WrappingText>}
          {item.communication_type === "Phone" && (
            <WrappingText>
              {item.channel === "Outbound"
                ? formatPhoneNumber(item.customer_number)
                : formatPhoneNumber(item?.user?.phone_number)}
            </WrappingText>
          )}
          {item.communication_type === "SMS" && (
            <WrappingText>
              {item?.SMSItem?.lead_number_used ? formatPhoneNumber(item?.SMSItem?.lead_number_used) : "NA"}
            </WrappingText>
          )}
          {item.communication_type === "NonCall" && <WrappingText>N/A - Non Call Activity</WrappingText>}
          {item.communication_type === "ExternalTransfer" && (
            <WrappingText>
              {!!item.external_transfer_number ? formatPhoneNumber(item?.external_transfer_number) : "NA"}
            </WrappingText>
          )}
          {showRepIcon && repReceivedCommunication && (
            <ProfileImageWithText
              first_name={item?.user?.first_name}
              last_name={item?.user?.last_name}
              profile_image={item?.user?.profile_image || undefined}
              height={24}
              width={24}
              border={false}
            />
          )}
        </FlexDiv>
        {/** from div*/}

        {/** title*/}
        <InnerRow>
          {item.communication_type === "Phone" ? (
            <IconAndTitleDiv>
              <img src={outgoing_phone_white} alt="Phone" style={{ height: "16px" }} />
              <CommunicationTitleText>Originating Number</CommunicationTitleText>
            </IconAndTitleDiv>
          ) : item.communication_type === "Email" ? (
            <IconAndTitleDiv>
              <PhoenixIcon svg={mail} variant="brand" alt="Email" size="small" />
              <CommunicationTitleText>Originating Email</CommunicationTitleText>
            </IconAndTitleDiv>
          ) : item.communication_type === "Internal" ? (
            <IconAndTitleDiv>
              <img src={outgoing_phone_white} alt="Phone" style={{ height: "16px" }} />
              <CommunicationTitleText>NA</CommunicationTitleText>
            </IconAndTitleDiv>
          ) : item.communication_type === "SMS" ? (
            <IconAndTitleDiv>
              <img src={chat} alt="SMS" style={{ height: "16px" }} />
              <CommunicationTitleText>Originating Number</CommunicationTitleText>
            </IconAndTitleDiv>
          ) : item.communication_type === "NonCall" ? (
            <IconAndTitleDiv>
              <img src={chat} alt="SMS" style={{ height: "16px" }} />
              <CommunicationTitleText>Originating Number</CommunicationTitleText>
            </IconAndTitleDiv>
          ) : (
            item.communication_type === "ExternalTransfer" && (
              <IconAndTitleDiv>
                <img src={outgoing_phone_white} alt="Phone" style={{ height: "16px" }} />
                <CommunicationTitleText>Originating Number</CommunicationTitleText>
              </IconAndTitleDiv>
            )
          )}
        </InnerRow>

        {/** value */}
        <FlexDiv justify="space-between" align="center">
          {item.communication_type === "Phone" ? (
            <WrappingText>
              {/* check if the call was made using a local presence number */}
              {!!item.local_number?.number
                ? item.channel === "Outbound"
                  ? formatPhoneNumber(item?.local_number?.number)
                  : formatPhoneNumber(item?.customer_number)
                : item?.user?.phone_number
                ? item.channel === "Outbound"
                  ? formatPhoneNumber(item?.user?.phone_number)
                  : formatPhoneNumber(item?.customer_number)
                : "NA"}
            </WrappingText>
          ) : item.communication_type === "Email" ? (
            <WrappingText>{item.email_item?.from_email ?? "NA"} </WrappingText>
          ) : item.communication_type === "Internal" ? (
            <WrappingText>NA</WrappingText>
          ) : item.communication_type === "Email" ? (
            <WrappingText>{item.email_item?.from_email ?? "NA"} </WrappingText>
          ) : item.communication_type === "SMS" ? (
            <WrappingText>{item?.user?.phone_number ? formatPhoneNumber(item?.user?.phone_number) : "NA"}</WrappingText>
          ) : item.communication_type === "NonCall" ? (
            <WrappingText>{formatPhoneNumber(item?.user?.phone_number)}</WrappingText>
          ) : (
            item.communication_type === "ExternalTransfer" && (
              <WrappingText>
                {/* check if the call was made using a local presence number */}
                {!!item.local_number?.number
                  ? item.channel === "Outbound"
                    ? formatPhoneNumber(item?.local_number?.number)
                    : formatPhoneNumber(item?.customer_number)
                  : item?.user?.phone_number
                  ? item.channel === "Outbound"
                    ? formatPhoneNumber(item?.user?.phone_number)
                    : formatPhoneNumber(item?.customer_number)
                  : "NA"}
              </WrappingText>
            )
          )}
          {showRepIcon && repSentCommunication && (
            <ProfileImageWithText
              first_name={item?.user?.first_name}
              last_name={item?.user?.last_name}
              profile_image={item?.user?.profile_image || undefined}
              height={24}
              width={24}
              border={false}
            />
          )}
        </FlexDiv>
      </MainGrid>
    </HistoryItemDiv>
  );
};

const LeftDateDiv = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const RightDateDiv = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
`;

const WrappingText = styled(AppText)`
  word-break: break-all;
  max-width: 200px;
  font-weight: 400;
  font-size: 12px;
`;

const ListItemDuration = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledLink = styled.a`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

const HistoryItemDiv = styled.div<{ backgroundColor: string | undefined; showRepIcon: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${(props) => props.backgroundColor ?? theme.WHITE_COLOR};
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${theme.border.neutral.secondary};
  background: ${theme.fill.neutral.primary};
  margin: 8px;
`;

const InnerRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 8px;
`;

const MainGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 152px 1fr;
  grid-auto-rows: auto;

  > * {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    border-bottom: 1px solid ${theme.border.neutral.secondary};
  }

  > *:last-child,
  > *:nth-last-child(2) {
    border-bottom: none;
  }
`;

const RepAndResultDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
`;

interface RepAndResultIcon {
  background?: string;
  color?: string;
}
const RepAndResultIcon = styled.div<RepAndResultIcon>`
  // font
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  text-transform: uppercase;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  margin-right: 4px;

  background-color: ${(props) => {
    return props.background;
  }};

  color: ${(props) => {
    return props.color;
  }};
  // never wrap
  white-space: nowrap;
`;

const IconAndTitleDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: ${theme.PRIMARY500};
`;

const CommunicationTitleText = styled(AppText)`
  font-family: 400;
  font-size: 12px;
  color: ${theme.PRIMARY500};
  line-height: 18px;
  text-wrap: nowrap;
`;

export { LeadCardLeadHistoryItem };
