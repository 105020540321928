import styled, { css, keyframes } from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useState, useEffect, useContext, useMemo } from "react";
import { AppIconButton, AppText, AppTabDiv, NewAppSelect, SkeletonBlock } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { NewAppButton } from "../../UI/NewAppButton";
import { theme } from "../../../utils/theme";
import { useMutation, useQuery, useLazyQuery, gql } from "@apollo/client";
import { formatCellData, formatUSDRaw } from "../../../utils/format";
import { groupBy, downloadResourceAWS, groupUserBy } from "../../../utils/misc";
import ReactTooltip from "react-tooltip";
import { GridFilterContext, ModalContext } from "../../../context/";
import moment from "moment";
import { AdvancedImage, lazyload } from "@cloudinary/react";
import { showMoreArrow } from "../../../images/";
import { isProduction, MANAGER_GRID_PERSIST_KEY, DEFAULT_CLOUDINARY_IMAGE } from "../../../utils/variables";
import {
  ManageGridColumnsModal,
  ManageTableColumnsModal,
  ManageTableEventColumnsModal,
  ManageTablePipelineColumnsModal,
  Modal,
} from "../../modal";
import { AiFillWarning } from "react-icons/ai";
import { BsGraphUp, BsLayoutTextWindow } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import { MixpanelActions } from "./../../../services/mixpanel";
import { cloudinary } from "./../../../services/cloudinary";
import { CallReportListV2 } from "../CallReportListV2";
import { StackRankListTable } from "./StackRankListTable";
import { EventsListTableV2 } from "./EventsListTableV2";
import { PipelineListTableV2 } from "./PipelineListTableV2";
import { TransferCallList } from "../TransferCallList";

import { ExportCallListModal } from "../../modal/ExportCallListModal";
import { appToast } from "../../../utils/toast";
import { FRONTEND_URL } from "../../../utils/variables";
import { StyledTooltip } from "../../Dumb";
import { useFlags } from "launchdarkly-react-client-sdk";
import TableStickyHeader from "./TableStickyHeader";
import { RevenueForecastTable } from "./RevenueForecastTable";
import { isUserRep, loggedInUser } from "../../../apollo/cache";
import Switch from "react-switch";
import { MRR_LABEL } from "../../../apollo/query";
import {
  info,
  share,
  file_text,
  clipboard,
  activity,
  shield,
  filter,
  repeat,
  calendar,
  download,
  settings,
  refresh,
  xIcon,
} from "../../../images/NewDesign";
import { PhoenixIcon, PhoenixAppButton, PhoenixMultiSelect, PhoenixDateRange } from "../../UI/Phoenix";
import Render30DayChart from "./GridInfoTable/30DayGraph";
import { OptionItem } from "../../../types";
import { cloneDeep } from "lodash";
import { DashboardViewModal } from "../../modal/DashboardViewModal";
import { DashboardShareViewModal } from "../../modal/DashboardShareViewModal";
import { DATE_RANGE_OPTIONS, IDateRangeOption } from "src/utils/date-options";

const DEFAULT_DATE_LABEL = "ThisWeek";

const GROUP_BY_OPTIONS: OptionItem[] = [
  { label: "Group by: All", value: "None" },
  { label: "Group by: Team", value: "Team" },
  { label: "Group by: Site", value: "Site" },
];
const MEASURE_BY_OPTIONS: OptionItem[] = [
  { label: "Who Logged Call Result", value: "user_id" },
  { label: "Who Set Original Demo", value: "set_user_id" },
];

interface ColumnOption {
  metric_label: string;
  metric: string;
  fixed: boolean;
}
const MANAGE_TABLE_COLUMN_OPTIONS: ColumnOption[] = [
  { metric_label: "Time of Call", metric: "CallTime", fixed: false },
  { metric_label: "Contact Name", metric: "LeadName", fixed: false },
  { metric_label: "Business Name", metric: "BusinessName", fixed: false },
  { metric_label: "Sales Rep", metric: "RepName", fixed: false },
  { metric_label: "Call Result", metric: "CallResult", fixed: false },
  { metric_label: "Associated Action", metric: "AssociatedAction", fixed: false },
  { metric_label: "Set", metric: "Set", fixed: true },
  { metric_label: "Held", metric: "Held", fixed: true },
  { metric_label: "Closed", metric: "Close", fixed: true },
  { metric_label: "Time of Event", metric: "EventTime", fixed: false },
  { metric_label: "Sales Rep Who Set Demo", metric: "SetByUser", fixed: false },
  { metric_label: "Sales Rep Who made the Sale", metric: "ClosedByUser", fixed: false },
  { metric_label: "Industry", metric: "Industry", fixed: false },
  { metric_label: "Sub-Industry", metric: "SubIndustry", fixed: false },
  { metric_label: "Lead Source", metric: "LeadSource", fixed: false },
  { metric_label: "Revenue", metric: "Revenue", fixed: false },
  { metric_label: "Revenue Value", metric: "RevenueValue", fixed: false },
  { metric_label: "Recurring Revenue", metric: "RecurringRevenue", fixed: false },
  { metric_label: "Days Set Out", metric: "DaysSetOut", fixed: false },
  { metric_label: "Dials: Current Sales Cycle", metric: "DialsInCurrentSalesCycle", fixed: false },
  { metric_label: "Call End Reason", metric: "CallEndReason", fixed: false },
  { metric_label: "Date/Time Sms Replied", metric: "DateTimeSmsReplied", fixed: false },
  { metric_label: "Date/Time Sms Sent", metric: "DateTimeSmsSent", fixed: false },
  { metric_label: "Date/Time Email Replied", metric: "DateTimeEmailReplied", fixed: false },
  { metric_label: "Date/Time Email Sent", metric: "DateTimeEmailSent", fixed: false },
];
interface IfetchUsersAndPreviews {
  fetchUsersAndPreviews?: {
    user_id?: string;
    user?: {
      id?: string;
      first_name?: string;
      last_name?: string;
      lead_source_filter?: string;
      industry_filter?: string;
      lead_source_white_list_array?: string[];
      industry_white_list_array?: string[];
      leads_in_queue?: number;
    };
    user_preview?: {
      AssignedLead?: number;
      OwnedLead?: number;
      TotalLead?: number;
      CCDials?: number;
      GeneralCallBackDials?: number;
      FollowUpDials?: number;
      ScheduledCallBacks?: number;
      Demos?: number;
      DecisionCalls?: number;
      AllScheduledEvents?: number;
      UpcomingDials?: number;
    };
  }[];
}

const FETCH_USER_PREVIEWS = gql`
  query fetchUsersAndPreviews($user_ids: [String!]!) {
    fetchUsersAndPreviews(user_ids: $user_ids) {
      user_id
      user {
        id
        first_name
        last_name
        lead_source_filter
        industry_filter
        lead_source_white_list_array
        industry_white_list_array
        leads_in_queue
      }
      user_preview {
        AssignedLead
        OwnedLead
        TotalLead
        CCDials
        GeneralCallBackDials
        FollowUpDials
        ScheduledCallBacks
        Demos
        DecisionCalls
        AllScheduledEvents
        UpcomingDials
      }
    }
  }
`;

interface IfetchUserCallReportEventColumns {
  fetchUser: {
    id: string;
    visible_call_report_event_columns_computed: string[];
    visible_call_report_pipeline_columns_computed: string[];
  };
}
const FETCH_USER_CALL_REPORT_PIPELINE_COLUMNS = gql`
  query fetchUserCallReportEventColumns {
    fetchUser {
      id
      visible_call_report_pipeline_columns_computed
    }
  }
`;

interface UserIconProps {
  user_id: string;
  data: any;
  loading: boolean;
  error: any;
  isSharedView: boolean;
}

const UserIcon: React.FC<UserIconProps> = ({ user_id, data, loading, error, isSharedView }) => {
  if (loading) {
    return <SkeletonBlock height={10} width={25} borderRadius={"50%"} />;
  }
  if (error || !data) {
    return null;
  }

  let industries: string = "";
  let lead_sources: string = "";

  if (data?.user?.industry_filter) {
    industries = data?.user?.industry_white_list_array.join(", ");
  }
  if (data?.user?.lead_source_filter) {
    lead_sources = data?.user?.lead_source_white_list_array.join(", ");
  }
  const hasFiltersAppliedToLeadPool = data?.user?.industry_filter || data?.user?.lead_source_filter;

  const leads_in_queue = data?.user?.leads_in_queue || 0;

  const name = `${data?.user?.first_name} ${data?.user?.last_name}`;

  let main_text = ``;

  // generate the tooltip html text in main_text

  if (leads_in_queue > 10) {
    main_text = ``;
  } else if (leads_in_queue > 0) {
    main_text = `Warning! ${name} only has ${leads_in_queue} leads left in their queue.`;
  } else {
    main_text = `Warning! ${name} is out of leads!`;
  }

  const userPreview = `
    <div style="display: flex; flex-direction: column; gap: 16px;" >
        <div style="display: flex; flex-direction: column; gap: 4px;" >
          <div style="font-weight: 600;">Leads</div>
          <div>Assigned:${data?.user_preview?.AssignedLead}</div>
          <div>Owned: ${data?.user_preview?.OwnedLead}</div>
          <div>All Leads: ${data?.user_preview?.TotalLead}</div>
        </div>
        <div style="display: flex; flex-direction: column; gap: 4px;" >
          <div style="font-weight: 600;">Active Queue</div>
          <div>Cold Calls: ${data?.user_preview?.CCDials}</div>
          <div>Call Follow-ups: ${data?.user_preview?.FollowUpDials}</div>
          <div>General Call Backs: ${data?.user_preview?.GeneralCallBackDials}</div>
          <div>All in Active Queue: ${data?.user_preview?.UpcomingDials}</div>
        </div>
        <div style="display: flex; flex-direction: column; gap: 4px;" >
          <div style="font-weight: 600;">Scheduled Events</div>
            <div>Scheduled Call Backs: ${data?.user_preview?.ScheduledCallBacks}</div>
            <div>Demos: ${data?.user_preview?.Demos}</div>
            <div>Decision Calls: ${data?.user_preview?.DecisionCalls}</div>
          <div>All Scheduled Events: ${data?.user_preview?.AllScheduledEvents}</div>
        </div>

        <div style="display: flex; flex-direction: column;" >
        ${
          hasFiltersAppliedToLeadPool
            ? `<div style="font-size: 10px;">Note: This user has the following filters applied to their lead pool:</div>`
            : `<div />`
        }
          ${industries ? `<div style="font-size: 10px;">Industry: ${industries}</div>` : `<div/>`}
          ${lead_sources ? `<div style="font-size: 10px;">Lead Source: ${lead_sources}</div>` : `<div/>`}
        </div>
    </div>`;

  return (
    <UserIconDiv>
      {leads_in_queue > 10 ? (
        <PhoenixIcon
          data-for={isSharedView ? undefined : `user-status-tooltip`}
          data-html
          data-tip={isSharedView ? undefined : userPreview}
          size={14}
          color={theme.BLACK_COLOR}
          hoverColor={theme.BLACK_COLOR}
          svg={info}
        />
      ) : leads_in_queue > 0 ? (
        <AiFillWarning
          data-for={isSharedView ? undefined : `user-status-tooltip`}
          data-html
          data-tip={isSharedView ? undefined : userPreview}
          size={14}
          color={theme.TERTIARY500}
        />
      ) : (
        <PhoenixIcon
          data-for={isSharedView ? undefined : `user-status-tooltip`}
          data-html
          data-tip={isSharedView ? undefined : userPreview}
          size={14}
          color={theme.ATTENTION700}
          hoverColor={theme.ATTENTION700}
          svg={info}
        />
      )}
    </UserIconDiv>
  );
};

interface IfetchPerformanceChartV2 {
  fetchPerformanceChartV2?: IPerformanceChartData[];
}
const FETCH_PERFORMANCE_CHART = gql`
  query fetchPerformanceChartV2($dashboard_args: DashBoardSavedViewArgument!, $dashboard_type: DashboardType!) {
    fetchPerformanceChartV2(dashboard_args: $dashboard_args, dashboard_type: $dashboard_type) {
      id
      computed_id
      row_label
      metric
      metric_label
      metric_type
      metric_tooltip
      value
      position
      rank
      lowerbound_date
      upperbound_date
      team_id
      user_id
      site_id
      profile_image
      organization_id
      role
      __typename
    }
  }
`;

interface IfetchDefaultSavedView {
  fetchDefaultSavedView: IDashboardSavedView;
}
const FETCH_DASHBOARD_DEFAULT_VIEW = gql`
  query fetchDefaultSavedView {
    fetchDefaultSavedView {
      id
      name
      is_default_view
      lead_filter {
        Channels
        LeadSources
        LeadCreationSources
        primary_industries
        sub_industries
        NextScheduledEventDays
        NextScheduledEventTypes
      }
      rep_filter {
        team_ids
        site_ids
        roles
        user_ids
      }
      column_options {
        visible_reporting_fields
        visible_coaching_fields
        visible_activity_fields
        visible_event_fields
        visible_pipeline_fields
        visible_transfer_fields
        visible_transfer_quality_fields
      }
      group_option
      measure_by
      lowerbound_date
      upperbound_date
      date_range
    }
  }
`;

interface ISavedView {
  id: string;
  name: string;
}

interface IfetchAllSavedViews {
  fetchAllSavedViews: ISavedView[];
}
const FETCH_ALL_SAVED_VIEW = gql`
  query fetchAllSavedViews {
    fetchAllSavedViews {
      id
      name
    }
  }
`;

interface IDashboardSavedView {
  id?: string;
  name?: string;
  lead_activity_channel?: string[];
  is_default_view?: boolean;
  lead_filter?: {
    Channels?: string[];
    LeadSources?: string[];
    LeadCreationSources?: string[];
    primary_industries?: string[];
    sub_industries?: string[];
    NextScheduledEventDays?: number;
    NextScheduledEventTypes?: string[];
    sequences?: string[];
    custom_fields: { id: string; key: string; type?: string; value: string[] }[];
  };
  rep_filter?: {
    team_ids?: string[];
    site_ids?: string[];
    roles?: string[];
    user_ids?: string[];
  };
  column_options?: {
    visible_reporting_fields?: string[];
    visible_coaching_fields?: string[];
    visible_activity_fields?: string[];
    visible_event_fields?: string[];
    visible_pipeline_fields?: string[];
    visible_transfer_fields?: string[];
    visible_transfer_quality_fields?: string[];
  };
  group_option?: string;
  measure_by?: string;
  lowerbound_date?: string;
  upperbound_date?: string;
  date_range?: string;
}

interface IfetchDashboardSavedView {
  fetchDashboardSavedView?: IDashboardSavedView;
}
const FETCH_DASHBOARD_SAVED_VIEW = gql`
  query fetchDashboardSavedView($saved_view_id: String!) {
    fetchDashboardSavedView(saved_view_id: $saved_view_id) {
      id
      name
      is_default_view
      lead_activity_channel
      lead_filter {
        Channels
        LeadSources
        LeadCreationSources
        primary_industries
        sub_industries
        NextScheduledEventDays
        NextScheduledEventTypes
        sequences
        custom_fields
      }
      rep_filter {
        team_ids
        site_ids
        roles
        user_ids
      }
      column_options {
        visible_reporting_fields
        visible_coaching_fields
        visible_activity_fields
        visible_event_fields
        visible_pipeline_fields
        visible_transfer_fields
        visible_transfer_quality_fields
      }
      group_option
      measure_by
      lowerbound_date
      upperbound_date
      date_range
    }
  }
`;

const UPDATE_DASHBOARD_SAVED_VIEW = gql`
  mutation updateDashboardSavedView($data: DashBoardSavedViewArgument!, $name: String!, $saved_view_id: String!) {
    updateDashboardSavedView(data: $data, name: $name, saved_view_id: $saved_view_id) {
      id
      name
    }
  }
`;

const CREATE_DASHBOARD_SAVED_VIEW = gql`
  mutation createDashboardSavedView($data: DashBoardSavedViewArgument!, $name: String!) {
    createDashboardSavedView(data: $data, name: $name) {
      id
      name
    }
  }
`;

interface IfetchDashboardSharedView {
  fetchDashboardSharedView?: {
    saved_view_data: IDashboardSavedView;
    group_option: string;
    stat_items: IPerformanceChartData[];
  };
}
const FETCH_DASHBOARD_SHARED_VIEW = gql`
  query fetchDashboardSharedView($saved_view_id: String!, $dashboard_type: DashboardType!) {
    fetchDashboardSharedView(saved_view_id: $saved_view_id, dashboard_type: $dashboard_type) {
      saved_view_data {
        id
        name
        date_range
        measure_by
        upperbound_date
        lowerbound_date
      }
      group_option
      stat_items {
        id
        computed_id
        row_label
        metric
        metric_label
        profile_image
        metric_type
        metric_tooltip
        value
        position
        rank
        lowerbound_date
        upperbound_date
        site_id
        team_id
        user_id
        organization_id
        __typename
      }
    }
  }
`;

interface IExportDashboardView {
  exportDashboardView?: {
    url?: string;
  };
}
const EXPORT_DASHBOARD_VIEW = gql`
  mutation exportDashboardView(
    $dashboard_args: DashBoardSavedViewArgument!
    $dashboard_type: DashboardType!
    $group_references: [String]
  ) {
    exportDashboardView(
      dashboard_args: $dashboard_args
      dashboard_type: $dashboard_type
      group_references: $group_references
    ) {
      url
    }
  }
`;

interface GridTableProps {
  loading?: boolean;
  data: any;
  isSharedView?: boolean;
  disableGroupBy?: boolean;
  only_show_rep?: boolean;
  topFunnelView?: boolean;
  bottomFunnelView?: boolean;
  topFunnelColumns?: string[];
  bottomFunnelColumns?: string[];
  selectedCell: any;
  setSelectedCell: (cell: any) => void; // Add this line
  setGroupReferences?: React.Dispatch<React.SetStateAction<string[]>>;
}

const FETCH_TOP_FUNNEL_LEAD_COUNT = gql`
  query fetchTopFunnelLeadCount($pipelineManagementArg: PipelineManagementArg!) {
    fetchTopFunnelLeadCount(pipeline_management_arg: $pipelineManagementArg)
  }
`;

const FETCH_BOTTOM_FUNNEL_LEAD_COUNT = gql`
  query fetchBottomFunnelLeadCount($pipelineManagementArg: PipelineManagementArg!) {
    fetchBottomFunnelLeadCount(pipeline_management_arg: $pipelineManagementArg)
  }
`;

const EXPORT_PIPELINE_REPORT = gql`
  mutation exportPipelineReport(
    $pipeline_export_arg: PipelineExportArg!
    $column_options: [String!]!
    $funnel: String
  ) {
    exportPipelineReport(pipeline_export_arg: $pipeline_export_arg, column_options: $column_options, funnel: $funnel) {
      url
    }
  }
`;

const GridInnerTableComponent: React.FC<GridTableProps> = ({
  loading,
  data,
  isSharedView,
  topFunnelView,
  bottomFunnelView,
  topFunnelColumns,
  bottomFunnelColumns,

  setGroupReferences,
}) => {
  const {
    selectedCell,
    setSelectedCell,
    tableView,
    groupOption,
    measureBy,
    columnOptions,
    sortOption,
    currentSavedView,
    sharedViewId,
    tablePipelineMetric,
    leadFilterArgs,
    dateFilterArgs,
    repFilterArgs,
    columnOptionArgs,
    groupOptionArgs,
    pipelineIgnoreCloseDatesComputed: pipelineIgnoreCloseDates,
    leadActivityChannel,
  } = useContext(GridFilterContext);

  const [expanded, setExpanded] = useState(false);
  const [firstExpand, setFirstExpand] = useState(true);

  const group_reference = useMemo(() => {
    switch (groupOption) {
      case "Team":
        return data[0].team_id;
      case "Site":
        return data[0].site_id;
      default:
        return null;
    }
  }, [groupOption, data]);

  const [
    fetchViewData,
    { data: dataUser, loading: loadingUser, error: errorUser, refetch: refetchUser },
  ] = useLazyQuery<IfetchPerformanceChartV2>(FETCH_PERFORMANCE_CHART, {
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
    },
    fetchPolicy: "no-cache",
    partialRefetch: false,
    nextFetchPolicy: "no-cache",
    variables: {
      dashboard_type: tableView,
      dashboard_args: {
        lead_activity_channel: leadActivityChannel,
        lead_filter: leadFilterArgs,
        rep_filter: repFilterArgs,
        date_filter: dateFilterArgs,
        group_option: groupOptionArgs,
        measure_by: measureBy,
        group_reference: group_reference,
        column_options: columnOptionArgs,
        pipeline_metric_type: tablePipelineMetric,
      },
    },
  });

  const userLevel = useMemo(() => {
    const userLevel = !!dataUser?.fetchPerformanceChartV2
      ? groupUserBy(dataUser.fetchPerformanceChartV2, "row_label")
      : [];

    //null is "Organization" position
    delete userLevel["null"];
    // delete userLevel["undefined"]; undefined is probably an error

    let sortedUserLevelKeys: string[] = [];

    // example ['ae test AE', 'Donald McAboy SDR', 'newest mrSDR SDR']
    const userLevelKeys = Object.keys(userLevel);

    sortedUserLevelKeys = userLevelKeys.sort((a: string, b: string) => {
      const aArray = userLevel[a] as any[];
      const bArray = userLevel[b] as any[];
      const aValueArray = !!sortOption ? aArray.find((item: any) => item.metric === sortOption) : aArray[0];

      const bValueArray = !!sortOption ? bArray.find((item: any) => item.metric === sortOption) : bArray[0];
      return bValueArray?.value - aValueArray?.value;
    });

    let sortedUserLevel = [] as any[];
    sortedUserLevelKeys.forEach((key: string) => {
      sortedUserLevel.push({
        [key]: userLevel[key]?.map((item: any) => {
          return { ...item, group_reference: group_reference, group_option: groupOption };
        }),
      });
    });

    return sortedUserLevel;
  }, [dataUser, sortOption, tableView, groupOption, measureBy, columnOptions, currentSavedView, tableView]);

  const { data: dataPreviews, loading: loadingPreviews, error: errorPreviews } = useQuery<IfetchUsersAndPreviews>(
    FETCH_USER_PREVIEWS,
    {
      fetchPolicy: "network-only",
      skip: userLevel.length <= 0,
      variables: {
        user_ids: userLevel?.map((item: any) => {
          let key = Object.keys(item)[0];
          let data = item[key];
          if (data.length) {
            return data[0].user_id;
          }
          return "";
        }),
      },
    },
  );

  useEffect(() => {
    if (!!expanded && !!firstExpand) {
      fetchViewData();
      setFirstExpand(false);
    }

    if (expanded) {
      setGroupReferences?.((prevGroupReferences) => {
        if (!prevGroupReferences.includes(group_reference)) {
          return [...prevGroupReferences, group_reference];
        }
        return prevGroupReferences;
      });
    } else {
      setGroupReferences?.((prevGroupReferences) => prevGroupReferences.filter((ref) => ref !== group_reference));
    }
  }, [expanded]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [dataPreviews?.fetchUsersAndPreviews, expanded, groupOption, loadingPreviews, sharedViewId]);

  return (
    <>
      <GridTR key={group_reference} style={{ backgroundColor: `${theme.NEUTRAL100}` }}>
        <GridBody
          style={{
            position: "sticky",
            left: "0px",
            zIndex: 0,
          }}
        >
          <Horizontal
            onClick={() => (!!sharedViewId ? null : setExpanded(!expanded))}
            style={{ justifyContent: "space-between" }}
          >
            <GridBodyRowLabel>{data[0].row_label}</GridBodyRowLabel>
            {!sharedViewId && (
              <ArrowDiv onClick={() => setExpanded(!expanded)} style={{ height: "8px", cursor: "pointer" }}>
                {expanded ? (
                  <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                ) : (
                  <img src={showMoreArrow} alt="Expand" />
                )}
              </ArrowDiv>
            )}
          </Horizontal>
        </GridBody>
        {data
          .filter((item: any) =>
            !topFunnelView
              ? !topFunnelColumns?.includes(item?.metric_label)
              : !bottomFunnelView
              ? !bottomFunnelColumns?.includes(item?.metric_label)
              : true,
          )
          ?.map((item: any) => (
            <GridBody
              selected={selectedCell?.id === item.id}
              onClick={() => (!!sharedViewId ? null : setSelectedCell(item))}
            >
              <CellDataText style={{ fontWeight: 600 }}>{formatCellData(item?.value, item?.metric_type)}</CellDataText>
            </GridBody>
          ))}
      </GridTR>

      {expanded &&
        (!!loadingUser ? (
          <GridTR>
            <GridBody>
              <SkeletonBlock height={40} width={174} borderRadius={0} />
            </GridBody>
            {data?.map((item: any) => (
              <GridBody />
            ))}
          </GridTR>
        ) : userLevel.length > 0 ? (
          userLevel?.map((item: any) => {
            const key = Object.keys(item);
            const profileImage = cloudinary.image(
              item[key[0]][0].profile_image ? item[key[0]][0].profile_image : DEFAULT_CLOUDINARY_IMAGE,
            );

            return (
              <GridTR key={`GridInnerTable-UserLevel-${key[0]}`}>
                <GridBody
                  style={{
                    backgroundColor: theme.WHITE_COLOR,
                    position: "sticky",
                    left: "0px",
                    zIndex: 0,
                  }}
                >
                  <Horizontal>
                    <AccountSettingsBorder>
                      <AdvancedImageStyle cldImg={profileImage} plugins={[lazyload()]} />
                    </AccountSettingsBorder>
                    <GridBodyRowLabel> {item[key[0]][0].row_label} </GridBodyRowLabel>
                    <UserIcon
                      data={
                        dataPreviews?.fetchUsersAndPreviews?.filter(
                          (preview: any) => preview.user_id === item[key[0]][0]?.user_id,
                        )[0]
                      }
                      loading={loadingPreviews}
                      error={errorPreviews}
                      user_id={item[key[0]][0]?.user_id}
                      isSharedView={!!sharedViewId}
                    />
                  </Horizontal>
                </GridBody>
                {item[key[0]]
                  .filter((item: any) =>
                    !topFunnelView
                      ? !topFunnelColumns?.includes(item?.metric_label)
                      : !bottomFunnelView
                      ? !bottomFunnelColumns?.includes(item?.metric_label)
                      : true,
                  )
                  ?.map((item: any) => (
                    <GridBody
                      selected={selectedCell?.id === item.id}
                      onClick={() => (!!sharedViewId ? null : setSelectedCell(item))}
                    >
                      <CellDataText>{formatCellData(item?.value, item?.metric_type)}</CellDataText>
                    </GridBody>
                  ))}
              </GridTR>
            );
          })
        ) : (
          <GridTR>
            <GridBody
              style={{
                backgroundColor: theme.WHITE_COLOR,
                position: "sticky",
                left: "0px",
                zIndex: 0,
              }}
            >
              {!firstExpand && <CellDataText>No Records Found</CellDataText>}
            </GridBody>
            {data?.map((item: any) => (
              <GridBody />
            ))}
          </GridTR>
        ))}
    </>
  );
};

const GridAllUsersTable: React.FC<any> = ({
  bottomFunnelColumns,
  bottomFunnelView,
  data,
  dataPreviews,
  errorPreviews,
  loadingPreviews,
  selectedCell,
  setSelectedCell,
  sharedViewId,
  topFunnelColumns,
  topFunnelView,
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data, dataPreviews?.fetchUsersAndPreviews, loadingPreviews, sharedViewId]);

  return data?.map((item: any) => {
    const key = Object.keys(item);
    const profileImage = cloudinary.image(
      item[key[0]][0].profile_image ? item[key[0]][0].profile_image : DEFAULT_CLOUDINARY_IMAGE,
    );
    return (
      <GridTR key={`GroupOptionLevel-${key[0]}`} style={{ backgroundColor: `${theme.WHITE_COLOR}` }}>
        <GridBody
          style={{
            position: "sticky",
            left: "0px",
            zIndex: 0,
            backgroundColor: `${theme.WHITE_COLOR}`,
          }}
        >
          <Horizontal>
            {!["Daily Stack Rank", "Monthly Stack Rank"].includes(item[key[0]][0]?.row_label) && (
              <AccountSettingsBorder>
                <AdvancedImageStyle cldImg={profileImage} plugins={[lazyload()]} />
              </AccountSettingsBorder>
            )}
            <GridBodyRowLabel>{item[key[0]][0].row_label}</GridBodyRowLabel>
            {!["Daily Stack Rank", "Monthly Stack Rank"].includes(item[key[0]][0]?.row_label) && (
              <UserIcon
                data={
                  dataPreviews?.fetchUsersAndPreviews?.filter(
                    (preview: any) => preview.user_id === item[key[0]][0]?.user_id,
                  )[0]
                }
                loading={loadingPreviews}
                error={errorPreviews}
                user_id={item[key[0]][0]?.user_id ?? ""}
                isSharedView={!!sharedViewId}
              />
            )}
          </Horizontal>
        </GridBody>
        {item[key[0]]
          .filter((item: any) =>
            !topFunnelView
              ? !topFunnelColumns?.includes(item?.metric_label)
              : !bottomFunnelView
              ? !bottomFunnelColumns?.includes(item?.metric_label)
              : true,
          )
          ?.map((cell: any) => (
            <GridBody
              selected={selectedCell?.id === cell.id}
              onClick={() => (!!sharedViewId ? null : setSelectedCell(cell))}
            >
              <CellDataText>{formatCellData(cell?.value, cell?.metric_type)}</CellDataText>
            </GridBody>
          ))}
      </GridTR>
    );
  });
};

interface IPerformanceChartData {
  id: string;
  computed_id?: string;
  row_label: string;
  metric: string;
  metric_label: string;
  metric_type: string;
  metric_tooltip: string;
  value: number;
  position?: string;
  rank?: number;
  user_id?: string;
  lowerbound_date?: string;
  upperbound_date?: string;
  team_id?: string;
  site_id?: string;
  profile_image?: string;
}

const EVENT_COLUMN_DATA = [
  { metric_label: "Time of Call", metric: "CallTime", fixed: false },
  { metric_label: "Event Type", metric: "EventType", fixed: false },
  { metric_label: "Contact Name", metric: "LeadName", fixed: false },
  { metric_label: "Business Name", metric: "BusinessName", fixed: false },
  { metric_label: "Sales Rep", metric: "RepName", fixed: false },
  { metric_label: "Set", metric: "Set", fixed: true },
  { metric_label: "Held", metric: "Held", fixed: true },
  { metric_label: "Closed", metric: "Close", fixed: true },
  { metric_label: "Time of Event", metric: "EventTime", fixed: false },
  { metric_label: "Sales Rep Who Set Demo", metric: "SetByUser", fixed: false },
  { metric_label: "Sales Rep Who made the Sale", metric: "ClosedByUser", fixed: false },
  { metric_label: "Industry", metric: "Industry", fixed: false },
  { metric_label: "Sub-Industry", metric: "SubIndustry", fixed: false },
  { metric_label: "Lead Source", metric: "LeadSource", fixed: false },
  { metric_label: "Call Result", metric: "ScheduleItemCallResult", fixed: false },
  { metric_label: "Days Set Out", metric: "DaysSetOut", fixed: false },
  { metric_label: "Dials: Current Sales Cycle", metric: "DialsInCurrentSalesCycle", fixed: false },
];

const FETCH_PIPELINE_COLUMNS = gql`
  query fetchPipelineColumn {
    fetchPipelineColumn {
      metric_label
      metric
    }
  }
`;

interface IActivityColumnDataColumn {
  metric_label?: string;
  metric?: string;
  fixed?: boolean;
}

export const ACTIVITY_COLUMN_DATA: IActivityColumnDataColumn[] = [
  { metric_label: "Skip", metric: "Skip", fixed: false },
  { metric_label: "Release", metric: "Release", fixed: false },
  { metric_label: "Avg Days from Set to Hold", metric: "AvgDialsToSetForEachLead", fixed: false },
  { metric_label: "Avg Time of First Dial", metric: "AvgTimeToFirstDial", fixed: false },
  { metric_label: "Inbound Lead Assignment to First Dial", metric: "InboundLeadAssignmentToFirstDial", fixed: false },
  { metric_label: "Avg Days to Close", metric: "MedianDaysToClose", fixed: false },
  { metric_label: "Avg Days from first dial to contact", metric: "MedianDaysFromFirstDialToContact", fixed: false },
  { metric_label: "Avg Days from contact to set", metric: "MedianDaysFromContactToSet", fixed: false },
  { metric_label: "Avg Days from Hold to Decision Call", metric: "MedianDaysFromHoldToDecisionCall", fixed: false },
  { metric_label: "Avg Days from Hold to Sale", metric: "MedianDaysFromHoldToSale", fixed: false },
  { metric_label: "Avg Days from Dec Call to Sale", metric: "MedianDaysFromDecisionCallToSale", fixed: false },
  { metric_label: "Call Coaching Requests", metric: "CallCoachingRequests", fixed: false },
  { metric_label: "Call Requests Received", metric: "ReviewsReceived", fixed: false },
  { metric_label: "# of self sourced leads", metric: "SelfSourcedLeads", fixed: false },
  { metric_label: "# of Transfers", metric: "Transfers", fixed: false },
];

const tabToAnalytics = (tableView: string) => {
  switch (tableView) {
    case "Transfer":
    case "TransferMetrics":
      return "Transfer Metrics";
    case "TransferQuality":
      return "Transfer Quality";
    default:
      return tableView;
  }
};

interface GridInfoTableProps {
  /**
   * Disables the group by option on the table
   */
  disableGroupBy?: boolean;
  /**
   * Hides the rep filter
   */
  hideRepFilter?: boolean;
  /**
   * Only returns data for the logged in rep. It will also not return any org level data
   */
  only_show_rep?: boolean;
  /**
   * expands the dropdown by default
   */
  expand_by_default?: boolean;
}

const GridInfoTableV2: React.FC<GridInfoTableProps> = ({
  expand_by_default,
  disableGroupBy,
  hideRepFilter,
  only_show_rep,
}) => {
  const [newSaveViewName, setNewSaveViewName] = useState("");
  const [exportLoading, setExportLoading] = useState(false);

  const [isShareable, setIsShareable] = useState(false);

  const [topFunnelView, setTopFunnelView] = useState(true);
  const [bottomFunnelView, setBottomFunnelView] = useState(true);

  // Pipeline Revenue Values
  const [topFunnelRevenue, setTopFunnelRevenue] = useState(0);
  const [bottomFunnelRevenue, setBottomFunnelRevenue] = useState(0);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const [groupReferences, setGroupReferences] = useState<string[]>([]);

  useEffect(() => {
    if (isFirstRender) {
      // Set isFirstRender to false to trigger a re-render
      setIsFirstRender(false);
    }
  }, [isFirstRender]);

  // set persist state
  useEffect(() => {
    const persistedStateRaw = localStorage.getItem(MANAGER_GRID_PERSIST_KEY);
    if (!persistedStateRaw) {
      return;
    }
    const persistedState = JSON.parse(persistedStateRaw);
    if (!persistedState) {
      return;
    }
  }, []);
  const {
    dateLabel,
    dateStart,
    dateEnd,
    tableView,
    setTableView,
    selectedView,
    setSelectedView,
    setDateStart,
    setDateEnd,
    setDateLabel,
    filterSidebarExpanded,
    gridFilter,
    gridModal,
    setGridModal,
    tableModal,
    setTableModal,
    selectedCell,
    setSelectedCell,
    groupOption,
    setGroupOption,
    measureBy,
    setMeasureBy,
    sortOption,
    setSortOption,
    callsExportModal,
    setCallsExportModal,
    repFilter,
    currentSavedView,

    setCurrentSavedView,
    setRepFilter,
    setGridFilter,
    columnOptions,
    setColumnOptions,
    isDefaultView,
    sharedViewId,
    funnelSegment,
    setFunnelSegment,
    tablePipelineMetric,
    setTablePipelineMetric,
    pipelineIgnoreCloseDatesComputed: pipelineIgnoreCloseDates,
    setPipelineIgnoreCloseDates,
    leadFilterArgs,
    repFilterArgs,
    dateFilterArgs,
    columnOptionArgs,
    groupOptionArgs,
    transferRole,
    setTransferRole,
    setFilterSidebarExpanded,
    resetGridFilters,
    leadActivityChannel,
  } = useContext(GridFilterContext);
  const {
    showDashboardViewModal,
    setShowDashboardViewModal,
    setUpdateDashboardView,
    setDeleteDashboardView,
    setShowRepFiltersModal,
    showDashboardShareViewModal,
    setShowDashboardShareViewModal,
  } = useContext(ModalContext);
  const [expandedState, setExpandedState] = useState(expand_by_default ? true : false);

  const selectedGridFilters = useMemo(
    () => Object.entries(gridFilter)?.map((item) => ({ key: item[0], value: formatFilterPills(item[0], item[1]) })),
    [gridFilter],
  );

  const filterCount = useMemo(() => selectedGridFilters.filter((item: any) => item?.value?.length > 0).length, [
    selectedGridFilters,
  ]);

  /**
   * Set the filters to the default view settings returned from the server
   * @param fetchDefaultSavedView
   * @returns void
   **/

  const setFiltersToDefaultView = (fetchDefaultSavedView: IDashboardSavedView) => {
    setRepFilter({
      team_ids: fetchDefaultSavedView?.rep_filter?.team_ids || [],
      site_ids: fetchDefaultSavedView?.rep_filter?.site_ids || [],
      roles: fetchDefaultSavedView?.rep_filter?.roles || [],
      user_ids: fetchDefaultSavedView?.rep_filter?.user_ids || [],
    });
    setGridFilter({
      channels: fetchDefaultSavedView?.lead_filter?.Channels || [],
      lead_sources: fetchDefaultSavedView?.lead_filter?.LeadSources || [],
      lead_creation_sources: fetchDefaultSavedView?.lead_filter?.LeadCreationSources || [],
      industries: fetchDefaultSavedView?.lead_filter?.primary_industries || [],
      sub_industries: fetchDefaultSavedView?.lead_filter?.sub_industries || [],
      next_scheduled_event: {
        next_scheduled_event_days: fetchDefaultSavedView?.lead_filter?.NextScheduledEventDays || undefined,
        next_scheduled_event_types: fetchDefaultSavedView?.lead_filter?.NextScheduledEventTypes || [],
      },
      sequences: fetchDefaultSavedView?.lead_filter?.sequences || [],
      custom_fields: [],
      lead_activity_channel: fetchDefaultSavedView?.lead_activity_channel || [],
    });
    setColumnOptions({
      visible_reporting_fields: fetchDefaultSavedView?.column_options?.visible_reporting_fields || [],
      visible_coaching_fields: fetchDefaultSavedView?.column_options?.visible_coaching_fields || [],
      visible_activity_fields: fetchDefaultSavedView?.column_options?.visible_activity_fields || [],
      visible_event_fields: fetchDefaultSavedView?.column_options?.visible_event_fields || [],
      visible_pipeline_fields: fetchDefaultSavedView?.column_options?.visible_pipeline_fields || [],
      visible_transfer_fields: fetchDefaultSavedView?.column_options?.visible_transfer_fields || [],
      visible_transfer_quality_fields: fetchDefaultSavedView?.column_options?.visible_transfer_quality_fields || [],
    });
    setDateLabel("ThisWeek");
    const currentDateLabel = fetchDefaultSavedView?.date_range || DEFAULT_DATE_LABEL;

    if (
      currentDateLabel === "CustomRange" &&
      fetchDefaultSavedView?.lowerbound_date &&
      fetchDefaultSavedView?.upperbound_date
    ) {
      setDateStart(moment().startOf("week").format("YYYY-MM-DD"));
      setDateEnd(moment().endOf("week").format("YYYY-MM-DD"));
    } else {
      const currentWeekStart = moment().startOf("week").format("YYYY-MM-DD");
      const currentWeekEnd = moment().endOf("week").format("YYYY-MM-DD");
      setDateStart(currentWeekStart);
      setDateEnd(currentWeekEnd);
    }
    !currentSavedView?.id && setCurrentSavedView({ ...fetchDefaultSavedView });
    fetchDefaultSavedView?.group_option && setGroupOption(fetchDefaultSavedView?.group_option);
    fetchDefaultSavedView?.measure_by && setMeasureBy(fetchDefaultSavedView?.measure_by);
  };
  interface IDateRangeOption {
    start: string;
    end: string;
    label: string;
    value: string;
  }

  const [DATE_RANGE_OPTIONS] = useState<IDateRangeOption[]>([
    { start: moment().startOf("day").format(), end: moment().endOf("day").format(), label: "Today", value: "Today" },
    {
      start: moment().subtract(24, "hours").startOf("day").format(),
      end: moment().subtract(24, "hours").endOf("day").format(),
      label: "Yesterday",
      value: "Yesterday",
    },
    {
      start: moment().startOf("week").format(),
      end: moment().endOf("week").format(),
      label: "This Week",
      value: "ThisWeek",
    },
    {
      start: moment().subtract(1, "week").startOf("week").format(),
      end: moment().subtract(1, "week").endOf("week").format(),
      label: "Last Week",
      value: "LastWeek",
    },
    {
      start: moment().startOf("month").format(),
      end: moment().endOf("day").format(),
      label: "Month to Date",
      value: "MonthToDate",
    },
    {
      start: moment().subtract(1, "months").startOf("month").format(),
      end: moment().subtract(1, "months").endOf("month").format(),
      label: "Last Month",
      value: "LastMonth",
    },
    {
      start: moment().startOf("year").format(),
      end: moment().endOf("day").format(),
      label: "Year to Date",
      value: "YearToDate",
    },
    {
      start: moment().subtract(1, "years").startOf("year").format(),
      end: moment().subtract(1, "years").endOf("year").format(),
      label: "Last Year",
      value: "LastYear",
    },
    {
      start: moment().subtract(1, "months").startOf("day").format(),
      end: moment().endOf("day").format(),
      label: "Custom Range",
      value: "CustomRange",
    },
  ]);

  const { transferQuality, hideAllExports } = useFlags();

  useEffect(() => {
    if (!sharedViewId) {
      // graphQL queries that are used to get the user's info
      // we moved them to here because they would error when called in a shared view
      fetchPipelineColumns();
      fetchUserCallReportPipelineColumns();
    }
  }, [sharedViewId]);

  useEffect(() => {
    setSelectedCell(undefined);
  }, [tableView]);

  useEffect(() => {
    setNewSaveViewName(currentSavedView ? currentSavedView.name : "");
    if (!!sharedViewId) {
      // sharedviewId is set when a not logged in user clicks on a shared view link
      fetchViewDataBySharedView();
    } else if (!!currentSavedView?.id) {
      // this is the basic fetch case will use any selected settings
      fetchViewData();
    }
  }, [currentSavedView?.id]);

  useEffect(() => {
    fetchViewData();
  }, [transferRole]);

  const { data: mrrLabel, loading: mrrLoading, error: mrrError } = useQuery(MRR_LABEL);

  const [
    fetchViewData,
    { data: dataTable, loading: loadingTable, error: errorTable, refetch: refetchTable },
  ] = useLazyQuery<IfetchPerformanceChartV2>(FETCH_PERFORMANCE_CHART, {
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
    },
    fetchPolicy: "no-cache",
    partialRefetch: false,
    nextFetchPolicy: "no-cache",
    // skip: !!sharedViewId,
    variables: {
      dashboard_type: tableView,
      dashboard_args: {
        lead_activity_channel: leadActivityChannel,
        lead_filter: leadFilterArgs,
        rep_filter: repFilterArgs,
        date_filter: dateFilterArgs,
        group_option: groupOptionArgs,
        measure_by: measureBy,
        column_options: columnOptionArgs,
        pipeline_metric_type: tableView === "Pipeline" ? tablePipelineMetric : undefined,
      },
    },
  });

  const [
    fetchViewDataBySharedView,
    { data: dataSharedView, loading: loadingSharedView, error: errorSharedView, refetch: refetchSharedView },
  ] = useLazyQuery(FETCH_DASHBOARD_SHARED_VIEW, {
    onCompleted({ fetchDashboardSharedView }) {
      setGridFilter({
        channels: fetchDashboardSharedView?.lead_filter?.Channels || [],
        lead_sources: fetchDashboardSharedView?.lead_filter?.LeadSources || [],
        lead_creation_sources: fetchDashboardSharedView?.lead_filter?.LeadCreationSources || [],
        industries: fetchDashboardSharedView?.lead_filter?.primary_industries || [],
        sub_industries: fetchDashboardSharedView?.lead_filter?.sub_industries || [],
        next_scheduled_event: {
          next_scheduled_event_days: fetchDashboardSharedView?.lead_filter?.NextScheduledEventDays || undefined,
          next_scheduled_event_types: fetchDashboardSharedView?.lead_filter?.NextScheduledEventTypes || [],
        },
        sequences: fetchDashboardSharedView?.lead_filter?.sequences || [],
        custom_fields: [],
        lead_activity_channel: fetchDashboardSharedView?.lead_activity_channel || [],
      });
      setGroupOption(fetchDashboardSharedView?.group_option);
      setMeasureBy(fetchDashboardSharedView?.measure_by || "user_id");
      setCurrentSavedView({ ...fetchDashboardSharedView });
      const currentDateLabel = fetchDashboardSharedView?.saved_view_data?.date_range || DEFAULT_DATE_LABEL;
      setDateLabel(currentDateLabel);
      if (currentDateLabel === "CustomRange") {
        setDateStart(fetchDashboardSharedView?.saved_view_data?.lowerbound_date || "");
        setDateEnd(fetchDashboardSharedView?.saved_view_data?.upperbound_date || "");
      } else {
        setDateStart(
          DATE_RANGE_OPTIONS.find((item: any) => item.value === fetchDashboardSharedView?.saved_view_data?.date_range)
            ?.start || "",
        );
        setDateEnd(
          DATE_RANGE_OPTIONS.find((item: any) => item.value === fetchDashboardSharedView?.saved_view_data?.date_range)
            ?.end || "",
        );
      }
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
    },
    fetchPolicy: "no-cache",
    partialRefetch: false,
    nextFetchPolicy: "no-cache",
    variables: {
      dashboard_type: tableView,
      saved_view_id: sharedViewId,
    },
  });

  const {
    data: defaultViewFilters,
    loading: loadingDefaultViewFilters,
    error: errorDefaultViewFilters,
  } = useQuery<IfetchDefaultSavedView>(FETCH_DASHBOARD_DEFAULT_VIEW, {
    fetchPolicy: "cache-and-network",
    onCompleted({ fetchDefaultSavedView }) {
      if (!currentSavedView?.id) {
        setFiltersToDefaultView(fetchDefaultSavedView);
      }
    },
  });

  const {
    data: dataSavedViews,
    loading: loadingSavedViews,
    error: errorSavedViews,
    refetch: refetchSavedViews,
  } = useQuery<IfetchAllSavedViews>(FETCH_ALL_SAVED_VIEW, {
    fetchPolicy: "no-cache",
    skip: !!sharedViewId,
  });

  const [
    fetchSavedViewFiltersFromId,
    { loading: loadingDashboardSavedView, error: errorDashboardSavedView },
  ] = useLazyQuery<IfetchDashboardSavedView>(FETCH_DASHBOARD_SAVED_VIEW, {
    fetchPolicy: "no-cache",
    partialRefetch: false,
    nextFetchPolicy: "no-cache",
    onCompleted({ fetchDashboardSavedView }) {
      setCurrentSavedView({ ...fetchDashboardSavedView });
      setGridFilter({
        channels: fetchDashboardSavedView?.lead_filter?.Channels || [],
        lead_sources: fetchDashboardSavedView?.lead_filter?.LeadSources || [],
        lead_creation_sources: fetchDashboardSavedView?.lead_filter?.LeadCreationSources || [],
        industries: fetchDashboardSavedView?.lead_filter?.primary_industries || [],
        sub_industries: fetchDashboardSavedView?.lead_filter?.sub_industries || [],
        next_scheduled_event: {
          next_scheduled_event_days: fetchDashboardSavedView?.lead_filter?.NextScheduledEventDays || undefined,
          next_scheduled_event_types: fetchDashboardSavedView?.lead_filter?.NextScheduledEventTypes || [],
        },
        sequences: fetchDashboardSavedView?.lead_filter?.sequences || [],
        custom_fields: fetchDashboardSavedView?.lead_filter?.custom_fields || [],
        lead_activity_channel: fetchDashboardSavedView?.lead_activity_channel || [],
      });
      setRepFilter({
        team_ids: fetchDashboardSavedView?.rep_filter?.team_ids || [],
        site_ids: fetchDashboardSavedView?.rep_filter?.site_ids || [],
        roles: fetchDashboardSavedView?.rep_filter?.roles || [],
        user_ids: fetchDashboardSavedView?.rep_filter?.user_ids || [],
      });
      setColumnOptions({
        visible_reporting_fields: fetchDashboardSavedView?.column_options?.visible_reporting_fields || [],
        visible_coaching_fields: fetchDashboardSavedView?.column_options?.visible_coaching_fields || [],
        visible_activity_fields: fetchDashboardSavedView?.column_options?.visible_activity_fields || [],
        visible_event_fields: fetchDashboardSavedView?.column_options?.visible_event_fields || [],
        visible_pipeline_fields: fetchDashboardSavedView?.column_options?.visible_pipeline_fields || [],
        visible_transfer_fields: fetchDashboardSavedView?.column_options?.visible_transfer_fields || [],
        visible_transfer_quality_fields: fetchDashboardSavedView?.column_options?.visible_transfer_quality_fields || [],
      });
      setDateLabel(fetchDashboardSavedView?.date_range || "This Week");
      if (fetchDashboardSavedView?.date_range === "CustomRange") {
        setDateStart(fetchDashboardSavedView?.lowerbound_date || "");
        setDateEnd(fetchDashboardSavedView?.upperbound_date || "");
      } else {
        const currentWeekStart = moment().startOf("week").format("YYYY-MM-DD");
        const currentWeekEnd = moment().endOf("week").format("YYYY-MM-DD");
        setDateStart(
          DATE_RANGE_OPTIONS.find((item: any) => item.value === fetchDashboardSavedView?.date_range)?.start ||
            currentWeekStart,
        );
        setDateEnd(
          DATE_RANGE_OPTIONS.find((item: any) => item.value === fetchDashboardSavedView?.date_range)?.end ||
            currentWeekEnd,
        );
      }

      setGroupOption(fetchDashboardSavedView?.group_option || "Team");
      setMeasureBy(fetchDashboardSavedView?.measure_by || "user_id");
      fetchViewData();
    },
  });
  const setFiltersToSavedView = async (id: string) => {
    await fetchSavedViewFiltersFromId({ variables: { saved_view_id: id } });
  };

  const [
    fetchUserCallReportPipelineColumns,
    { data: dataColumns, loading: loadingColumns, error: errorColumns },
  ] = useLazyQuery<IfetchUserCallReportEventColumns>(FETCH_USER_CALL_REPORT_PIPELINE_COLUMNS, {
    fetchPolicy: "network-only",
    onError({ message }) {
      // Sentry.captureEvent({
      //   message: `fetchUser for Call Report Columns GraphQL Error: ${message}`,
      // });
      appToast(message);
    },
  });

  const [
    updateDashboardSavedView,
    { data: dataUpdateDashboardSavedView, loading: loadingCreateDashboardView },
  ] = useMutation(UPDATE_DASHBOARD_SAVED_VIEW, {
    variables: {
      data: {
        lead_activity_channel: leadActivityChannel,
        shareable: isShareable,
        lead_filter: leadFilterArgs,
        rep_filter: repFilterArgs,
        date_filter: dateFilterArgs,
        group_option: groupOptionArgs,
        measure_by: measureBy,
        column_options: columnOptionArgs,
      },
      saved_view_id: currentSavedView?.id,
      // will update with the new name or default to the old one if blank
      name: newSaveViewName || currentSavedView?.name,
    },
    onCompleted({ updateDashboardSavedView }) {
      appToast(`Saved View ${updateDashboardSavedView?.name} Updated!`);
      setNewSaveViewName(updateDashboardSavedView?.name);
      setIsShareable(false);
    },
    onError({ message }) {
      appToast(`${message}`);
      Sentry.captureEvent({
        message: `updateDashboardSavedView GraphQL Error: ${message}`,
      });
      setIsShareable(false);
    },
    refetchQueries: ["fetchAllSavedViews"],
  });

  const [
    fetchPipelineColumns,
    { data: dataPipelineColumns, loading: loadingPipelineColumns, error: errorPipelineColumns },
  ] = useLazyQuery(FETCH_PIPELINE_COLUMNS, {
    fetchPolicy: "network-only",
    onError({ message }) {
      // Sentry.captureEvent({
      //   message: `fetchUser for Pipeline Columns GraphQL Error: ${message}`,
      // });
      appToast(message);
    },
    onCompleted() {},
  });

  const [
    exportDashboardView,
    { data: dataExportDashboardView, loading: loadingExportDashboardView },
  ] = useMutation<IExportDashboardView>(EXPORT_DASHBOARD_VIEW, {
    variables: {
      dashboard_type: tableView,
      dashboard_args: {
        lead_activity_channel: leadActivityChannel,
        lead_filter: leadFilterArgs,
        rep_filter: repFilterArgs,
        date_filter: dateFilterArgs,
        group_option: groupOptionArgs,
        measure_by: measureBy,
        column_options: columnOptionArgs,
        pipeline_metric_type: tablePipelineMetric,
      },
      group_references: groupReferences,
    },
    onCompleted({ exportDashboardView }) {
      appToast(`Exporting your view...`);
      if (!exportDashboardView) {
        appToast("Error exporting view. Something went wrong.");
        return;
      }
      downloadResourceAWS(exportDashboardView.url);
    },
    onError({ message }) {
      appToast(`${message}`);
      Sentry.captureEvent({
        message: `exportDashboardView GraphQL Error: ${message}`,
      });
      console.log("Error in exportDashboardView: ", message);
    },
  });

  const {
    data: dataBottomFunnelLeadsCount,
    loading: loadingBottomFunnelLeadsCount,
    error: errorBottomFunnelLeadsCount,
  } = useQuery(FETCH_BOTTOM_FUNNEL_LEAD_COUNT, {
    fetchPolicy: "network-only",
    skip: tableView !== "Pipeline",
    variables: {
      pipelineManagementArg: {
        lead_filter: leadFilterArgs,
        rep_filter: repFilterArgs,
        date_filter: dateFilterArgs,
      },
    },
  });

  const {
    data: dataTopFunnelLeadsCount,
    loading: loadingTopFunnelLeadsCount,
    error: errorTopFunnelLeadsCount,
  } = useQuery(FETCH_TOP_FUNNEL_LEAD_COUNT, {
    fetchPolicy: "network-only",
    skip: tableView !== "Pipeline",
    variables: {
      pipelineManagementArg: {
        lead_filter: leadFilterArgs,
        rep_filter: repFilterArgs,
        date_filter: dateFilterArgs,
      },
    },
  });

  const [exportPipelineReport, { loading: loadingExport }] = useMutation(EXPORT_PIPELINE_REPORT, {
    variables: {
      pipeline_export_arg: {
        lead_filter: leadFilterArgs,
        rep_filter: repFilterArgs,
        date_filter: dateFilterArgs,
        metric: selectedCell?.metric || null,
        position: selectedCell?.position || null,
        computed_id: selectedCell?.computed_id || null,
        lead_activity_channel: leadActivityChannel,
      },
      column_options: dataColumns?.fetchUser?.visible_call_report_pipeline_columns_computed,
      funnel: funnelSegment,
    },
    async onCompleted({ exportPipelineReport }) {
      if (!exportPipelineReport) {
        appToast("Error exporting calls. Something went wrong.");
        return;
      }
      appToast("Success! Download should begin shortly");
      !!exportPipelineReport.url &&
        downloadResourceAWS(exportPipelineReport.url, `callreports-${moment().format("YYYY-MM-DD-hhmma")}.csv`);
    },
    onError({ message }) {
      appToast(`${message}`);
      Sentry.captureEvent({
        message: `exportPipelineReport GraphQL Error: ${message}`,
      });
      console.log("Error in exportPipelineReport: ", message);
    },
  });

  useEffect(() => {
    setGroupReferences([]);
  }, [expandedState, groupOption]);

  const organizationLevel: IPerformanceChartData[] = useMemo(() => {
    if (!!sharedViewId) {
      return !!dataSharedView?.fetchDashboardSharedView?.stat_items
        ? dataSharedView.fetchDashboardSharedView?.stat_items.filter((item: IPerformanceChartData) => {
            return item.position === "Organization";
          })
        : [];
    } else {
      return !!dataTable?.fetchPerformanceChartV2
        ? dataTable.fetchPerformanceChartV2.filter((item: IPerformanceChartData) => {
            return item.position === "Organization";
          })
        : [];
    }
  }, [sharedViewId, dataSharedView?.fetchDashboardSharedView?.stat_items, dataTable?.fetchPerformanceChartV2]);

  type GroupOptionLevel = Array<{ [key: string]: IPerformanceChartData[] }>;
  const groupOptionLevel: GroupOptionLevel = useMemo(() => {
    let key = "row_label";

    let groupLevel: Record<string, IPerformanceChartData[]> = {}; // Update to use Record<string, IPerformanceChartData[]>

    if (!!sharedViewId) {
      groupLevel = !!dataSharedView?.fetchDashboardSharedView?.stat_items
        ? groupBy(dataSharedView.fetchDashboardSharedView?.stat_items, key)
        : [];
    } else {
      groupLevel = !!dataTable?.fetchPerformanceChartV2 ? groupBy(dataTable.fetchPerformanceChartV2, key) : [];
    }

    // null is "organization"
    delete groupLevel["null"];

    // hide company row if rep
    delete groupLevel["MY COMPANY METRICS"];

    let sortedGroupLevelKeys: string[] = [];
    let sortedGroupLevel: { [key: string]: IPerformanceChartData[] }[] = []; // Update the type here

    const groupLevelKeys = Object.keys(groupLevel);

    if (!isUserRep()) {
      // admin rows sorted by value

      sortedGroupLevelKeys = groupLevelKeys.sort((a: string, b: string) => {
        const aArray = groupLevel[a] as IPerformanceChartData[];
        const bArray = groupLevel[b] as IPerformanceChartData[];

        const aValueArray = !!sortOption ? aArray.find((item) => item.metric === sortOption) : aArray[0];
        const bValueArray = !!sortOption ? bArray.find((item) => item.metric === sortOption) : bArray[0];

        return (bValueArray?.value || 0) - (aValueArray?.value || 0);
      });

      sortedGroupLevelKeys.forEach((key: string) => {
        sortedGroupLevel.push({ [key]: groupLevel[key] });
      });
    } else {
      // if rep, return unsorted rows
      groupLevelKeys.forEach((key: string) => {
        sortedGroupLevel.push({ [key]: groupLevel[key] });
      });
    }

    return sortedGroupLevel;
  }, [groupOption, dataTable, measureBy, dataSharedView, sortOption, columnOptions, currentSavedView, tableView]);

  const skipUserPreviews: boolean = useMemo(() => {
    let valueOfRep = groupOptionLevel[0] && Object.values(groupOptionLevel[0]);
    const repDataExample = valueOfRep?.[0]?.[0];
    return (
      groupOption !== "None" ||
      (groupOption === "None" && !!valueOfRep && (!!repDataExample?.team_id || !!repDataExample?.site_id))
    );
  }, [groupOptionLevel]);

  const { data: dataPreviews, loading: loadingPreviews, error: errorPreviews } = useQuery<IfetchUsersAndPreviews>(
    FETCH_USER_PREVIEWS,
    {
      fetchPolicy: "network-only",
      skip: skipUserPreviews,
      variables: {
        user_ids: groupOptionLevel?.map((item: any) => {
          // the other dataPreview request seen in in the userLevel returns the user_id as a string
          // this one in the groupOptionLevel returns the rep name
          // in order to show the tooltip we can parse the user_id from the data returned

          // get the object that has all of the rep data
          const valueOfRep: Array<string> = Object.values(item);

          // get an example of the rep data to get the user_id
          const repDataExample: any = valueOfRep[0][0];

          // grab the user_id from the rep example
          const user_id = repDataExample?.user_id;

          return user_id;
        }),
      },
    },
  );

  const returnTooltipData = (item: string) => {
    let element;
    if (!!sharedViewId) {
      element = dataSharedView?.fetchDashboardSharedView?.stat_items.filter(
        (row: IPerformanceChartData) => row.metric_label === item,
      );
    } else {
      element = dataTable?.fetchPerformanceChartV2?.filter((row: IPerformanceChartData) => row?.metric_label === item);
    }

    return !!element[0]?.metric_tooltip ? element[0].metric_tooltip : element[0].metric_label;
  };

  const handleUpdateSaveView = async () => {
    MixpanelActions.track(!!isShareable ? "View Shared" : "Updated View", {
      view: `${tableView}`,
      group_option: `${groupOption}`,
      measure_by: `${measureBy}`,
      date_type: `${dateLabel}`,
    });

    await updateDashboardSavedView();
  };

  const handleExport = async () => {
    try {
      setExportLoading(true);
      await exportDashboardView();
      MixpanelActions.track("Performance Data Exported", {
        view: `${tableView}`,
        group_option: `${groupOption}`,
        measure_by: `${measureBy}`,
        date_type: `${dateLabel}`,
      });
    } catch (e) {
      console.log(`export failed`);
      appToast("Error exporting view");
    } finally {
      setExportLoading(false);
    }
  };

  const handleTabChange = (tabName: string) => {
    const analyticsName = tabToAnalytics(tabName);
    MixpanelActions.track("Performance Dashboard Tab Clicked", {
      tab_name: `${analyticsName.toLowerCase()}`,
      group_option: `${groupOption}`,
      measure_by: `${measureBy}`,
      date_type: `${dateLabel}`,
    });

    setTableView(tabName);
  };

  const scrollToDrilldown = () => {
    const tableElement = document.getElementById("drilldown-table");
    tableElement?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const renderDrilldownFunctions = ({ loading }: { loading: boolean }) => {
    if (loading) {
      return <SkeletonBlock height={300} width={"100%"} borderRadius={4} />;
    }

    if (selectedCell?.row_label === "Daily Stack Rank" || selectedCell?.row_label === "Monthly Stack Rank") {
      return <CallListActionsDiv />;
    }
    switch (tableView) {
      case "Coaching":
        if (!!selectedCell?.id) {
          return (
            <CallListActionsDiv>
              <InnerCallListActions
                style={{
                  justifyContent: "flex-end",
                  gap: 16,
                }}
              >
                {!hideAllExports && (
                  <ClickableText onClick={() => setCallsExportModal(!callsExportModal)}>Export</ClickableText>
                )}
                <ClickableText onClick={() => setTableModal(!tableModal)}>Manage Columns</ClickableText>
              </InnerCallListActions>
            </CallListActionsDiv>
          );
        }
      case "Event":
        if (!!selectedCell?.id) {
          return (
            <CallListActionsDiv>
              <InnerCallListActions
                style={{
                  justifyContent: "flex-end",
                  gap: 16,
                }}
              >
                {!hideAllExports && (
                  <ClickableText onClick={() => setCallsExportModal(!callsExportModal)}>Export</ClickableText>
                )}
                <ClickableText onClick={() => setTableModal(!tableModal)}>Manage Columns</ClickableText>
              </InnerCallListActions>
            </CallListActionsDiv>
          );
        }
      case "Transfer":
        if (
          !!selectedCell?.id &&
          [
            "TransferCallAnswered",
            "TransferCallIgnored",
            "TransferCallAnsweredDuration",
            "SDRHold",
            "SDRSale",
            "RevenuefromSDR",
            "SDRLiveTransferHold",
            "SDRLiveTransferSale",
            "SDRTransfer",
          ].includes(selectedCell?.metric ?? "")
        ) {
          return (
            <CallListActionsDiv>
              <InnerCallListActions
                style={{
                  justifyContent: "flex-end",
                  gap: 16,
                }}
              >
                {!hideAllExports && (
                  <ClickableText onClick={() => setCallsExportModal(!callsExportModal)}>Export</ClickableText>
                )}
                <ClickableText onClick={() => setTableModal(!tableModal)}>Manage Columns</ClickableText>
              </InnerCallListActions>
            </CallListActionsDiv>
          );
        }
      case "Pipeline":
        return (
          <CallListActionsDiv>
            <InnerCallListActions
              style={{
                justifyContent: "flex-end",
                gap: 16,
              }}
            >
              {!hideAllExports && <ClickableText onClick={() => exportPipelineReport()}>Export</ClickableText>}
              <ClickableText onClick={() => setTableModal(!tableModal)}>Manage Columns</ClickableText>
            </InnerCallListActions>
          </CallListActionsDiv>
        );
      case "Activity":
        if (!!selectedCell?.id) {
          return (
            <CallListActionsDiv>
              {ACTIVITY_COLUMN_DATA.filter((item: IActivityColumnDataColumn) => item.metric === selectedCell?.metric)
                ?.length > 0 && (
                <InnerCallListActions>
                  <AppIconButton
                    variant="secondary"
                    selected={selectedView === "Chart"}
                    onClick={() => setSelectedView("Chart")}
                  >
                    <BsGraphUp />
                  </AppIconButton>
                  <AppIconButton
                    variant="secondary"
                    selected={selectedView === "List"}
                    onClick={() => setSelectedView("List")}
                  >
                    <BsLayoutTextWindow />
                  </AppIconButton>
                </InnerCallListActions>
              )}
              {selectedView !== "Chart" && (
                <InnerCallListActions
                  style={{
                    justifyContent: "flex-end",
                    gap: 16,
                  }}
                >
                  {!hideAllExports && (
                    <ClickableText onClick={() => setCallsExportModal(!callsExportModal)}>Export</ClickableText>
                  )}
                  <ClickableText onClick={() => setTableModal(!tableModal)}>Manage Columns</ClickableText>
                </InnerCallListActions>
              )}
            </CallListActionsDiv>
          );
        }
      default:
        return <></>;
    }
  };

  const savedViewOptions = useMemo(
    () =>
      !!dataSavedViews?.fetchAllSavedViews && !!defaultViewFilters?.fetchDefaultSavedView
        ? [
            {
              label: defaultViewFilters?.fetchDefaultSavedView?.name || "",
              value: defaultViewFilters?.fetchDefaultSavedView?.id,
              hide_trash: true,
            },
            ...dataSavedViews?.fetchAllSavedViews?.map((item: ISavedView) => ({
              label: item?.name ?? "N/A",
              value: item?.id,
            })),
          ]
        : [],
    [dataSavedViews, defaultViewFilters],
  );
  const defaultDateRangeValue =
    DATE_RANGE_OPTIONS.find((option: OptionItem) => option.value === "ThisWeek")?.value || null;

  const Memoized30DayChart = useMemo(() => Render30DayChart, [
    selectedCell?.id,
    leadFilterArgs,
    repFilterArgs,
    dateFilterArgs,
    selectedCell?.metric,
    selectedCell?.position,
    selectedCell?.computed_id,
    leadActivityChannel,
  ]);

  const renderDrilldownTable = ({ loading }: { loading: boolean }) => {
    if (loading) {
      return <SkeletonBlock height={400} width={"100%"} borderRadius={4} />;
    }
    if (selectedCell?.row_label === "Daily Stack Rank" || selectedCell?.row_label === "Monthly Stack Rank") {
      // DAILY DATE WILL BE BASED ON TODAYS INTERVAL
      // MONTHLY DATE WILL BE BASED ON LAST MONTHS INTERVAL
      const lowerbound_date =
        selectedCell?.row_label === "Daily Stack Rank" ? DATE_RANGE_OPTIONS[0].start : DATE_RANGE_OPTIONS[4].start;
      const upperbound_date =
        selectedCell?.row_label === "Daily Stack Rank" ? DATE_RANGE_OPTIONS[0].end : DATE_RANGE_OPTIONS[4].end;
      return (
        <StackRankListTable
          key={`CallReportsRep${selectedCell?.metric}`}
          userID={selectedCell?.user_id}
          teamID={selectedCell?.team_id}
          orgID={selectedCell?.organization_id}
          gridFilter={true}
          metric={selectedCell?.metric}
          computedID={selectedCell?.computed_id}
          position={selectedCell?.position}
          lowerbound_date={moment(lowerbound_date).toDate()}
          upperbound_date={moment(upperbound_date).toDate()}
        />
      );
    }
    switch (tableView) {
      case "Coaching":
        return (
          <CallReportListV2
            key={`CallReportsRep${selectedCell?.metric}`}
            userID={selectedCell?.user_id}
            teamID={
              selectedCell?.group_option === "Team" && selectedCell?.position === "User"
                ? selectedCell?.group_reference
                : undefined
            }
            siteID={
              selectedCell?.group_option === "Site" && selectedCell?.position === "User"
                ? selectedCell?.group_reference
                : undefined
            }
            orgID={selectedCell?.organization_id}
            gridFilter={true}
            metric={selectedCell?.metric}
            computedID={selectedCell?.computed_id}
            position={selectedCell?.position}
          />
        );
      case "Event":
        return (
          <EventsListTableV2
            key={`EventsListRep${selectedCell?.metric}`}
            userID={selectedCell?.user_id}
            teamID={selectedCell?.team_id}
            siteID={selectedCell?.site_id}
            orgID={selectedCell?.organization_id}
            computedID={selectedCell?.computed_id}
            gridFilter={true}
            metric={selectedCell?.metric}
          />
        );

      case "Pipeline":
        return (
          <PipelineListTableV2
            key={`PipelineListRep${selectedCell?.metric}`}
            userID={selectedCell?.user_id}
            teamID={selectedCell?.team_id}
            orgID={selectedCell?.organization_id}
            siteID={selectedCell?.site_id}
            computedID={selectedCell?.computed_id}
            gridFilter={true}
            metric={selectedCell?.metric}
            dataAllColumns={dataPipelineColumns?.fetchPipelineColumn}
          />
        );
      // }
      case "Transfer":
        if (
          [
            "TransferCallAnswered",
            "TransferCallIgnored",
            "TransferCallAnsweredDuration",
            "SDRHold",
            "SDRSale",
            "RevenuefromSDR",
            "SDRLiveTransferHold",
            "SDRLiveTransferSale",
            "SDRTransfer",
          ].includes(selectedCell?.metric ?? "")
        ) {
          return (
            <TransferCallList
              key={`TransferCallList${selectedCell?.metric}`}
              userID={selectedCell?.user_id}
              teamID={selectedCell?.team_id}
              orgID={selectedCell?.organization_id}
              gridFilter={true}
              metric={selectedCell?.metric}
              computedID={selectedCell?.computed_id}
              position={selectedCell?.position}
              role={selectedCell?.role}
            />
          );
        } else
          return (
            <Memoized30DayChart
              selectedCell={selectedCell}
              leadFilterArgs={leadFilterArgs}
              repFilterArgs={repFilterArgs}
              dateFilterArgs={dateFilterArgs}
              leadActivityChannel={leadActivityChannel}
            />
          );
      case "Activity":
        if (
          ACTIVITY_COLUMN_DATA.filter((item: any) => item.metric === selectedCell?.metric)?.length > 0 &&
          selectedView === "List"
        ) {
          return (
            <CallReportListV2
              key={`CallReportsRep${selectedCell?.metric}`}
              userID={selectedCell?.user_id}
              teamID={selectedCell?.team_id}
              orgID={selectedCell?.organization_id}
              gridFilter={true}
              metric={selectedCell?.metric}
              computedID={selectedCell?.computed_id}
              position={selectedCell?.position}
            />
          );
        } else
          return (
            <Memoized30DayChart
              selectedCell={selectedCell}
              leadFilterArgs={leadFilterArgs}
              repFilterArgs={repFilterArgs}
              dateFilterArgs={dateFilterArgs}
              leadActivityChannel={leadActivityChannel}
            />
          );
      // this is the default case but it is being used for several tabs
      // to do: refactor this to be more specific and use the default as error handling
      default:
        return (
          <Memoized30DayChart
            selectedCell={selectedCell}
            leadFilterArgs={leadFilterArgs}
            repFilterArgs={repFilterArgs}
            dateFilterArgs={dateFilterArgs}
            leadActivityChannel={leadActivityChannel}
          />
        );
    }
  };

  // used as reference when filtering columns
  const topFunnelColumns = ["NDM Contact", "NDM Contact Interested", "DM Contact", "DM Contact Interested"];
  const bottomFunnelColumns = [
    "Demo Set",
    "Demo Set Flaked",
    "Demo Held",
    "Follow Up Demo Held",
    "Decision Call Held",
    "Sale",
  ];

  const loadingPage =
    loadingBottomFunnelLeadsCount ||
    loadingTopFunnelLeadsCount ||
    loadingDefaultViewFilters ||
    loadingDefaultViewFilters ||
    loadingDashboardSavedView ||
    loadingSharedView ||
    loadingColumns ||
    loadingPipelineColumns ||
    loadingExportDashboardView ||
    loadingCreateDashboardView ||
    loadingExport ||
    loadingTable;

  const errorOnAnyFetch =
    errorBottomFunnelLeadsCount ||
    errorTopFunnelLeadsCount ||
    errorPreviews ||
    errorDefaultViewFilters ||
    errorDefaultViewFilters ||
    errorDashboardSavedView ||
    errorSharedView ||
    errorColumns ||
    errorPipelineColumns;

  return (
    <LargeDiv expanded={filterSidebarExpanded}>
      {!!showDashboardViewModal && (
        <DashboardViewModal
          createViewArgs={{
            lead_filter: leadFilterArgs,
            rep_filter: repFilterArgs,
            date_filter: dateFilterArgs,
            group_option: groupOptionArgs,
            measure_by: measureBy,
            column_options: columnOptionArgs,
            lead_activity_channel: leadActivityChannel,
          }}
          isShareable={isShareable}
          setIsShareable={setIsShareable}
          newSaveViewName={newSaveViewName}
          setNewSaveViewName={setNewSaveViewName}
          currentSavedView={currentSavedView}
          handleDefaultSavedView={setFiltersToDefaultView}
          fetchDefaultSavedView={defaultViewFilters?.fetchDefaultSavedView}
        />
      )}

      {!!showDashboardShareViewModal && (
        <DashboardShareViewModal
          url={`${FRONTEND_URL}/share-view/${loggedInUser().organization_id}/${
            dataUpdateDashboardSavedView?.updateDashboardSavedView?.id
          }`}
        />
      )}

      <ReactTooltip
        multiline
        place="top"
        effect="solid"
        css={{
          maxWidth: 300,
          lineHeight: 1.4,
          textAlign: "center",
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor={theme.PRIMARY800}
        className="sib-tooltip"
        id="GridInfoTableV2header"
        getContent={(dataTip) => (
          <span
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "10px",
              lineHeight: "14px",
            }}
          >
            {dataTip}
          </span>
        )}
      />

      <StyledTooltip
        effect="solid"
        id={`user-status-tooltip`}
        html
        multiline
        place="top"
        backgroundColor={theme.PRIMARY800}
      />
      {gridModal && (
        <DarkDiv>
          <SlideInDiv>
            <ManageGridColumnsModal />
          </SlideInDiv>
        </DarkDiv>
      )}
      {!sharedViewId && tableModal && tableView === "Event" ? (
        <ManageTableEventColumnsModal dataAllColumns={EVENT_COLUMN_DATA} />
      ) : !sharedViewId && tableView === "Pipeline" ? (
        <ManageTablePipelineColumnsModal dataAllColumns={dataPipelineColumns?.fetchPipelineColumn} />
      ) : (
        !sharedViewId && <ManageTableColumnsModal dataAllColumns={MANAGE_TABLE_COLUMN_OPTIONS} />
      )}
      {callsExportModal && (
        <ExportCallListModal
          dashboardVars={{
            lead_filter: leadFilterArgs,
            rep_filter: repFilterArgs,
            date_filter: dateFilterArgs,
            metric: selectedCell?.metric,
            position: selectedCell?.position,
            computed_id: selectedCell?.computed_id,
            lead_activity_channel: leadActivityChannel,
          }}
        />
      )}

      <DashboardContainer>
        <FlexDiv justify="space-between" style={{ paddingBottom: "24px" }}>
          <AppText fontSize={22} fontWeight={600}>
            {!sharedViewId ? "Dashboard" : dataSharedView?.fetchDashboardSharedView?.saved_view_data?.name}
          </AppText>

          <FlexDiv gap={6} align="center">
            <PhoenixMultiSelect
              name="SavedViewInput"
              isMulti={false}
              isClearable={false}
              options={savedViewOptions}
              placeholder="Default View"
              onChange={(e: any) => {
                MixpanelActions.track("Saved View Selected", {
                  view: `${tableView}`,
                  group_option: `${groupOption}`,
                  measure_by: `${measureBy}`,
                  date_type: `${dateLabel}`,
                });
                if (!!e) {
                  setFiltersToSavedView(e.value);
                } else {
                  // set to default view (this is a fail safe)
                  setCurrentSavedView({ ...defaultViewFilters?.fetchDefaultSavedView });
                }
              }}
              width={240}
              maxHeight={40}
              minHeight={40}
              marginBottom={false}
              value={{ label: currentSavedView?.name, value: currentSavedView?.id }}
              isDisabled={!!sharedViewId}
              menuPortal
              showTrash={!loadingDefaultViewFilters}
              onTrashClick={(data: { label: string; value: unknown }) => {
                setShowDashboardViewModal(true);
                typeof data.value === "string" && setDeleteDashboardView(data);
              }}
            />

            {!sharedViewId && (
              <>
                <PhoenixAppButton
                  buttonType="secondary"
                  variant="brand-outline"
                  height={40}
                  width={145}
                  onClick={() => setShowDashboardViewModal(true)}
                >
                  <AppText
                    fontSize={10}
                    fontWeight={600}
                    lineHeight={16}
                    letterSpacing={1}
                    uppercase
                    color={theme.PRIMARY500}
                  >
                    Save New View
                  </AppText>
                </PhoenixAppButton>
                {!!currentSavedView?.id && !isDefaultView && (
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="brand-outline"
                    height={40}
                    width={190}
                    onClick={() => {
                      setShowDashboardViewModal(true);
                      setUpdateDashboardView(true);
                    }}
                  >
                    <AppText
                      fontSize={10}
                      fontWeight={600}
                      lineHeight={16}
                      letterSpacing={1}
                      uppercase
                      color={theme.PRIMARY500}
                    >
                      Update Current View
                    </AppText>
                  </PhoenixAppButton>
                )}
              </>
            )}

            {!sharedViewId && (
              <FlexDiv
                align="center"
                gap={4}
                style={{ paddingLeft: "8px", cursor: "pointer" }}
                onClick={() => {
                  if (!currentSavedView?.id) {
                    appToast(`Please save your view first!`);
                  } else {
                    setIsShareable(true);
                    handleUpdateSaveView().then(() => setShowDashboardShareViewModal(true));
                  }
                }}
              >
                <PhoenixIcon svg={share} size={16} color={theme.PRIMARY500} hoverColor={theme.PRIMARY500} pointer />
                <AppText
                  fontSize={10}
                  fontWeight={600}
                  lineHeight={16}
                  letterSpacing={1}
                  uppercase
                  color={theme.PRIMARY500}
                >
                  Share View
                </AppText>
              </FlexDiv>
            )}
          </FlexDiv>
        </FlexDiv>
        <TableSelectContainer>
          <TableSelectTop style={{ borderBottom: !!filterCount ? `1px solid ${theme.PRIMARY200}` : undefined }}>
            <TableSelect>
              {!disableGroupBy && (
                <PhoenixMultiSelect
                  name="GroupByInput"
                  isMulti={false}
                  isClearable={false}
                  options={GROUP_BY_OPTIONS?.map((item) => ({ label: item.label, value: item.value }))}
                  onChange={(e: any) => {
                    MixpanelActions.track("Dashboard", {
                      type: "Dashboard Group Option Changed",
                      view: `${tableView}`,
                      group_option: `${groupOption}`,
                      measure_by: `${measureBy}`,
                      date_type: `${dateLabel}`,
                    });
                    setGroupOption(e.value);
                  }}
                  value={GROUP_BY_OPTIONS.find((option: OptionItem) => option.value === groupOption)}
                  width={200}
                  minHeight={42}
                  marginBottom={false}
                  isDisabled={!!sharedViewId}
                  menuPortal
                />
              )}

              <FlexDiv
                gap={8}
                style={{
                  padding: !disableGroupBy ? "0px 16px" : "0px",
                  border: !disableGroupBy ? `solid ${theme.PRIMARY200}` : "none",
                  borderWidth: "0px 1px",
                }}
              >
                <PhoenixMultiSelect
                  name="DateRangeInput"
                  isMulti={false}
                  isClearable={false}
                  options={DATE_RANGE_OPTIONS?.map((item) => ({ label: item?.label, value: item?.value }))}
                  onChange={(e: any) => {
                    MixpanelActions.track("Dashboard", {
                      type: "Dashboard Filter Changed",
                      view: `${tableView}`,
                      group_option: `${groupOption}`,
                      measure_by: `${measureBy}`,
                      date_type: `${dateLabel}`,
                    });
                    setDateLabel(e.value);

                    const { start, end } = DATE_RANGE_OPTIONS?.find(
                      (option: OptionItem) => option?.value === e?.value,
                    ) as IDateRangeOption;

                    setDateStart(start);
                    setDateEnd(end);
                  }}
                  minHeight={42}
                  width={180}
                  marginBottom={false}
                  value={
                    DATE_RANGE_OPTIONS.find((option: OptionItem) => option.value === dateLabel) || {
                      label: "This Week",
                      value: "ThisWeek",
                    }
                  }
                  isDisabled={!!sharedViewId || pipelineIgnoreCloseDates}
                  menuPortal
                />

                <PhoenixDateRange
                  lowerbound_date={!!dateStart ? moment(dateStart) : moment()}
                  upperbound_date={!!dateEnd ? moment(dateEnd) : moment()}
                  disabled={!!sharedViewId || pipelineIgnoreCloseDates}
                  keepOpenOnDateSelect={false}
                  onDateChange={({ startDate, endDate }) => {
                    if (startDate && moment(startDate).isValid()) {
                      setDateLabel("CustomRange");
                      setDateStart(moment(startDate).startOf("day").format());
                    }
                    if (endDate && moment(endDate).isValid()) {
                      setDateLabel("CustomRange");
                      setDateEnd(moment(endDate).endOf("day").format());
                    }
                  }}
                  minWidth={220}
                  minHeight={42}
                  max_date={!["Pipeline", "Coaching", "Event"].includes(tableView) ? moment().endOf("day") : null}
                />
              </FlexDiv>
              {(tableView === "Coaching" || tableView === "Reporting") && (
                <MeasureByDiv>
                  <PhoenixMultiSelect
                    name="MeasureBy"
                    placeholder={"Measure By"}
                    isMulti={false}
                    isClearable={false}
                    options={MEASURE_BY_OPTIONS?.map((item) => ({ label: item.label, value: item.value }))}
                    onChange={(e: any) => {
                      MixpanelActions.track("Dashboard", {
                        type: "Dashboard Measure By Option Changed",
                        view: `${tableView}`,
                        group_option: `${groupOption}`,
                        measure_by: `${measureBy}`,
                        date_type: `${dateLabel}`,
                      });
                      setMeasureBy(e.value);
                    }}
                    value={MEASURE_BY_OPTIONS.find((option: OptionItem) => option.value === measureBy)}
                    width={200}
                    minHeight={42}
                    marginBottom={false}
                    isDisabled={!!sharedViewId}
                    menuPortal
                  />
                </MeasureByDiv>
              )}

              {!sharedViewId && (
                <FlexDiv gap={16} align="center">
                  {!hideAllExports && (
                    <FlexDiv
                      gap={4}
                      align="center"
                      style={{ cursor: "pointer" }}
                      onClick={() => !exportLoading && handleExport()}
                    >
                      <PhoenixIcon svg={download} size={16} variant="brand" hoverColor={theme.PRIMARY500} pointer />
                      <AppText fontSize={10} fontWeight={600} uppercase color={theme.PRIMARY500} letterSpacing={1}>
                        Export Data
                      </AppText>
                    </FlexDiv>
                  )}
                  <FlexDiv
                    gap={4}
                    align="center"
                    style={{ cursor: tableView !== "Pipeline" ? "pointer" : "unset" }}
                    onClick={() => tableView !== "Pipeline" && setGridModal(!gridModal)}
                  >
                    <PhoenixIcon
                      svg={settings}
                      size={16}
                      color={tableView !== "Pipeline" ? theme.PRIMARY500 : theme.NEUTRAL200}
                      hoverColor={tableView !== "Pipeline" ? theme.PRIMARY500 : theme.NEUTRAL200}
                      pointer={tableView !== "Pipeline"}
                    />
                    <AppText
                      fontSize={10}
                      fontWeight={600}
                      uppercase
                      color={tableView !== "Pipeline" ? theme.PRIMARY500 : theme.NEUTRAL200}
                      letterSpacing={1}
                    >
                      Manage Columns
                    </AppText>
                  </FlexDiv>
                </FlexDiv>
              )}
            </TableSelect>
            <FilterButton onClick={() => setFilterSidebarExpanded(true)}>
              {!!filterCount && <FilterButtonDot />}
              <PhoenixIcon svg={filter} size={16} pointer variant="white" />
            </FilterButton>
          </TableSelectTop>

          {!!filterCount && (
            <TableSelectBottom gap={4} align="center" justify="flex-start">
              <FlexDiv gap={4} style={{ paddingRight: "16px", cursor: "pointer" }} onClick={() => resetGridFilters()}>
                <PhoenixIcon svg={refresh} size={16} color={theme.PRIMARY500} hoverColor={theme.PRIMARY500} />
                <AppText fontSize={10} fontWeight={600} uppercase color={theme.PRIMARY500} letterSpacing={1} noWrap>
                  Reset Filters
                </AppText>
              </FlexDiv>
              {selectedGridFilters
                ?.flatMap(({ key, value }) =>
                  value?.map((filter: any) => (
                    <FilterPill key={`${key}-${filter}`}>
                      <AppText fontSize={8} fontWeight={600} color={theme.WHITE_COLOR} uppercase noWrap>
                        {key === "lead_creation_sources" ? formatLeadCreationName(filter) : filter}
                      </AppText>
                      <PhoenixIcon
                        svg={xIcon}
                        size={12}
                        color={theme.WHITE_COLOR}
                        hoverColor={theme.WHITE_COLOR}
                        pointer
                        onClick={() => {
                          const newGridFilter = cloneDeep(gridFilter) as any;
                          if (!key) return;
                          if (key === "next_scheduled_event") {
                            newGridFilter[key].next_scheduled_event_types = newGridFilter[
                              key
                            ]?.next_scheduled_event_types?.filter((item: any) => item !== filter);
                          } else {
                            newGridFilter[key] =
                              key === "custom_fields"
                                ? newGridFilter[key].filter((item: any) => item?.key !== filter)
                                : key === "sequences"
                                ? newGridFilter[key].filter((item: any) => item?.label !== filter)
                                : value.filter((item: any) => item !== filter);
                          }
                          setGridFilter(newGridFilter);
                        }}
                      />
                    </FilterPill>
                  )),
                )
                ?.flat()}
            </TableSelectBottom>
          )}
        </TableSelectContainer>
        <TableBorderDiv>
          <TabDiv>
            <ToggleView>
              <AppTabDiv
                style={{ width: 141 }}
                selected={tableView === "Reporting"}
                onClick={() => {
                  handleTabChange("Reporting");
                }}
              >
                <PhoenixIcon style={{ paddingRight: 6 }} svg={file_text} size={12} variant="brand" pointer />
                Reporting
              </AppTabDiv>
              <AppTabDiv
                style={{ width: 141 }}
                selected={tableView === "Coaching"}
                onClick={() => {
                  handleTabChange("Coaching");
                }}
              >
                <PhoenixIcon style={{ paddingRight: 6 }} svg={clipboard} size={12} variant="brand" pointer />
                Coaching
              </AppTabDiv>
              {/* {!isProduction && (
                <AppTabDiv
                  style={{ width: 141 }}
                  selected={tableView === "Activity"}
                  onClick={() => {
                    handleTabChange("Activity");
                  }}
                >
                  <PhoenixIcon style={{ paddingRight: 6 }} svg={activity} size={12} variant="brand" />
                  Activity
                </AppTabDiv>
              )} */}
              <AppTabDiv
                style={{ width: 141 }}
                selected={tableView === "Event"}
                onClick={() => {
                  handleTabChange("Event");
                }}
              >
                <PhoenixIcon style={{ paddingRight: 6 }} svg={calendar} size={12} variant="brand" pointer />
                Event
              </AppTabDiv>
              <AppTabDiv
                style={{ width: 141 }}
                selected={tableView === "Pipeline"}
                onClick={() => {
                  handleTabChange("Pipeline");
                }}
              >
                <PhoenixIcon style={{ paddingRight: 6 }} svg={filter} size={12} variant="brand" pointer />
                Pipeline
              </AppTabDiv>
              <AppTabDiv
                style={{ width: 175 }}
                selected={tableView === "Transfer"}
                onClick={() => {
                  handleTabChange("Transfer");
                }}
              >
                <PhoenixIcon style={{ paddingRight: 6 }} svg={repeat} size={12} variant="brand" pointer />
                Transfer Metrics
              </AppTabDiv>
              {transferQuality && (
                <AppTabDiv
                  style={{ width: 175 }}
                  selected={tableView === "TransferQuality"}
                  onClick={() => {
                    handleTabChange("TransferQuality");
                  }}
                >
                  <PhoenixIcon style={{ paddingRight: 6 }} svg={shield} size={12} variant="brand" pointer />
                  Transfer Quality
                </AppTabDiv>
              )}
            </ToggleView>
            <TabFill />
          </TabDiv>
          {tableView === "Pipeline" && (
            <FlexDiv align="center" justify="space-between" style={{ paddingTop: "24px" }}>
              <FlexDiv gap={8} align="center" style={{ height: "fit-content", marginRight: "72px" }}>
                <Switch
                  onChange={(checked) => setPipelineIgnoreCloseDates(checked)}
                  disabled={loadingTable}
                  onColor={theme.PRIMARY500}
                  checked={pipelineIgnoreCloseDates}
                  height={16}
                  width={32}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  handleDiameter={12}
                />
                <AppText fontSize={12} fontWeight={500} style={{ whiteSpace: "nowrap" }}>
                  Ignore Close Dates
                </AppText>
                <PhoenixIcon
                  data-tip={"Include all leads regardless of the Close Date field."}
                  size="small"
                  variant="neutral"
                  svg={info}
                />
              </FlexDiv>

              <FunnelSummaryContainer>
                <FunnelSummaryDiv selected={funnelSegment === "Top" && !sharedViewId} view={topFunnelView}>
                  <FlexDiv align="center">
                    <FunnelHeading>
                      Top Funnel <br /> Summary
                    </FunnelHeading>

                    {topFunnelView && (
                      <StatWrapper>
                        <VerticalDiv>
                          <FunnelValue>
                            {!loadingTopFunnelLeadsCount &&
                              !errorTopFunnelLeadsCount &&
                              dataTopFunnelLeadsCount?.fetchTopFunnelLeadCount &&
                              dataTopFunnelLeadsCount.fetchTopFunnelLeadCount.toLocaleString()}
                          </FunnelValue>
                          <FunnelLabel>Total Leads</FunnelLabel>
                        </VerticalDiv>
                        <VerticalHr />
                        <VerticalDiv>
                          <FunnelValue>{formatUSDRaw(topFunnelRevenue)}</FunnelValue>
                          <FunnelLabel>Forecasted Revenue</FunnelLabel>
                        </VerticalDiv>
                      </StatWrapper>
                    )}
                  </FlexDiv>

                  <FlexDiv align="center" gap={24}>
                    {!sharedViewId && topFunnelView && (
                      <PhoenixAppButton
                        variant="brand-outline"
                        buttonType="tertiary"
                        buttonTextFontSize={10}
                        uppercase
                        onClick={() => {
                          setFunnelSegment("Top");
                          scrollToDrilldown();
                          MixpanelActions.track(`Top Funnel View Leads clicked`);
                        }}
                      >
                        View Leads
                      </PhoenixAppButton>
                    )}
                    <Switch
                      onChange={(checked) => setTopFunnelView(checked)}
                      disabled={!bottomFunnelView || loadingTable}
                      onColor={theme.PRIMARY500}
                      checked={topFunnelView}
                      height={16}
                      width={32}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      handleDiameter={12}
                    />
                  </FlexDiv>
                </FunnelSummaryDiv>

                <FunnelSummaryDiv bottom selected={funnelSegment === "Bottom" && !sharedViewId} view={bottomFunnelView}>
                  <FlexDiv align="center" gap={24}>
                    <FunnelHeading>
                      Bottom Funnel <br /> Summary
                    </FunnelHeading>

                    {bottomFunnelView && (
                      <StatWrapper>
                        <VerticalDiv>
                          <FunnelValue>
                            {!loadingBottomFunnelLeadsCount &&
                              !errorBottomFunnelLeadsCount &&
                              dataBottomFunnelLeadsCount?.fetchBottomFunnelLeadCount &&
                              dataBottomFunnelLeadsCount.fetchBottomFunnelLeadCount.toLocaleString()}
                          </FunnelValue>
                          <FunnelLabel>Total Leads</FunnelLabel>
                        </VerticalDiv>
                        <VerticalHr />
                        <VerticalDiv>
                          <FunnelValue>{formatUSDRaw(bottomFunnelRevenue)}</FunnelValue>
                          <FunnelLabel>Forecasted Revenue</FunnelLabel>
                        </VerticalDiv>
                      </StatWrapper>
                    )}
                  </FlexDiv>

                  <FlexDiv align="center" gap={24}>
                    {!sharedViewId && bottomFunnelView && (
                      <PhoenixAppButton
                        variant="brand-outline"
                        buttonType="tertiary"
                        buttonTextFontSize={10}
                        uppercase
                        onClick={() => {
                          setFunnelSegment("Bottom");
                          scrollToDrilldown();
                          MixpanelActions.track(`Bottom Funnel View Leads clicked`);
                        }}
                      >
                        View Leads
                      </PhoenixAppButton>
                    )}
                    <Switch
                      onChange={(checked) => setBottomFunnelView(checked)}
                      disabled={!topFunnelView || loadingTable}
                      onColor={theme.PRIMARY500}
                      checked={bottomFunnelView}
                      height={16}
                      width={32}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      handleDiameter={12}
                    />
                  </FlexDiv>
                </FunnelSummaryDiv>
              </FunnelSummaryContainer>
            </FlexDiv>
          )}

          {(tableView === "Transfer" || tableView === "TransferQuality") && (
            <SelectRoleContainer>
              <AppText fontSize={12} fontWeight={500} style={{ marginBottom: "8px" }}>
                Select role
              </AppText>
              <NewAppSelect
                options={[
                  { label: "AE", value: "AE" },
                  { label: "SDR", value: "SDR" },
                ]}
                name="transfer_role"
                value={transferRole}
                onChange={async (e: any) => {
                  setTransferRole(e?.value);
                }}
                isDisabled={loadingTable}
                noErrorNeeded
                menuShouldBlockScroll={true}
              />
            </SelectRoleContainer>
          )}

          <TableContainer onlyShowRep={only_show_rep} expanded={expandedState && !only_show_rep}>
            {loadingPage && (
              <FlexDiv gap={8}>
                <SkeletonBlock height={40} width={150} borderRadius={4} style={{ marginTop: "40px" }} />
                {Array.from({ length: 16 }).map((_, index) => (
                  <SkeletonBlock key={index} height={80} width={78} borderRadius={4} delayNumber={index / 2} />
                ))}
              </FlexDiv>
            )}
            {errorOnAnyFetch && (
              <div style={{ width: "93%", position: "absolute", paddingLeft: 16 }}>
                <AppText variant="error">Error loading Dashboard</AppText>
              </div>
            )}
            {(!!dataTable || !!dataSharedView) && !(loadingTable || loadingSharedView) && (
              <GridTable loading={loadingTable}>
                <thead>
                  <GridTR style={{ backgroundColor: `${theme.NEUTRAL100}`, position: "sticky", top: "0px", zIndex: 1 }}>
                    <GridTH
                      style={{
                        borderLeft: "none",
                        left: "0px",
                      }}
                    >
                      {tableView === "Pipeline" && (
                        <NewAppSelect
                          options={[
                            { label: "Lead Count", value: "LeadCount" },
                            { label: mrrLabel?.getMrrLabel, value: "MRR" },
                          ]}
                          name="close_date_default_value"
                          value={tablePipelineMetric}
                          onChange={async (e: any) => {
                            setTablePipelineMetric(e.value);
                          }}
                          noErrorNeeded
                          menuPortal
                          menuShouldBlockScroll
                        />
                      )}
                    </GridTH>
                    {organizationLevel
                      ?.filter((item: any) =>
                        !topFunnelView
                          ? !topFunnelColumns.includes(item?.metric_label)
                          : !bottomFunnelView
                          ? !bottomFunnelColumns.includes(item?.metric_label)
                          : true,
                      )
                      ?.map((item: any, index: number) => (
                        <TableStickyHeader
                          key={`TH${item.metric_label}`}
                          item={item}
                          canSort={!isUserRep()}
                          dataFor={sharedViewId ? undefined : "GridInfoTableV2header"}
                          dataTip={returnTooltipData(item?.metric_label)}
                          backgroundColor={
                            tableView === "Pipeline"
                              ? topFunnelColumns.includes(item?.metric_label)
                                ? theme.TERTIARY500
                                : theme.SUCCESS500
                              : undefined
                          }
                        />
                      ))}
                  </GridTR>
                  {!only_show_rep && (
                    <GridTR
                      style={{
                        backgroundColor: theme.WHITE_COLOR,
                        position: "sticky",
                        top: "43px",
                        zIndex: 1,
                      }}
                    >
                      <GridTH
                        style={{
                          left: "0px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: "0px 10px 0px 10px",
                          }}
                        >
                          <GridBodyRowLabel>My Company Metrics</GridBodyRowLabel>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            {!sharedViewId && (
                              <AppIconButton
                                onClick={() => {
                                  setShowRepFiltersModal(true);
                                }}
                                style={{
                                  padding: "0px 2px 0px 2px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FaFilter style={{ height: "10px" }} />
                              </AppIconButton>
                            )}
                            <AppIconButton
                              onClick={() => {
                                if (!expandedState) {
                                  MixpanelActions.track(`Metric Dropdown Expanded`, {
                                    tab_name: tabToAnalytics(tableView),
                                  });
                                }
                                setExpandedState(!expandedState);
                              }}
                              style={{
                                padding: "0px 2px 0px 2px",
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                              }}
                            >
                              <ArrowDiv style={{ height: "8px", cursor: "pointer" }}>
                                {expandedState ? (
                                  <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                                ) : (
                                  <img src={showMoreArrow} alt="Expand" />
                                )}
                              </ArrowDiv>
                            </AppIconButton>
                          </div>
                        </div>
                      </GridTH>
                      {organizationLevel
                        .filter((item: any) =>
                          !topFunnelView
                            ? !topFunnelColumns.includes(item?.metric_label)
                            : !bottomFunnelView
                            ? !bottomFunnelColumns.includes(item?.metric_label)
                            : true,
                        )
                        ?.map((item: any) => (
                          <GridTH
                            selected={selectedCell?.id === item.id}
                            onClick={() => (!!sharedViewId ? null : setSelectedCell(item))}
                            style={{
                              boxShadow:
                                selectedCell?.id === item.id ? `inset 0px 0px 2px 2px ${theme.PRIMARY500}` : "none",
                              fontSize: "10px",
                            }}
                          >
                            {formatCellData(item?.value, item?.metric_type)}
                          </GridTH>
                        ))}
                    </GridTR>
                  )}
                </thead>

                <tbody>
                  {expandedState &&
                    (groupOption === "Site" || groupOption === "Team" ? (
                      groupOptionLevel?.map((item, index: number) => {
                        const key = Object.keys(item);
                        return (
                          <GridInnerTableComponent
                            loading={loadingTable}
                            data={item[key[0]]}
                            key={`GroupOptionLevel-${key[0]}`}
                            topFunnelView={topFunnelView}
                            bottomFunnelView={bottomFunnelView}
                            topFunnelColumns={topFunnelColumns}
                            bottomFunnelColumns={bottomFunnelColumns}
                            selectedCell={selectedCell}
                            setSelectedCell={setSelectedCell}
                          />
                        );
                      })
                    ) : (
                      <GridAllUsersTable
                        bottomFunnelColumns={bottomFunnelColumns}
                        bottomFunnelView={bottomFunnelView}
                        data={groupOptionLevel}
                        dataPreviews={dataPreviews}
                        errorPreviews={errorPreviews}
                        loadingPreviews={loadingPreviews}
                        selectedCell={selectedCell}
                        sharedViewId={sharedViewId}
                        topFunnelColumns={topFunnelColumns}
                        topFunnelView={topFunnelView}
                        setSelectedCell={setSelectedCell}
                      />
                    ))}
                </tbody>
              </GridTable>
            )}
          </TableContainer>
        </TableBorderDiv>
      </DashboardContainer>

      {tableView === "Pipeline" && (
        <RevenueForecastContainer>
          <RevenueForecastTable
            setBottomFunnelRevenue={setBottomFunnelRevenue}
            setTopFunnelRevenue={setTopFunnelRevenue}
          />
        </RevenueForecastContainer>
      )}
      {!sharedViewId &&
        (!!selectedCell?.id ||
          (tableView === "Pipeline" && (funnelSegment === "Top" || funnelSegment === "Bottom"))) && (
          <ListViewContainer id="drilldown-table">
            {renderDrilldownFunctions({
              loading: loadingPage,
            })}
            {renderDrilldownTable({
              loading: loadingPage,
            })}
          </ListViewContainer>
        )}
    </LargeDiv>
  );
};

const formatLeadCreationName = (name: string) => {
  switch (name) {
    case "self_sourced":
      return "Self Sourced";
    case "CSV":
      return "CSV Upload";
    case "as_admin":
      return "Admin Upload";
    case "hubspot":
      return "Hubspot";
    case "salesforce":
      return "Salesforce";
    default:
      return name;
  }
};

const formatFilterPills = (key: string, value: any) => {
  switch (key) {
    case "next_scheduled_event":
      return value?.next_scheduled_event_types;
    case "sequences":
      return value?.map(({ label }: any) => label);
    case "custom_fields":
      return value?.map(({ key }: any) => key);
    default:
      return value;
  }
};

const MeasureByDiv = styled.div`
  padding-right: 16px;
  border-right: 1px solid ${theme.PRIMARY200};
`;

const FilterButton = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  padding: 8px;

  border-radius: 4px;
  cursor: pointer;
  background-color: ${theme.PRIMARY500};
`;

const FilterButtonDot = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;

  width: 14px;
  height: 14px;

  background-color: ${theme.DANGER600};
  border: 1px solid ${theme.PRIMARY500};
  border-radius: 50%;
`;

const ListViewContainer = styled.div`
  border-radius: 8px;
  background-color: ${theme.WHITE_COLOR};
  width: 100%;
  padding: 24px;
  margin-top: 16px;
`;

const RevenueForecastContainer = styled.div`
  background-color: ${theme.WHITE_COLOR};
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  /* border: 1px solid ${theme.NEUTRAL200}; */
  box-shadow: 0 4px 8px 0 rgba(224, 224, 224, 0.5);
  max-width: inherit;
  min-width: 1578px;
`;

const FunnelValue = styled(AppText)`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  max-width: 188px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FunnelLabel = styled(AppText)`
  color: ${theme.NEUTRAL350};
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
`;

const FunnelHeading = styled(AppText)`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  min-width: 65px;
  margin-right: 24px;
`;

const VerticalHr = styled.div`
  height: 42px;
  width: 1px;
  margin: 0px 24px;
  background-color: ${theme.NEUTRAL200};
`;

const VerticalDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ITopBool {
  selected?: boolean;
  bottom?: boolean;
  view: boolean;
}

const FunnelSummaryDiv = styled.div<ITopBool>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: ${(props) => (props.view ? "100%" : "fit-content")};
  min-width: ${(props) => (props.view ? "512px" : "158px")};
  max-width: 695px;
  height: 70px;
  padding: 16px;

  border: ${(props) =>
    props.bottom
      ? `2px solid ${props.view ? theme.SUCCESS500 : theme.NEUTRAL200}`
      : `2px solid ${props.view ? theme.WARNING500 : theme.NEUTRAL200}`};
  border-radius: 8px;

  transition: border-color 0.2s ease;
`;

const FunnelSummaryContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  width: 100%;
  height: fit-content;
`;

const CallListActionsDiv = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  height: 40px;
`;

const InnerCallListActions = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 26px;
`;

interface ClickableTextProps {
  disabled?: boolean;
}

const ClickableText = styled(AppText)<ClickableTextProps>`
  width: fit-content;
  border-bottom: none;
  color: ${(props) => (props.disabled ? theme.PRIMARY100 : theme.PRIMARY500)};
  font-weight: 600;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;

const slideInAnimation = keyframes`
  0% { margin-left: 0px; width: 0px; }
  100% { margin-left: 0px; width: 480px; }
`;

const DarkDiv = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const SlideInDiv = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 478px;
  min-height: 100vh;
  overflow: hidden;
  animation-name: ${slideInAnimation};
  animation-duration: 700ms;
  background-color: ${theme.WHITE_COLOR};
`;

const DashboardContainer = styled.div`
  background-color: ${theme.WHITE_COLOR};
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const TableSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  align-items: center;
  background-color: ${theme.PRIMARY50};
  border-radius: 8px;
  min-height: 60px;
`;

const TableSelectTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 16px 0px;

  width: 100%;
  height: 100%;
`;

const TableSelectBottom = styled(FlexDiv)`
  overflow-x: auto;
  width: 100%;
  padding: 16px 0px;
`;

const FilterPill = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  width: fit-content;
  height: 24px;
  padding: 4px 8px;

  background-color: ${theme.PRIMARY500};
  border-radius: 4px;
`;

const TableSelect = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  flex-grow: 2;
`;

const TabDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const ToggleView = styled.div`
  display: flex;
  flex-direction: row;
`;

const TabFill = styled.div`
  width: 100%;
  border-bottom: 2px solid ${theme.NEUTRAL200};
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 176px;
  padding: 0px 12px 0px 12px;
  gap: 4px;
  width: 100%;
`;

const GridTR = styled.tr``;

interface LoadingTableProp {
  loading: boolean;
}

const GridTable = styled.table<LoadingTableProp>`
  pointer-events: ${(props) => (props.loading ? "none" : "auto")};
  cursor: ${(props) => (props.loading ? "wait" : "auto")};
  border-collapse: separate;
  border-spacing: 0;
  min-width: 100%;
  /* overflow: hidden; */
  animation: ${theme.fadeIn} 0.15s ease-in-out forwards;
`;

const StatWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TableBorderDiv = styled.div`
  margin-bottom: 16px;
`;

interface TableContainerProps {
  expanded?: boolean;
  onlyShowRep?: boolean;
}
const TableContainer = styled.div<TableContainerProps>`
  margin-top: 23px;
  height: ${(props) => (props.expanded ? "440px" : props.onlyShowRep ? "180px" : "140px")};
  //width needs to be calculated b/c of sticky headers  -- 100vw - side nav - padding + margin on both sides of main content
  /* width: calc(100vw - 72px - 186px); */
  width: 100%;
  overflow: auto;
`;

const GridBodyRowLabel = styled(AppText)`
  font-size: 9px;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: 600;
  text-align: left;
  max-width: 118px;
`;

const CellDataText = styled(AppText)`
  font-size: 10px;
  font-weight: 400;
  width: 100%;
`;

interface SelectedProp {
  selected?: boolean;
}

const GridTH = styled.th<SelectedProp>`
  text-align: center;
  border: 1px solid ${theme.NEUTRAL200};

  box-sizing: border-box;
  min-width: 80px;
  max-width: 217px;
  height: 40px;
  font-size: 9px;
  font-weight: 600;
  background-color: inherit;
  word-break: "break";
  cursor: pointer;
  &:first-child {
    width: 217px;
    background-color: ${theme.WHITE_COLOR};
    border-top: none;
  }
`;

const GridBody = styled.td<SelectedProp>`
  border: 1px solid ${theme.NEUTRAL200};

  box-sizing: border-box;
  text-align: center;
  min-width: 80px;
  max-width: 176px;
  height: 40px;
  cursor: pointer;
  background-color: inherit;
  box-shadow: ${(props) => (!!props.selected ? `inset 0px 0px 0px 2px ${theme.PRIMARY500}` : "none")};
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  cursor: pointer;
`;

interface ExpandedProps {
  expanded?: boolean;
}

const LargeDiv = styled.div<ExpandedProps>`
  margin: 28px;
  border-radius: 8px;
  /* padding: 42px 40px 0px 40px; */
  box-sizing: border-box;
  max-width: calc(100vw - 142px);
  min-width: 1580px;
`;

const ArrowDiv = styled.div`
  display: flex;
  justify-content: space-around;
`;

const AdvancedImageStyle = styled(AdvancedImage)`
  border-radius: 30px;
  width: 16px;
  height: 16px;
`;

const AccountSettingsBorder = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid ${theme.PRIMARY500};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserIconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const SelectRoleContainer = styled.div`
  margin-top: 20px;
  width: 236px;
`;

export { GridInfoTableV2 };
