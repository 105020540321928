import { gql, useLazyQuery, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { currentCallState, loggedInUser } from "../../../apollo/cache";
import { CallContext, LeadCardContext } from "../../../context/";
import { iconNewInfo } from "../../../images";
import { MixpanelActions } from "../../../services/mixpanel";
import { returnDispositionInfo } from "../../../utils/format";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { StyledTooltip } from "../../Dumb";
import { AppText, FlexDiv, Loading } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";

import { useFlags } from "launchdarkly-react-client-sdk";
import { isEqual } from "lodash";
import { info } from "../../../images/NewDesign";
import { PhoenixCheckbox, PhoenixIcon } from "../../UI/Phoenix";
import { LeadDispositionReason } from "./CallResultV2";
import { IDispositionOption } from "src/types";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { BAD_NUMBER_DISPOSITION_TYPES } from "src/utils/misc";

const FETCH_DISPOSITION_OPTIONS = gql`
  query fetchDispositionOptions($person_spoke_to: CALLRESULT, $lead_id: String!, $associated_action: String) {
    fetchDispositionOptions(
      person_spoke_to: $person_spoke_to
      lead_id: $lead_id
      associated_action: $associated_action
    ) {
      id
      label
      person_spoke_to
      phase
      next_phase
      next_phase_label
      resting_day
      description
      type
      disposition_reasons {
        id
        reason
        is_other
      }
    }
  }
`;

export const LOG_DISPOSITION = gql`
  mutation logDisposition(
    $disposition_id: String!
    $lead_id: String!
    $intent_id: String
    $call: Boolean
    $from_custom_call_queue: Boolean
    $call_sid: String
    $release_lead: Boolean
    $scheduled_event_ack: Boolean
    $try_alternate_contact: Boolean
    $disposition_reason: String
    $phone_number: String
  ) {
    logDisposition(
      disposition_id: $disposition_id
      lead_id: $lead_id
      intent_id: $intent_id
      call: $call
      from_custom_call_queue: $from_custom_call_queue
      call_sid: $call_sid
      release_lead: $release_lead
      scheduled_event_ack: $scheduled_event_ack
      try_alternate_contact: $try_alternate_contact
      disposition_reason: $disposition_reason
      phone_number: $phone_number
    ) {
      id
      channel
      lead_id
      related_disposition {
        id
        phase
        person_spoke_to
        type
        next_phase
        completed_text
        completed_image
        completed_tip
      }
    }
  }
`;

const FETCH_UPCOMING_EVENTS = gql`
  query fetchScheduledItems($lead_id: String!, $show_all_lead: Boolean) {
    fetchScheduledItems(lead_id: $lead_id, show_all_lead: $show_all_lead) {
      id
    }
  }
`;

const FETCH_LEAD = gql`
  query fetchLead($id: String) {
    fetchLead(id: $id) {
      id
      first_name
      last_name
      primary_phone_number
      primary_phone_number_type
      primary_phone_number_title
      local_primary_phone_number
      primary_phone_number_country_code
      lead_ownership_status
      associate_parent_id
      alternate_contacts {
        id
        title
        channel
        type
        value
      }
      next_intent_scheduled_or_unscheduled {
        id
      }
    }
  }
`;

interface CallDispositionProps {
  lead_id: string;
  intent_id?: string;
  transferDemoOptions: string;
  transferDemoSubOptions: string;
  setTransferDemoSubOptions: (transferDemoSubOptions: string) => void;
  /**
   * True if UI is this component is not called from the dialer
   */
  fromNonCallActivity?: boolean;
}

const CallDispositionsComponentV2: React.FC<CallDispositionProps> = ({
  lead_id,
  intent_id,
  transferDemoOptions,
  transferDemoSubOptions,
  setTransferDemoSubOptions,
  fromNonCallActivity,
}) => {
  const {
    callLeadId,
    getCallLeadId,
    associatedAction,
    callOptionStackPush,
    revertCallState,
    transferState,
    callSid,
    isNonCallActivity,
    requireNotes,
    performedNoteAction,
    isZoomCall,
    isGoogleMeetCall,
    handleLeadCardRefresh,
    phoneNumber,
  } = useContext(CallContext);

  const { selectedTabDialQueue } = useContext(LeadCardContext);

  const [nextDispositionInfo, setNextDispositionInfo] = useState("");
  const [releaseLeadFutureEventConfirmation, setReleaseLeadFutureEventConfirmation] = useState(false);

  const tryAlternateContact = useReactiveVar(currentCallState).alternate_contact;
  const releaseLead = useReactiveVar(currentCallState).release_lead;

  const [dispositionStep, setDispositionStep] = useState(0);
  const [selectedDisposition, setSelectedDisposition] = useState<IDispositionOption>({} as any);
  const [selectedDispositionReason, setSelectedDispositionReason] = useState("");

  const willMakeLeadUndialable = useMemo(
    () => ["Resting", "RetiredColdCall", "Retired", "Customer"].includes(selectedDisposition.next_phase || ""),
    [selectedDisposition],
  );

  const { logDispositionReason } = useFlags();

  const [getDispositionOptions, { data: dataDispositions, loading: loadingDispositions }] = useLazyQuery(
    FETCH_DISPOSITION_OPTIONS,
    {
      variables: {
        person_spoke_to: transferDemoOptions,
        lead_id: lead_id,
        associated_action: associatedAction,
      },
      fetchPolicy: "no-cache",
    },
  );

  const dispositionRequiresReason = useMemo(() => {
    const currentDisposition = dataDispositions?.fetchDispositionOptions?.find(
      (option: IDispositionOption) => option.id === transferDemoSubOptions,
    );
    return currentDisposition?.disposition_reasons.length > 0;
  }, [dataDispositions, transferDemoSubOptions]);

  const { data: dataLead, loading: loadingLead, error: errorLead, refetch: refetchLead } = useQuery(FETCH_LEAD, {
    fetchPolicy: "network-only",
    skip: !callLeadId,
    variables: {
      id: callLeadId,
    },
  });

  const { data: dataUpcomingEvents, loading: loadingUpcomingEvents, error: errorUpcomingEvents } = useQuery(
    FETCH_UPCOMING_EVENTS,
    {
      fetchPolicy: "network-only",
      skip: !callLeadId,
      variables: { lead_id: callLeadId },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const hasMultipleNumbers = useMemo(() => {
    const phoneNumbers = [
      ...(dataLead?.fetchLead?.primary_phone_number ? [dataLead?.fetchLead?.primary_phone_number] : []),
      ...(dataLead?.fetchLead?.alternate_contacts ?? []).map((item: any) => item.value),
    ];
    return phoneNumbers.length > 1;
  }, [dataLead]);

  useEffect(() => {
    if (!transferDemoOptions) {
      return;
    }
    getDispositionOptions({
      variables: {
        person_spoke_to: transferDemoOptions,
        lead_id,
        associated_action: associatedAction,
      },
    });
  }, [transferDemoOptions, lead_id]);

  const [logDisposition, { loading: loadingDisposition, error: errorDisposition }] = useMutation(LOG_DISPOSITION, {
    async onCompleted({ logDisposition }) {
      console.log("logDisposition: ", logDisposition);
      if (!logDisposition) {
        appToast("Error logging disposition. Please try again.");
        return;
      }
      MixpanelActions.track("Disposition Event", {
        phase: logDisposition?.related_disposition?.phase,
        next_phase: logDisposition?.related_disposition?.next_phase,
        type: logDisposition?.related_disposition?.type,
        person_spoke_to: logDisposition?.related_disposition?.person_spoke_to,
      });
      // This mutation implicitly logs a disposition
      // Make sure to update dispositionLogged if call is still going on
      // If the call has ended, revert back to dashboard

      const callLeadId = getCallLeadId();
      const isSameCallLead =
        callLeadId === logDisposition.lead_id || dataLead?.fetchLead?.associate_parent_id === logDisposition.lead_id;

      if (!currentCallState().onCall && !currentCallState().alternate_contact && isSameCallLead) {
        revertCallState();
      } else {
        currentCallState({
          ...currentCallState(),
          dispositionLogged: isSameCallLead ? true : currentCallState().dispositionLogged,
        });
        handleLeadCardRefresh && handleLeadCardRefresh();
      }
      appToast("Metrics are being updated.", {}, "");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `logDisposition GraphQL Error: ${message}`,
      });
      console.log("Error in logDisposition: ", message);
    },
    refetchQueries: ["fetchNextDial", "fetchCustomQueueLeads", "fetchFilteredLeadsInQueueV2"],
  });

  const loading = loadingDisposition || loadingLead || loadingUpcomingEvents || loadingDispositions;

  const disableReleaseLead =
    tryAlternateContact ||
    ["Owned", "Assigned"].includes(dataLead?.fetchLead?.lead_ownership_status) === false ||
    selectedDisposition?.next_phase?.toLowerCase().includes("resting") ||
    selectedDisposition?.next_phase?.toLowerCase().includes("retired") ||
    selectedDisposition?.next_phase?.toLowerCase().includes("customer");

  useEffect(() => {
    disableReleaseLead && currentCallState({ ...currentCallState(), release_lead: false });
  }, [disableReleaseLead]);

  useEffect(() => {
    willMakeLeadUndialable && currentCallState({ ...currentCallState(), alternate_contact: false });
  }, [willMakeLeadUndialable]);

  return (
    <>
      {requireNotes && !performedNoteAction && <PhoenixStyledTooltip id="call-disposition-tooltip" />}

      {dispositionStep === 0 && (
        <>
          {transferDemoOptions !== "" &&
            (loadingDispositions ? (
              <div style={{ margin: "20px auto" }}>
                <Loading />
              </div>
            ) : (
              <>
                <HeaderContainer>
                  <AppText fontSize={16}>Other {isNonCallActivity ? "" : "Call"} Results</AppText>
                  <AppText fontSize={14}>
                    What was the result of your {isNonCallActivity ? "activity" : "call"}?
                  </AppText>
                </HeaderContainer>
                <ButtonsDiv>
                  {dataDispositions && dataDispositions?.fetchDispositionOptions.length > 0 ? (
                    dataDispositions.fetchDispositionOptions?.map((item: IDispositionOption) => (
                      <FlexDiv direction="column" gap={8} width={"100%"}>
                        <DispositionOption
                          onClick={() => {
                            isEqual(selectedDisposition, item)
                              ? setSelectedDisposition({} as IDispositionOption)
                              : setSelectedDisposition(item);

                            setTransferDemoSubOptions(transferDemoSubOptions === item.id ? "" : item?.id);
                            if (
                              loggedInUser()?.organization?.enable_bad_number_feature &&
                              BAD_NUMBER_DISPOSITION_TYPES.includes(item?.type || "") &&
                              hasMultipleNumbers
                            ) {
                              setNextDispositionInfo(
                                `This will mark the number as bad, and if it's the primary, the first alternate will be set as primary`,
                              );
                            } else if (item.resting_day === -1) {
                              setNextDispositionInfo(
                                `This will put the lead into a retired phase, which will stop future assignment of this lead.`,
                              );
                            } else if (item.resting_day) {
                              const dispoLabel = item.label.toLowerCase();
                              const wordToMatch = "transfer";
                              const isTransferDispo = !!dispoLabel.match(wordToMatch);
                              if (isTransferDispo) {
                                setNextDispositionInfo(``);
                              } else {
                                setNextDispositionInfo(`${returnDispositionInfo(item)}`);
                              }
                            } else {
                              setNextDispositionInfo(``);
                            }

                            setSelectedDispositionReason("");
                          }}
                          item={item}
                          selected={selectedDisposition.id === item.id}
                        />
                        {logDispositionReason && selectedDisposition.id === item.id && dispositionRequiresReason && (
                          <LeadDispositionReason
                            disposition={item}
                            selectedDispositionReason={selectedDispositionReason}
                            setSelectedDispositionReason={setSelectedDispositionReason}
                          />
                        )}
                      </FlexDiv>
                    ))
                  ) : (
                    <AppText>No Options Found</AppText>
                  )}
                </ButtonsDiv>
              </>
            ))}

          {!!transferDemoSubOptions && !!nextDispositionInfo ? (
            <MaximumReachedTextDiv>
              <img src={iconNewInfo} alt="Info" height="28px" />
              <AppText style={{ marginLeft: "8px" }}>{nextDispositionInfo}</AppText>
            </MaximumReachedTextDiv>
          ) : (
            <div style={{ height: "72px" }} />
          )}

          {transferDemoOptions !== "" && (
            <ConfirmDiv>
              <StyledTooltip
                multiline
                place="top"
                effect="solid"
                css={{
                  fontFamily: theme.PRIMARY_FONT,
                }}
                backgroundColor={theme.PRIMARY800}
                id="lead-release-tooltip"
                getContent={(dataTip: string) => (
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "14px",
                      width: "200px",
                    }}
                  >
                    {dataTip}
                  </div>
                )}
              />
              {!isNonCallActivity && !isZoomCall && !isGoogleMeetCall && transferState !== "Success" && (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <PhoenixCheckbox
                    checked={!!currentCallState()?.alternate_contact}
                    onChange={() =>
                      currentCallState({
                        ...currentCallState(),
                        alternate_contact: !currentCallState().alternate_contact,
                      })
                    }
                    disabled={releaseLead || willMakeLeadUndialable}
                  />
                  <AppText>Would you like to try an alternate contact?</AppText>
                </div>
              )}
              <>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <PhoenixCheckbox
                    checked={!!currentCallState().release_lead}
                    onChange={() =>
                      currentCallState({
                        ...currentCallState(),
                        release_lead: !currentCallState().release_lead,
                      })
                    }
                    disabled={disableReleaseLead}
                  />
                  <AppText>Release Lead?</AppText>
                  <PhoenixIcon
                    style={{
                      marginLeft: "8px",
                    }}
                    data-for="lead-release-tooltip"
                    data-tip="This action will release the lead after the call result is logged. This option is only available if the lead is currently assigned or owned."
                    svg={info}
                    size={14}
                    variant="brand"
                  />
                </div>
                {currentCallState().release_lead && !!dataUpcomingEvents?.fetchScheduledItems?.length && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "16px",
                      gap: "8px",
                    }}
                  >
                    <PhoenixCheckbox
                      checked={!!releaseLeadFutureEventConfirmation}
                      onChange={() => {
                        setReleaseLeadFutureEventConfirmation(!releaseLeadFutureEventConfirmation);
                      }}
                    />
                    <AppText fontWeight={500} fontSize={12} variant="error">
                      I understand that this lead has future scheduled events and releasing them will delete those
                      events.
                    </AppText>
                  </div>
                )}
              </>
              <ConfirmCallOptionButton
                variant={"primary"}
                disabled={
                  loading ||
                  !transferDemoSubOptions ||
                  lead_id === "" ||
                  (currentCallState().release_lead &&
                    !!dataUpcomingEvents?.fetchScheduledItems?.length &&
                    !releaseLeadFutureEventConfirmation) ||
                  (logDispositionReason && dispositionRequiresReason && !selectedDispositionReason) ||
                  // cannot finish the flow if admin configured requireNotes & user did not perform note action
                  (requireNotes && !performedNoteAction)
                }
                onClick={async () => {
                  if (!currentCallState()?.dispositionLogged) {
                    await logDisposition({
                      variables: {
                        disposition_id: transferDemoSubOptions,
                        lead_id: lead_id,
                        intent_id,
                        call: !fromNonCallActivity,
                        from_custom_call_queue: selectedTabDialQueue === "custom",
                        call_sid: callSid,
                        release_lead: currentCallState().release_lead,
                        scheduled_event_ack: !!dataUpcomingEvents?.fetchScheduledItems?.length
                          ? releaseLeadFutureEventConfirmation
                          : true,
                        try_alternate_contact: !!currentCallState().alternate_contact,
                        disposition_reason:
                          logDispositionReason && dispositionRequiresReason ? selectedDispositionReason : undefined,
                        phone_number: phoneNumber,
                      },
                    });
                  }
                  // !!tryAlternateContact && setDispositionStep(1);
                  !!currentCallState().alternate_contact && callOptionStackPush("alternate-contact");
                }}
                fromNonCallActivity={fromNonCallActivity}
                data-tip={"Please add a note before proceeding."}
                data-for={requireNotes && !performedNoteAction ? "call-disposition-tooltip" : ""}
              >
                {!!currentCallState().alternate_contact ? (
                  "Next"
                ) : loadingDisposition ? (
                  <Loading color={theme.WHITE_COLOR} />
                ) : fromNonCallActivity ? (
                  "Confirm Result"
                ) : (
                  "Confirm Call Option"
                )}
              </ConfirmCallOptionButton>
            </ConfirmDiv>
          )}
        </>
      )}
    </>
  );
};

export const DispositionOption = ({
  item,
  selected,
  onClick,
}: {
  item: IDispositionOption;
  selected: boolean;
  onClick: () => void;
}) => {
  return (
    <DispositionDiv key={item?.id} disabled={!item?.id} selected={selected} onClick={onClick}>
      <AppText fontSize={12} fontWeight={600} color={selected ? theme.WHITE_COLOR : theme.PRIMARY600}>
        {item.label}
      </AppText>
      <AppText fontSize={10} fontWeight={400} color={selected ? theme.WHITE_COLOR : theme.NEUTRAL300}>
        {item.description}
      </AppText>
    </DispositionDiv>
  );
};

const DispositionDiv = styled.div<{ selected: boolean; disabled: boolean }>`
  background: ${(props) => (props.selected ? theme.PRIMARY600 : props.disabled ? theme.NEUTRAL300 : theme.WHITE_COLOR)};
  border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL500 : props.selected ? "none" : theme.NEUTRAL200)};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 88px;
  width: 100%;
  cursor: pointer;
`;
const MaximumReachedTextDiv = styled.div`
  background: ${theme.PRIMARY300};
  border: 1px solid ${theme.PRIMARY500};
  border-radius: 4px;
  padding: 16px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  height: 60px;
`;

interface TransferDemoThirdButtonProps {
  transferDemoSubOptions: string;
  option: string;
}

interface ButtonProps {
  fromNonCallActivity?: boolean;
}

const ConfirmCallOptionButton = styled(NewAppButton)<ButtonProps>`
  width: ${(props) => (props.fromNonCallActivity ? "100%" : "262px")};

  margin: 30px auto;
`;

const ButtonsDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ConfirmDiv = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  margin-top: 16px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 40px;
`;

export { CallDispositionsComponentV2 };
