import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { NoContactNoMessageComponent } from "./NoContactNoMessage";
import { CallContext, LeadCardContext } from "src/context";
import { currentCallState } from "src/apollo/cache";
import { gql, useMutation, useQuery } from "@apollo/client";
import { SHOW_NOCONTACT_BUTTON_SHORTCUT } from "src/apollo/query";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import * as Sentry from "@sentry/react";
import { LOG_DISPOSITION } from "./CallDispositionsV2";
import { appToast } from "src/utils/toast";
import { MixpanelActions } from "src/services/mixpanel";
import { AppText, FlexDiv, Loading } from "src/Components/UI";
import { NewAppButton } from "src/Components/UI/NewAppButton";
import { PhoenixAppButton, PhoenixIcon } from "src/Components/UI/Phoenix";
import { ABLY_OPTIONS } from "src/services/ably";
import { info } from "src/images/NewDesign";
import ReactTooltip from "react-tooltip";
import { theme } from "src/utils/theme";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";
interface SuggestedDispositionsProps {
  setTransferDemoSubOptions: Dispatch<SetStateAction<string>>;
  isNonCallActivity: boolean;
  callbackStatusAbly: string;
}

const FETCH_DISPOSITION_OPTIONS = gql`
  query fetchDispositionOptions($person_spoke_to: CALLRESULT, $lead_id: String!, $associated_action: String) {
    fetchDispositionOptions(
      person_spoke_to: $person_spoke_to
      lead_id: $lead_id
      associated_action: $associated_action
    ) {
      id
      label
      person_spoke_to
      phase
      next_phase
      next_phase_label
      resting_day
      description
      type
    }
  }
`;

const returnSuggestedDisposition = (status: string, timeOnCall: number, returnDefaultNoMessage: boolean) => {
  switch (status) {
    case "no-answer":
      return [{ person_spoke_to: "NoContact", type: "NoMessageLeft" }];
    case "canceled":
      return [{ person_spoke_to: "NoContact", type: "NoMessageLeft" }];
    case "fail":
      return [{ person_spoke_to: "NoContact", type: "NoMessageLeft" }];
    case "busy":
      // if (timeOnCall <= 35) {
      //   return [{ person_spoke_to: "NoContact", type: "NoMessageLeft" }];
      // } else if (timeOnCall > 35) {
      // }
      return [
        { person_spoke_to: "NoContact", type: "NoMessageLeft" },
        { person_spoke_to: "NoContact", type: "LeftVoicemail" },
      ];
    case "voicemail":
      return [
        { person_spoke_to: "NoContact", type: "NoMessageLeft" },
        { person_spoke_to: "NoContact", type: "LeftVoicemail" },
      ];
    default:
      return returnDefaultNoMessage ? [{ person_spoke_to: "NoContact", type: "NoMessageLeft" }] : [];
  }
};

const SuggestedDispositions: React.FC<SuggestedDispositionsProps> = ({
  setTransferDemoSubOptions,
  isNonCallActivity,
  callbackStatusAbly,
}) => {
  const { data: showNoContactButtonShortcut } = useQuery(SHOW_NOCONTACT_BUTTON_SHORTCUT);
  /**
   * Used to store the loading state of suggested dispositions component where disposition is logged
   */
  const [isLoadingDisposition, setIsLoadingDisposition] = useState<boolean>(false);

  const { selectedTabDialQueue } = useContext(LeadCardContext);
  const {
    associatedAction,
    timeOnCall,
    callSid,
    callLeadId,
    setPersonSpokeTo,
    intentId,
    callOptionStackPush,
  } = useContext(CallContext);

  const { data: dataDispositions, loading: loadingDispositions } = useQuery(FETCH_DISPOSITION_OPTIONS, {
    variables: {
      person_spoke_to: "NoContact",
      lead_id: callLeadId,
      associated_action: associatedAction,
    },
    fetchPolicy: "no-cache",
    skip: !callLeadId,
    onCompleted: () => {
      console.log("dataDispositions: ", dataDispositions);
    },
  });

  const [logDisposition, { loading: loadingLogDisposition, error: errorDisposition }] = useMutation(LOG_DISPOSITION, {
    async onCompleted({ logDisposition }) {
      console.log("logDisposition: ", logDisposition);
      if (!logDisposition) {
        appToast("Error logging disposition. Please try again.");
        return;
      }
      MixpanelActions.track("Disposition Event", {
        phase: logDisposition?.related_disposition?.phase,
        next_phase: logDisposition?.related_disposition?.next_phase,
        type: logDisposition?.related_disposition?.type,
        person_spoke_to: logDisposition?.related_disposition?.person_spoke_to,
      });
      // This mutation implicitly logs a disposition
      // Make sure to update dispositionLogged if call is still going on
      // If the call has ended, revert back to dashboard

      if (!currentCallState().onCall && !currentCallState().alternate_contact) {
        // revertCallState();
      } else {
        currentCallState({
          ...currentCallState(),
          dispositionLogged: callLeadId !== logDisposition.lead?.id ? currentCallState().dispositionLogged : true,
        });
      }
      appToast("Metrics are being updated.", {}, "");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `Suggested Dispositions logDisposition GraphQL Error: ${message}`,
      });
      console.log("Error in logDisposition: ", message);
      setIsLoadingDisposition(false);
    },
    refetchQueries: ["fetchNextDial", "fetchCustomQueueLeads", "fetchFilteredLeadsInQueueV2"],
  });

  const suggestedDispositions = useMemo(() => {
    const conditions = returnSuggestedDisposition(
      callbackStatusAbly,
      timeOnCall,
      showNoContactButtonShortcut?.showNoContactButtonShortcut,
    );
    const includedTypes = conditions ? conditions.map((item: any) => item.type) : [];

    console.log("conditions: ", conditions);
    if (dataDispositions?.fetchDispositionOptions?.length > 0) {
      const suggestedDispositions = dataDispositions?.fetchDispositionOptions
        .map((item: any) => item)
        .filter((item: any) => item?.person_spoke_to === "NoContact" && includedTypes.includes(item?.type));
      return suggestedDispositions;
    }
    return [];
  }, [callbackStatusAbly, timeOnCall]);

  if (isNonCallActivity || currentCallState().onCall || suggestedDispositions.length === 0) {
    return <></>;
  }
  return (
    <SuggestedDispositionsContainer>
      <PhoenixStyledTooltip
        multiline
        place="top"
        effect="solid"
        css={{
          maxWidth: 600,
          lineHeight: 1.4,
          textAlign: "center",
          fontFamily: theme.PRIMARY_FONT,
        }}
        id="suggested-dispositions-tip"
        backgroundColor={theme.PRIMARY800}
        getContent={(dataTip) => (
          <span
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "10px",
              lineHeight: "14px",
            }}
          >
            {dataTip}
          </span>
        )}
      />
      <FlexDiv gap={8}>
        <AppText fontSize={14} fontWeight={500}>
          {suggestedDispositions.length === 1
            ? `Suggested Disposition`
            : suggestedDispositions.length > 1
            ? `Suggested Dispositions`
            : ""}{" "}
        </AppText>
        <PhoenixIcon
          data-tip="Based on metadata from your call, we predict the likely disposition"
          data-for="suggested-dispositions-tip"
          variant="neutral"
          svg={info}
          size={14}
          style={{ marginTop: 3, cursor: "info" }}
        />
      </FlexDiv>

      {suggestedDispositions.length > 0 &&
        suggestedDispositions.map((item: any) => (
          <SuggestedDispositionButton
            variant={"brand"}
            buttonType={"primary"}
            disabled={
              isLoadingDisposition ||
              loadingDispositions ||
              loadingLogDisposition ||
              dataDispositions?.fetchDispositionOptions?.length === 0
            }
            onClick={async () => {
              console.log("dataDispositions: ", dataDispositions);
              setIsLoadingDisposition(true);
              setPersonSpokeTo("NoContact");

              setTransferDemoSubOptions(item?.id);

              if (!currentCallState()?.dispositionLogged && item?.id) {
                await logDisposition({
                  variables: {
                    disposition_id: item?.id,
                    lead_id: callLeadId,
                    intent_id: intentId,
                    call: true,
                    from_custom_call_queue: selectedTabDialQueue === "custom",
                    call_sid: callSid,
                    release_lead: currentCallState().release_lead,
                    scheduled_event_ack: false,
                    try_alternate_contact: true, // or !!currentCallState().alternate_contact,
                  },
                }).catch((e) => {
                  console.error(e);
                });
              } else {
                appToast("Disposition ID was not found");
              }
              callOptionStackPush("alternate-contact");
            }}
          >
            {isLoadingDisposition || loadingDispositions || loadingLogDisposition ? (
              <Loading />
            ) : (
              `No Contact, ${item?.label}`
            )}
          </SuggestedDispositionButton>
        ))}
    </SuggestedDispositionsContainer>
  );
};

const SuggestedDispositionButton = styled(PhoenixAppButton)`
  width: 286px;
  height: 40px;
`;

const SuggestedDispositionsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
`;
export default SuggestedDispositions;
