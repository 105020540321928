import * as Sentry from "@sentry/react";
import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { AppErrorText, AppText, FlexDiv, SkeletonBlock } from "../UI";

import { gql, useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import Spinner from "react-spinkit";
import { pause_circle_new, play_circle_new, record_outer_ring, stop_outer_ring } from "src/images/NewDesign";
import { axios } from "src/services/rest";
import { OptionItem } from "src/types";
import { FetchVoiceMailScriptsExpectedResponse } from "src/types/VoicemailTypes";
import { useModalContext } from "src/utils/hooks";
import { BACKEND_URL, TOKEN_PERSIST_KEY } from "src/utils/variables";
import WaveSurfer from "wavesurfer.js";
import { convertBlobToMediaFile } from "../../utils/media";
import { appToast } from "../../utils/toast";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import { formatTimeCallback, TimelineContainer } from "../Segments/CallReportSegments/WaveformV2";
import { PhoenixAppButton, PhoenixIcon, PhoenixMultiSelect } from "../UI/Phoenix";
import { PhoenixInput } from "../UI/Phoenix/PhoenixInput";
import SavedRecordings from "./VoicemailDrops/SavedRecordings";
import { createFormDataForPostRequest } from "src/utils/format";
// @ts-ignore
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline";
// import { restAPI } from "src/apollo";

const FETCH_VOICEMAIL_SCRIPTS = gql`
  query FetchVoicemailScripts($fetchVoiceMailScriptsId: String) {
    fetchVoiceMailScripts(id: $fetchVoiceMailScriptsId)
  }
`;

const formatSeconds = (seconds: number) => {
  // 00:00 format minutes:seconds
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const VoicemailDrops: React.FC = () => {
  const {
    data: voiceMailScriptsData,
    loading: voiceMailScriptsLoading,
    error: voiceMailScriptsError,
    refetch: voiceMailScriptsRefetch,
  } = useQuery<FetchVoiceMailScriptsExpectedResponse>(FETCH_VOICEMAIL_SCRIPTS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      console.log(data);
    },
    onError: (error) => {
      appToast("Unable to fetch voicemail scripts");
    },
    notifyOnNetworkStatusChange: true,
  });

  const FORMATED_VOICEMAIL_SCRIPTS = useMemo(
    () =>
      voiceMailScriptsData?.fetchVoiceMailScripts.map((script) => ({
        label: script.title,
        value: script.id,
      })) || [],
    [voiceMailScriptsData],
  );

  const { setShowScriptPreviewModal, setScriptPreviewModalDataId } = useModalContext();

  const [recordingFile, setRecordingFile] = useState<any>(null);

  const [selectedScriptId, setSelectedScriptId] = useState<string | undefined>(undefined);

  const [mediaDuration, setMediaDuration] = useState(0);

  const [recordingTitle, setRecordingTitle] = useState("");

  const [recordingDescription, setRecordingDescription] = useState("");

  const [loadingSaveRecording, setLoadingSaveRecording] = useState(false);

  const handleSaveRecording = async ({
    recordingFile,
    selectedScriptId,
    recordingDescription,
    recordingTitle,
  }: {
    recordingFile: File | null;
    selectedScriptId: string | undefined;
    recordingDescription?: string;
    recordingTitle: string;
  }) => {
    if (!recordingFile || !recordingTitle || !recordingDescription) {
      appToast("Please provide a recording, title, and description");
      return;
    }

    setLoadingSaveRecording(true);

    try {
      const formData = createFormDataForPostRequest([
        { key: "file", value: recordingFile },
        { key: "script_id", value: selectedScriptId },
        { key: "title", value: recordingTitle },
        { key: "description", value: recordingDescription },
        { key: "duration_seconds", value: Math.round(mediaDuration).toString() },
        { key: "size", value: recordingFile.size.toString() },
      ]);

      // for some reason restAPI.post is not working
      // const response = await restAPI.post(`/api/uploadVoicemailDrop`, formData, {
      //   headers: {
      //     Authorization: `${localStorage.getItem(TOKEN_PERSIST_KEY)}`,
      //   },
      // });
      const response = await axios.post(`${BACKEND_URL}/api/uploadVoicemailDrop`, formData, {
        headers: {
          Authorization: `${localStorage.getItem(TOKEN_PERSIST_KEY)}`,
        },
      });

      if (response.status === 200) {
        appToast("Recording saved successfully");
        voiceMailScriptsRefetch();
        setRecordingTitle("");
        setRecordingDescription("");
        setSelectedScriptId(undefined);
        setMediaDuration(0);
        setRecordingFile(null);
        setShowScriptPreviewModal(false);
      } else {
        appToast("Failed to save recording");
      }
    } catch (error: any) {
      console.error("Error saving recording:", error);
      appToast("Error saving recording");
    } finally {
      setLoadingSaveRecording(false);
    }
  };

  const globalLoading = voiceMailScriptsLoading;

  const globalError = voiceMailScriptsError;

  if (globalError) {
    return (
      <Main>
        <AppErrorText>Error fetching voicemail scripts</AppErrorText>
      </Main>
    );
  }

  if (globalLoading) {
    return (
      <Main>
        <SkeletonBlock width="100%" height="70vh" borderRadius={8} />
      </Main>
    );
  }

  const VoicemailDropsHeader = () => {
    return (
      <Header>
        <AppText fontSize={22} fontWeight={500} lineHeight={28}>
          Voicemail Drops
        </AppText>
      </Header>
    );
  };

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured at the Voicemail Drops page"}>
      <Main>
        <PhoenixStyledTooltip id="call-library-tooltip" />

        <VoicemailDropsHeader />

        <BodyWrap>
          <Body>
            <VoicemailGrid>
              <Left direction="column" gap={16}>
                <AppText fontSize={18} fontWeight={500} lineHeight={24} style={{ marginBottom: 8 }}>
                  Record Outbound Voicemail
                </AppText>
                <PhoenixInput
                  titleText="Name"
                  name="voicemail-name"
                  value={recordingTitle}
                  placeholder="Name this recording"
                  onChange={(e: any) => setRecordingTitle(e?.target?.value)}
                  displayNoContextText
                />
                <PhoenixInput
                  titleText="Description"
                  name="voicemail-description"
                  placeholder="What is it used for?"
                  value={recordingDescription}
                  displayNoContextText
                  onChange={(e: any) => setRecordingDescription(e?.target?.value)}
                />
                <FlexDiv gap={16} style={{ width: "100%" }} justify="space-between">
                  <PhoenixMultiSelect
                    width={211}
                    voicemail-selected-script-id=""
                    name="voicemail-selected-script-id"
                    placeholder="Select a Script"
                    options={FORMATED_VOICEMAIL_SCRIPTS}
                    value={FORMATED_VOICEMAIL_SCRIPTS.find((script) => script.value === selectedScriptId)}
                    isMulti={false}
                    onChange={(option: OptionItem) => {
                      if (option?.value) {
                        setShowScriptPreviewModal(true);
                        setScriptPreviewModalDataId(option?.value as string);
                      }

                      setSelectedScriptId(option?.value as string);
                    }}
                    marginBottom={false}
                  />
                  <PhoenixAppButton
                    variant="brand"
                    buttonType="secondary"
                    disabled={
                      !recordingFile ||
                      !recordingTitle ||
                      !recordingDescription ||
                      !mediaDuration ||
                      loadingSaveRecording
                    }
                    onClick={() =>
                      handleSaveRecording({
                        recordingFile,
                        selectedScriptId,
                        recordingDescription,
                        recordingTitle,
                      })
                    }
                  >
                    Save Recording
                  </PhoenixAppButton>
                </FlexDiv>
              </Left>
              <Right>
                <RightSection
                  recordingFile={recordingFile}
                  setRecordingFile={setRecordingFile}
                  setMediaDuration={setMediaDuration}
                  mediaDuration={mediaDuration}
                />
              </Right>
            </VoicemailGrid>
            <SavedRecordings />
          </Body>
        </BodyWrap>
      </Main>
    </Sentry.ErrorBoundary>
  );
};

const Left = styled(FlexDiv)`
  border-right: 1px solid ${theme.border.neutral.primary};
  padding: 24px 40px 24px 24px;
`;

const Right = styled(FlexDiv)``;

const VoicemailGrid = styled.div`
  display: grid;
  grid-template-columns: 420px 1fr;
  max-width: 1200px;
  background-color: ${theme.WHITE_COLOR};
  border-radius: 8px;
  border: 1px solid ${theme.border.neutral.primary};
`;

const Main = styled.div`
  margin: 24px;

  min-width: fit-content;
  height: max-content;
  border: 16px;
  border-radius: 8px;

  animation: ${theme.fadeIn} 0.2s ease;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 24px 40px;

  background-color: ${theme.WHITE_COLOR};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const BodyWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  min-width: fit-content;
  height: 100%;
  padding: 24px;

  overflow-y: auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${theme.surface.brand.secondary};
`;

const RightSectionRaw: React.FC<{
  recordingFile: File | null;
  setRecordingFile: (recordingFile: File | null) => void;
  setMediaDuration: (mediaDuration: number) => void;
  mediaDuration: number;
}> = ({ recordingFile, setRecordingFile, setMediaDuration, mediaDuration }) => {
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } = useReactMediaRecorder({
    video: false,
    audio: {
      channelCount: 1,
      sampleRate: 16000,
    },
  });

  useEffect(() => {
    const tickerContainerHeight = 22;

    if (waveformRef.current && recordingFile) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: theme.text.brand.primary,
        progressColor: theme.text.brand.secondary,
        // hide the default cursor and use our custom one that design made
        cursorColor: "transparent",
        barWidth: 2,
        barRadius: 1,
        responsive: true,
        height: 160 - tickerContainerHeight,
        normalize: true,
        partialRender: true,
        plugins: [
          TimelinePlugin.create({
            container: "#wave-timeline",
            formatTimeCallback: formatTimeCallback,
            height: 34,
            notchPercentHeight: 50,
            primaryColor: theme.text.brand.primary,
            secondaryColor: theme.text.brand.primary,
            primaryFontColor: theme.text.brand.primary,
            secondaryFontColor: theme.text.brand.primary,
            fontFamily: "Inter",
            fontSize: 10,
            clickable: true,
            clickableColor: theme.text.brand.primary,
          }),
        ],
      });
      // Create a custom cursor element (to match the design)
      const cursor = document.createElement("div");
      cursor.style.position = "absolute";
      cursor.style.top = `calc(50%)`;
      cursor.style.transform = "translateY(calc(-50% - 18px))";
      cursor.style.width = "1px";
      cursor.style.height = "72px";
      cursor.style.backgroundColor = theme.text.danger.primary;
      cursor.style.zIndex = "1";
      waveformRef.current.appendChild(cursor);

      // Create a circle element at the top of the cursor
      const circle = document.createElement("div");
      circle.style.position = "absolute";
      circle.style.top = "-4px";
      circle.style.left = "-4px";
      circle.style.width = "8px";
      circle.style.height = "8px";
      circle.style.borderRadius = "50%";
      circle.style.backgroundColor = theme.text.danger.primary;
      cursor.appendChild(circle);

      // Update the cursor position based on the current time
      const updateCursorPosition = () => {
        const progress = (wavesurfer.current?.getCurrentTime() || 0) / (wavesurfer.current?.getDuration() || 0);
        cursor.style.left = `${progress * 100}%`;
      };

      wavesurfer.current?.on("audioprocess", updateCursorPosition);

      wavesurfer.current?.on("seek", updateCursorPosition);

      wavesurfer.current?.loadBlob(recordingFile);

      wavesurfer.current?.on("ready", () => {
        setMediaDuration(wavesurfer.current?.getDuration() || 0);
      });

      wavesurfer.current?.on("play", () => {
        setIsPlaying(true);
      });

      wavesurfer.current.on("pause", () => {
        setIsPlaying(false);
      });
    }

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
        wavesurfer.current = null;
      }
      setIsPlaying(false);
    };
  }, [recordingFile]);

  const [listenerSet, setListenerSet] = useState(false);
  useEffect(() => {
    const timelineElement = document.querySelector("#wave-timeline");

    if (timelineElement && !listenerSet) {
      timelineElement.addEventListener("click", (event) => {
        const mouseEvent = event as MouseEvent;
        if (!wavesurfer.current) return;

        const leftPadding = 24;
        const rightPadding = 24;
        const rect = timelineElement.getBoundingClientRect();
        const clickX = mouseEvent.clientX - rect.left - leftPadding;
        const percentage = clickX / (rect.width - leftPadding - rightPadding);
        const time = percentage * wavesurfer.current.getDuration();
        const newTime = time / wavesurfer.current.getDuration() || 0;
        if (newTime < 0) return;
        wavesurfer.current.seekTo(newTime);
      });
      setListenerSet(true);
    }
  }, [document]);

  useEffect(() => {
    if (!mediaBlobUrl) return;
    const convert = async () => {
      const file = await convertBlobToMediaFile(mediaBlobUrl as string);
      setRecordingFile(file);
    };
    convert();
  }, [mediaBlobUrl]);

  const [currentRecordingDuration, setCurrentRecordingDuration] = useState(0);

  const increaseRecordingDuration = () => {
    setCurrentRecordingDuration((prev) => prev + 1);
  };

  useEffect(() => {
    if (isRecording) {
      const interval = setInterval(increaseRecordingDuration, 1000);
      return () => clearInterval(interval);
    } else {
      setCurrentRecordingDuration(0);
    }
  }, [isRecording]);

  const recordingMade = !!mediaBlobUrl && !!recordingFile;

  const handlePlay = () => {
    wavesurfer.current?.play();
  };

  const handlePause = () => {
    wavesurfer.current?.pause();
  };

  const handleClearRecording = () => {
    setRecordingFile(null);
    clearBlobUrl();
  };

  const handleRecord = () => {
    setIsRecording(true);
    startRecording();
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    stopRecording();
  };

  useEffect(() => {
    handleStopRecording();
  }, []);

  const handleStopAudioPlayback = () => {
    wavesurfer.current?.pause();
    wavesurfer.current?.seekTo(0);
  };

  return (
    <FlexDiv direction="column" width="100%" height="100%">
      {!recordingMade ? (
        <WaveformContainer recordingMade={false}>
          <RecordingVisualizer isRecording={isRecording} />
        </WaveformContainer>
      ) : (
        <>
          <WaveformContainer recordingMade>
            <div
              style={{
                width: "100%",
              }}
              ref={waveformRef}
            />
            <TimelineContainer id="wave-timeline" padding="0px 8px" />
          </WaveformContainer>
        </>
      )}
      <RecordingControls
        isRecording={isRecording}
        handleRecord={handleRecord}
        handleStopRecording={handleStopRecording}
        handleStopAudioPlayback={handleStopAudioPlayback}
        clearRecording={handleClearRecording}
        recordingMade={recordingMade}
        handlePlay={handlePlay}
        handlePause={handlePause}
        currentRecordingDuration={currentRecordingDuration}
        isPlaying={isPlaying}
      />
    </FlexDiv>
  );
};

const RightSection = memo(RightSectionRaw);

const RecordingControls = ({
  isRecording,
  handleRecord,
  handleStopAudioPlayback,
  handleStopRecording,
  clearRecording,
  recordingMade,

  handlePlay,
  handlePause,
  currentRecordingDuration,
  isPlaying,
}: {
  isRecording: boolean;
  handleRecord: () => void;
  handleStopAudioPlayback: () => void;
  handleStopRecording: () => void;
  clearRecording: () => void;
  recordingMade: boolean;

  handlePlay: () => void;
  handlePause: () => void;
  currentRecordingDuration: number;
  isPlaying: boolean;
}) => {
  return (
    <FlexDiv
      justify="space-between"
      style={{ padding: 24, borderTop: `1px solid ${theme.border.neutral.primary}` }}
      align="center"
    >
      {recordingMade ? (
        <RecordModuleDiv>
          <IconImg
            src={isPlaying ? pause_circle_new : play_circle_new}
            onClick={isPlaying ? handlePause : handlePlay}
          />
          <PhoenixIcon
            svg={stop_outer_ring}
            onClick={handleStopAudioPlayback}
            variant="neutral"
            size={40}
            pointer={isPlaying}
          />
        </RecordModuleDiv>
      ) : (
        <RecordModuleDiv>
          {isRecording ? (
            <PhoenixIcon svg={stop_outer_ring} onClick={handleStopRecording} variant="neutral" size={40} pointer />
          ) : (
            <PhoenixIcon svg={record_outer_ring} onClick={handleRecord} variant="neutral" size={40} pointer />
          )}

          <FlexDiv direction="column" style={{ marginRight: 8 }}>
            <AppText fontSize={14} fontWeight={500} lineHeight={20}>
              {isRecording ? "Stop Recording" : "Start Recording"}
            </AppText>
            <AppText fontSize={10} fontWeight={400} lineHeight={16}>
              {formatSeconds(currentRecordingDuration)}
            </AppText>
          </FlexDiv>
        </RecordModuleDiv>
      )}
      <PhoenixAppButton
        variant="danger-outline"
        buttonType="secondary"
        disabled={!recordingMade}
        onClick={clearRecording}
      >
        Clear
      </PhoenixAppButton>
    </FlexDiv>
  );
};

const RecordingVisualizer = memo(
  ({ isRecording }: { isRecording: boolean }) => {
    if (isRecording) {
      return (
        <FlexDiv justify="center" align="center" style={{ height: "100%" }}>
          <Spinner name="line-scale-party" color={theme.text.brand.primary} />
        </FlexDiv>
      );
    }

    return null;
  },
  (prevProps, nextProps) => prevProps.isRecording === nextProps.isRecording,
);

const IconImg = styled.img`
  width: 40px;
  height: 40px;
  padding: 0;
`;

const WaveformContainer = styled.div<{ recordingMade: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  ${({ recordingMade }) => recordingMade && `background-color: ${theme.fill.brand.secondary};`}
`;

const RecordModuleDiv = styled.div`
  display: flex;
  gap: 16px;
  padding: 8px;
  background-color: ${theme.NEUTRAL100};
  border: 1px solid ${theme.NEUTRAL200};
  align-items: center;
  justify-content: center;
  border-radius: 360px;
  height: 64px;
`;

export { VoicemailDrops };
