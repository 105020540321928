import styled from "styled-components";
import React, { useState, useMemo } from "react";
import { AppText, FlexDiv, TemplateEditor, Tokens } from "../../UI";
import { theme } from "../../../utils/theme";
import { gql, useMutation, useQuery } from "@apollo/client";
import { AllTokens } from "src/types";
import { PhoenixAppButton } from "src/Components/UI/Phoenix";
import { appToast, errorToast } from "src/utils/toast";
import { handleLineHeights } from "src/Components/modal";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

const FETCH_ALL_TOKENS = gql`
  query fetchTokensForTemplate {
    fetchTokensForTemplate
  }
`;
const FETCH_CUSTOM_FIELDS = gql`
  query fetchCustomFields {
    fetchCustomFields {
      id
      key
    }
  }
`;
const CREATE_UPDATE_EMAIL_TEMPLATE = gql`
  mutation createOrUpdateTemplate($templateInput: TemplateInput!) {
    createOrUpdateTemplate(TemplateInput: $templateInput) {
      id
      content
    }
  }
`;
const FETCH_SIGNATURE_TEMPLATE = gql`
  query getEmailSignatureTemplate {
    getEmailSignatureTemplate {
      id
      content
    }
  }
`;

const StepEmailSignature: React.FC<DisappearingDivProps> = () => {
  const [signatureContent, setSignatureContent] = useState("");

  const { data: dataTokens, loading: loadingTokens } = useQuery(FETCH_ALL_TOKENS, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });
  const { data: dataCustomFields, loading: loadingCustomFields } = useQuery(FETCH_CUSTOM_FIELDS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });
  const { data: dataSignatureTemplate, loading: loadingSignatureTemplate } = useQuery(FETCH_SIGNATURE_TEMPLATE, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const tokens = useMemo(() => {
    const customFieldTokens = dataCustomFields?.fetchCustomFields?.map((field: { id: string; key: string }) => ({
      token: `contact_customField.${field.key}`,
      token_formatted: `[contact_customField.${field.key}]`,
      fallback: "",
    }));
    return { ...dataTokens?.fetchTokensForTemplate, "Custom Field": customFieldTokens };
  }, [dataTokens, dataCustomFields]);

  const [createOrEditEmailTemplate, { loading: createOrEditEmailTemplateLoading }] = useMutation(
    CREATE_UPDATE_EMAIL_TEMPLATE,
    {
      async onCompleted({ createOrUpdateTemplate }) {
        console.log("createOrUpdateTemplate: ", createOrUpdateTemplate);
        if (!createOrUpdateTemplate) {
          errorToast("Email Signature error. Something went wrong.");
          return;
        }

        appToast("Updated email template!");
      },
      onError({ message }) {
        console.log("Error in createOrUpdateTemplate: ", message);
      },
    },
  );

  return (
    <Main>
      <TopAlignDiv>
        <AppText fontSize={22} fontWeight={500} lineHeight={28} noWrap>
          Email Signature
        </AppText>
      </TopAlignDiv>

      <Body>
        <FlexDiv direction="column" gap={40}>
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Configure the text and styling of your organization's email signatures.
          </AppText>
          <FlexDiv gap={40}>
            <FlexDiv direction="column" gap={16}>
              <TemplateEditor
                width={520}
                initialContent={dataSignatureTemplate?.getEmailSignatureTemplate?.content}
                deliver
                noMargin
                emojiPickerRight={20}
                emojiPickerTop={-160}
                displayNoContextText
                onContentChange={(content) => setSignatureContent(content)}
              />
              <PhoenixAppButton
                variant="brand"
                buttonType="secondary"
                onClick={async () => {
                  const submitObj = {
                    type: "EMAIL",
                    content: signatureContent,
                    template_name: "Email Signature",
                    visibility: {
                      user_ids: [],
                      team_ids: [],
                      roles: [],
                    },
                    template_id: dataSignatureTemplate?.getEmailSignatureTemplate?.id,
                    is_signature: true,
                  };
                  submitObj.content = handleLineHeights(submitObj.content).innerHTML;
                  await createOrEditEmailTemplate({
                    variables: {
                      templateInput: submitObj,
                    },
                  });
                }}
                disabled={createOrEditEmailTemplateLoading || loadingSignatureTemplate}
              >
                Save Changes
              </PhoenixAppButton>
            </FlexDiv>
            <TokenContainer tokens={tokens} loading={loadingTokens || loadingCustomFields} />
          </FlexDiv>
        </FlexDiv>
      </Body>
    </Main>
  );
};

interface ITokenContainer {
  tokens: AllTokens;
  loading: boolean;
}

const TokenContainer: React.FC<ITokenContainer> = ({ tokens, loading }) => {
  return (
    <TokenContainerWrap>
      <TokenWrap>
        <Tokens loading={loading} tokens={tokens} compressed ignoreOverflow />
      </TokenWrap>
    </TokenContainerWrap>
  );
};

const TokenContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 400px;

  padding: 16px;
  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
`;

const TokenWrap = styled.div`
  max-height: 270px;
  overflow-y: auto;
`;

const Main = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  border-radius: 8px;

  height: 100%;
  overflow-y: auto;
`;

const TopAlignDiv = styled.div`
  display: flex;
  justify-items: center;

  height: 108px;
  padding: 24px 40px;

  border-bottom: 1px solid ${theme.border.neutral.primary};
`;

const Body = styled.div`
  padding: 40px;
  width: 100%;
`;

export { StepEmailSignature };
