import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ModalContext } from "../../context";
import { chevron_down, chevron_up } from "../../images/NewDesign";
import { PhoenixExpandButton, PhoenixIcon } from "../UI/Phoenix";
import { createSystemConfigNav } from "../../utils/misc";
import { SystemConfigNavShape } from "../../types";
import { theme } from "../../utils/theme";
import { AppText } from "../UI";

const SystemConfigNav: React.FC = () => {
  const history = useHistory();

  const { currentTab, isSystemConfigNavExpanded, setIsSystemConfigNavExpanded } = useContext(ModalContext);

  const {
    customObject,

    registerDomain,
    callRecordingStorage,
    opsiqForm,
    callNoteTemplates,
    emailSignature,
    leadCardConfig,
    voicemailDrops,
    sellfireLabels,
    inboundCallRouting,
  } = useFlags();

  const replaceHistory = (path: string) => {
    history.push(path);
  };

  console.log("customObject", customObject);

  const [openedDrawers, setOpenedDrawers] = useState<string[]>([]);

  useEffect(() => {
    localStorage.setItem("systemConfigNavExpanded", JSON.stringify(isSystemConfigNavExpanded));
  }, [isSystemConfigNavExpanded]);

  const systemConfigNav = useMemo(() => {
    return createSystemConfigNav({
      showFieldManager: !customObject,
      showCustomObject: !!customObject,
      showLeadRanking: true,
      showMakeSaleConfig: true,
      showRegisterDomain: !!registerDomain,
      showCallRecordingStorage: !!callRecordingStorage,
      showSMSSettings: true,
      showOpsiqForms: !!opsiqForm,
      showCallNoteTemplates: !!callNoteTemplates,
      emailSignature: !!emailSignature,
      leadCardConfig: !!leadCardConfig,
      showScriptManager: !!voicemailDrops,
      showOrganizationSettings: !!voicemailDrops,
      sellfireLabels: !!sellfireLabels,
      inboundCallRouting: !!inboundCallRouting,
    });
  }, [
    registerDomain,
    callRecordingStorage,
    customObject,
    opsiqForm,
    emailSignature,
    leadCardConfig,
    voicemailDrops,
    sellfireLabels,
    inboundCallRouting,
  ]);

  return (
    <>
      <PhoenixExpandButton
        expandedState={isSystemConfigNavExpanded}
        moveDirection="right"
        moveDistance={224}
        top={24}
        left={72}
        onClick={() => setIsSystemConfigNavExpanded(!isSystemConfigNavExpanded)}
      />
      <TabContainer expanded={isSystemConfigNavExpanded}>
        {systemConfigNav?.map((drawer: SystemConfigNavShape) => {
          const drawerOpened = openedDrawers.includes(drawer.drawer_name) && !!drawer.items.length;

          // boolean for if we are currently navigated to an item in the drawer
          const activeDrawer =
            !!currentTab &&
            (drawer.items.some((item: { title: string; path: string }) => item.path.includes(currentTab)) ||
              drawer.path?.includes(currentTab));

          return (
            <>
              <Drawer
                onClick={() => {
                  setOpenedDrawers((prevState) => {
                    if (prevState.includes(drawer.drawer_name)) {
                      return prevState.filter((drawerName) => drawerName !== drawer.drawer_name);
                    } else {
                      return [...prevState, drawer.drawer_name];
                    }
                  });

                  if (!!drawer?.path) {
                    replaceHistory(drawer.path);
                  }
                }}
              >
                <DrawerText
                  fontSize={12}
                  fontWeight={!drawerOpened && activeDrawer ? 500 : 400}
                  lineHeight={18}
                  color={!drawerOpened && activeDrawer ? theme.PRIMARY500 : theme.BLACK_COLOR}
                >
                  {drawer.drawer_name}
                </DrawerText>
                {!!drawer.items.length && (
                  <PhoenixIcon
                    svg={!!drawerOpened ? chevron_up : chevron_down}
                    variant="brand"
                    size={24}
                    pointer
                    alt="chevron"
                  />
                )}
              </Drawer>
              <DrawerContentContainer expanded={!!drawerOpened} content_length={drawer.items.length}>
                {drawer.items?.map((item) => (
                  <DrawerItem
                    onClick={() => replaceHistory(item.path)}
                    active={!!currentTab && item.path.includes(currentTab)}
                  >
                    <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                      {item.title}
                    </AppText>
                  </DrawerItem>
                ))}
              </DrawerContentContainer>
            </>
          );
        })}
      </TabContainer>
    </>
  );
};

const DrawerText = styled(AppText)`
  transition: color 0.2s ease-in-out;
`;

const Drawer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 224px;
  min-height: 64px;
  padding: 0px 24px;

  background-color: ${theme.WHITE_COLOR};
  border-bottom: 1px solid ${theme.NEUTRAL200};

  cursor: pointer;

  :hover {
    & ${DrawerText} {
      color: ${theme.PRIMARY500};
    }
  }
`;

interface DrawerContentContainer {
  expanded: boolean;
  content_length: number;
}

const DrawerContentContainer = styled.div<DrawerContentContainer>`
  overflow: hidden;

  max-height: ${(props) => (props.expanded ? `calc(48px * ${props.content_length})` : "0px")};
  min-height: ${(props) => (props.expanded ? `calc(48px * ${props.content_length})` : "0px")};

  transition: max-height 0.3s ease, min-height 0.3s ease;
`;

interface DrawerItemProps {
  active: boolean;
}

const DrawerItem = styled.div<DrawerItemProps>`
  display: flex;
  align-items: center;

  width: 100%;
  min-height: 48px;
  padding: 0px 24px;
  background: ${theme.NEUTRAL100};
  border-right: ${(props) => (props.active ? `solid 4px ${theme.PRIMARY500}` : "solid 0px")};
  border-bottom: 1px solid ${theme.NEUTRAL200};

  cursor: pointer;

  & p {
    color: ${(props) => (props.active ? theme.PRIMARY500 : theme.BLACK_COLOR)};
    transition: color 0.15s ease-in-out;
  }
  :hover {
    & p {
      color: ${theme.PRIMARY500};
    }
  }

  transition: border-right 0.1s ease-in-out;
`;

interface TabContainerProps {
  expanded: boolean;
}

const TabContainer = styled.div<TabContainerProps>`
  position: absolute;
  left: ${(props) => (props.expanded ? "72px" : "-158px")};
  z-index: 99;

  display: flex;
  flex-direction: column;

  height: 100vh;
  min-width: 224px;

  border-right: solid 1px ${theme.NEUTRAL300};
  background-color: ${theme.WHITE_COLOR};

  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  transition: left 0.2s ease-in-out;
`;

export { SystemConfigNav };
