import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { default as React, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import ConnectionBanner from "src/Components/Dumb/ConnectionBanner";
import { CallMonitorV2Component } from "src/Components/Smart/CallMonitorV2";
import { CallMonitorV3Component } from "src/Components/Smart/CallMonitorV3/CallMonitorV3";
import { SequenceNav } from "src/Components/UI/Sequences/SequenceNav";
import styled from "styled-components";
import { loggedInUser } from "../apollo/cache";
import { IS_LOGGED_IN, SHOW_AUTO_DIALER } from "../apollo/query";
import { MakeSomeHeightComponent } from "../Components/Dumb";
import { Onboarding } from "../Components/Pages";
import { InfoHeader, TabNav } from "../Components/Segments";
import { SystemConfigNav } from "../Components/Segments/SystemConfigNav";
import { AutoDialTimerComponent } from "../Components/Smart/";
import { FlexDiv, SkeletonBlock } from "../Components/UI";
import { AutoDialContextProvider, LiveUserStatusProvider, ModalContext, NotificationsProvider } from "../context";
import { CallComponent } from "../twilio/CallComponent";
import { DevicePermission } from "../twilio/DevicePermission";
import { getLocalStorage } from "../utils/misc";
import { theme } from "../utils/theme";

const UPDATE_USER_STATUS = gql`
  mutation updateUserStatus($status: STATUS!) {
    updateUserStatus(status: $status) {
      id
      email
      status
    }
  }
`;

interface PrivateRouteProps {
  component: any;
  path: string;
  exact?: boolean;
}

type Props = PrivateRouteProps;

export const PrivateRouteComponent = ({
  component: Component,
  path,
  ...rest // sets the rest of the parameters to the rest variable
}: Props) => {
  const { condensedCallMonitor } = useFlags();

  const { data, loading } = useQuery(IS_LOGGED_IN);

  const { data: cacheOrgData } = useQuery(SHOW_AUTO_DIALER);

  const showAutoDialer = cacheOrgData?.showAutoDialer;

  const [updateUserStatus, { loading: loadStatus, error: errorStatus }] = useMutation(UPDATE_USER_STATUS, {
    onCompleted({ updateUserStatus }) {
      if (!updateUserStatus) {
        return;
      }
    },
    onError({ message }) {
      console.log(message);
    },
  });

  const eventListenerRef = (e: BeforeUnloadEvent) => {
    const savedDevices = getLocalStorage("saved_devices", []);
    !savedDevices.length && updateUserStatus({ variables: { status: "OFFLINE" } });

    const isCallLiveInTab = JSON.parse(sessionStorage.getItem("userIsOnCall") || "false");
    if (!!isCallLiveInTab) {
      localStorage.setItem("userIsOnCallLocal", JSON.stringify(false));
      sessionStorage.setItem("userIsOnCall", JSON.stringify(false));
      window.dispatchEvent(new Event("storage"));
    }
  };

  React.useEffect(() => {
    window.removeEventListener("unload", eventListenerRef);
    window.addEventListener("unload", eventListenerRef);
  }, []);

  if (loading) {
    return (
      <FullViewportLoadingDiv>
        <SkeletonBlock height={"100%"} width={"100%"} borderRadius={8} />
      </FullViewportLoadingDiv>
    );
  }
  const is_authenticated = data.isLoggedIn;

  const CallMonitor = !!condensedCallMonitor ? CallMonitorV3Component : CallMonitorV2Component;

  return (
    <div id="root">
      <NotificationsProvider>
        <CallComponent />
        <DevicePermission />

        <AutoDialContextProvider>
          <BackgroundFlexDiv>
            {path !== "/onboarding/" && <InfoHeader />}
            {path.includes("system-config") ? (
              <SystemConfigNav />
            ) : path.includes("sequences") ? (
              <SequenceNav />
            ) : (
              <TabNav />
            )}

            <MainContent path={path}>
              <MakeSomeHeightComponent />
              {(loggedInUser().role === "SDR" || loggedInUser().role === "AE") && showAutoDialer && (
                <AutoDialTimerComponent />
              )}
              <ConnectionBanner />
              <FlexDiv direction="column" height="100%" style={{ overflow: "auto" }}>
                <Route
                  {...rest}
                  component={(props: any) => {
                    if (is_authenticated) {
                      const is_onboarded = loggedInUser()?.organization?.onboardComplete;
                      console.log("in_onboarded: ", is_onboarded);
                      if (!is_onboarded) {
                        return <Onboarding {...props} />;
                      }
                      return <Component {...props} />;
                    } else {
                      return <Redirect to="/login/" />;
                    }
                  }}
                />
                <LiveUserStatusProvider>
                  {(loggedInUser().role === "ADMIN" || loggedInUser().role === "SM") && <CallMonitor />}
                </LiveUserStatusProvider>
              </FlexDiv>
            </MainContent>
          </BackgroundFlexDiv>
        </AutoDialContextProvider>
      </NotificationsProvider>
    </div>
  );
};

const FullViewportLoadingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 16px;
`;

const MainContent: React.FC<{ path: string }> = ({ children, path }) => {
  const { isSystemConfigNavExpanded, isSequenceNavExpanded } = useContext(ModalContext);
  return (
    <MainContentDiv
      expanded={
        (!!isSystemConfigNavExpanded && !!path.includes("system-config")) ||
        (!!isSequenceNavExpanded && !!path.includes("sequences")) ||
        !!path.includes("system-view")
      }
    >
      {children}
    </MainContentDiv>
  );
};

const MainContentDiv = styled.div<{ expanded: boolean }>`
  width: 100%;

  overflow-y: auto;
  margin-left: ${(props) => (props.expanded ? "224px" : "0px")};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: margin-left 0.25s ease-in-out;
`;

const BackgroundFlexDiv = styled.div`
  display: flex;
  background-color: ${theme.surface.neutral.tertiary};
  height: 100vh;
`;

const PrivateRoute = PrivateRouteComponent;

export default PrivateRoute;
