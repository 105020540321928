import React, { useState, useContext, useEffect, useMemo } from "react";
import styled, { keyframes } from "styled-components";
import * as Sentry from "@sentry/react";
import { useMutation, useQuery, useLazyQuery, gql } from "@apollo/client";
import * as Yup from "yup";
import { theme } from "../../utils/theme";
import { ModalContext } from "../../context";
import { chevron_down, chevron_up, refresh, xIcon } from "../../images/NewDesign";
import { AppText, DarkDiv, EmojiPicker, Loading, Tokens } from "../UI";
import { FlexDiv } from "../UI/FlexDiv";
import { PhoenixAppButton, PhoenixCheckbox, PhoenixIcon, PhoenixInput } from "../UI/Phoenix";
import { PhoenixInputField } from "../Field/Phoenix/PhoenixInputField";
import { TemplateEditorField } from "../Field";
import { Formik, FormikProps } from "formik";
import { appToast } from "../../utils/toast";
import Switch from "react-switch";
import { removeColorFormattingFromHtml } from "../../utils/format";
import { TemplateEditor } from "../UI";
import { handleLineHeights } from "./EditEmailModalV2";
import { MixpanelActions } from "../../services/mixpanel";
import { countTemplateTokens } from "../../utils/misc";
import { AllTokens, TokenObject } from "../../types";
import { useFlags } from "launchdarkly-react-client-sdk";

const FETCH_TEAMS = gql`
  query fetchTeams {
    fetchTeams {
      id
      name
    }
  }
`;

const FETCH_REPS = gql`
  query fetchReps {
    fetchReps {
      id
      full_name
    }
  }
`;

const FETCH_TEMPLATE = gql`
  query fetchTemplate($templateFilter: TemplateFilter!) {
    fetchTemplates(TemplateFilter: $templateFilter) {
      id
      name
      subject
      content
      visible_roles
      visible_teams
      visible_users
      use_signature
    }
  }
`;

const CREATE_UPDATE_EMAIL_TEMPLATE = gql`
  mutation createOrUpdateTemplate($templateInput: TemplateInput!) {
    createOrUpdateTemplate(TemplateInput: $templateInput) {
      id
      content
    }
  }
`;

const FETCH_ALL_TOKENS = gql`
  query fetchTokensForTemplate {
    fetchTokensForTemplate
  }
`;

const FETCH_CUSTOM_FIELDS = gql`
  query fetchCustomFields {
    fetchCustomFields {
      id
      key
    }
  }
`;

interface EmailFormikProps {
  type: "SMS" | "EMAIL";
  content: string;
  subject: string;
  template_name: string;
  template_id?: string;
  visibility: {
    user_ids?: [string?];
    team_ids?: [string?];
    roles?: [string?];
  };
  use_signature: boolean;
}

export const EmailTemplateModal: React.FC = () => {
  const { setEmailTemplateModal, editEmailTemplateID, setEditEmailTemplateID } = useContext(ModalContext);
  const { emailSignature, voicemailDrops } = useFlags();

  const [step, setStep] = useState(1);
  const [visibilityControls, setVisibilityControls] = useState(false);
  const [pillList, setPillList] = useState<any[]>([]);

  const [rolesVisible, setRolesVisible] = useState(true);
  const [teamsVisible, setTeamsVisible] = useState(true);
  const [repsVisible, setRepsVisible] = useState(true);

  const [teamSearch, setTeamSearch] = useState("");
  const [repSearch, setRepSearch] = useState("");

  const handleEmojiInsert = (curValue: string, emoji: any) => {
    const ele = document.getElementById("emailSubjectFieldInput") as HTMLInputElement;
    // grab input caret position
    const { selectionStart, selectionEnd } = ele as any;
    // insert emoji at caret position
    const newValue = curValue.slice(0, selectionStart) + emoji + curValue.slice(selectionEnd);
    // update input selection range. Moves caret position to the right of most recent emoji.
    setTimeout(() => ele?.setSelectionRange(selectionStart + emoji.length, selectionEnd + emoji.length), 10);

    return newValue;
  };

  const { data: dataTeams, loading: loadingTeams, error: errorTeams } = useQuery(FETCH_TEAMS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataReps, loading: loadingReps, error: errorReps } = useQuery(FETCH_REPS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  interface ExpectedTokenResponse {
    fetchTokensForTemplate?: AllTokens;
  }

  interface ExpectedCustomFieldsResponse {
    fetchCustomFields: {
      id: string;
      key: string;
    }[];
  }

  interface CustomFieldTokenObject {
    token: string;
    token_formatted: string;
    fallback: string;
  }

  const { data: rawDataTokens, loading: loadingTokens } = useQuery<ExpectedTokenResponse>(FETCH_ALL_TOKENS, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataCustomFields, loading: loadingCustomFields } = useQuery<ExpectedCustomFieldsResponse>(
    FETCH_CUSTOM_FIELDS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const tokens = useMemo(() => {
    const customFieldTokens: CustomFieldTokenObject[] =
      dataCustomFields?.fetchCustomFields?.map((field: { id: string; key: string }) => ({
        token: `contact_customField.${field.key}`,
        token_formatted: `[contact_customField.${field.key}]`,
        fallback: "",
      })) || [];

    const filteredTokens = { ...rawDataTokens?.fetchTokensForTemplate };

    if (!voicemailDrops && filteredTokens) {
      Object.keys(filteredTokens).forEach((key) => {
        if (key.toLowerCase().includes("organization")) {
          delete filteredTokens[key];
        }
      });
    }

    const dataTokens: TokenObject = { ...filteredTokens, "Custom Field": customFieldTokens };

    return dataTokens;
  }, [rawDataTokens, dataCustomFields, voicemailDrops]);

  const [fetchTemplate, { data: dataTemplate, loading: loadingTemplate, error: errorTemplate }] = useLazyQuery(
    FETCH_TEMPLATE,
    {
      fetchPolicy: "network-only",
      variables: {
        templateFilter: {
          template_id: editEmailTemplateID,
          skip: 0,
          take: 1,
        },
      },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
      onCompleted({ fetchTemplates }) {
        // if the template has visibility controls, have them turned on in the modal
        if (
          !!fetchTemplates?.[0]?.visible_roles?.length ||
          !!fetchTemplates?.[0]?.visible_teams?.length ||
          !!fetchTemplates?.[0]?.visible_users?.length
        ) {
          setVisibilityControls(true);
        }
      },
    },
  );

  const [
    createOrEditEmailTemplate,
    { loading: createOrEditEmailTemplateLoading, error: createOrEditEmailTemplateError },
  ] = useMutation(CREATE_UPDATE_EMAIL_TEMPLATE, {
    async onCompleted({ createOrUpdateTemplate }) {
      console.log("createOrUpdateTemplate: ", createOrUpdateTemplate);
      if (!createOrUpdateTemplate) {
        appToast("Email template error. Something went wrong.");
        return;
      }
      createOrUpdateTemplate.id === editEmailTemplateID
        ? appToast("Updated email template!")
        : appToast("Created email template!");
      setEmailTemplateModal(false);
      editEmailTemplateID && setEditEmailTemplateID("");

      const { contactCount, repCount, leadCount, organizationCount, customFieldCount } = countTemplateTokens({
        input: createOrUpdateTemplate.content,
        leadTokens: tokens?.lead || [],
        contactTokens: tokens?.contact || [],
        repTokens: tokens?.rep || [],
        organizationTokens: tokens?.organization || [],
        customFieldTokens: tokens?.["Custom Field"] || [],
      });

      MixpanelActions.track("Email Template Saved", {
        contact_tokens_used: contactCount,
        rep_tokens_used: repCount,
        lead_tokens_used: leadCount,
        organization_tokens_used: organizationCount,
        custom_field_tokens_used: customFieldCount,
      });
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `createOrUpdateTemplate GraphQL Error: ${message}`,
      });
      console.log("Error in createOrUpdateTemplate: ", message);
    },
    refetchQueries: ["fetchTemplates"],
  });

  useEffect(() => {
    // grab template data so we can prefill inputs
    if (!!editEmailTemplateID) fetchTemplate();

    // reset editEmailTemplateID on modal close
    return () => setEditEmailTemplateID("");
  }, []);

  const handleFilterReset = (visibility: any, param: any) => {
    const visibilityCopy = JSON.parse(JSON.stringify(visibility));
    visibilityCopy[param] = [];

    let pillListCopy = JSON.parse(JSON.stringify(pillList));

    switch (param) {
      case "team_ids":
        pillListCopy = pillListCopy.filter((pill: any) => !visibility?.team_ids?.includes(pill.id));
        break;
      case "user_ids":
        pillListCopy = pillListCopy.filter((pill: any) => !visibility?.user_ids?.includes(pill.id));
        break;
      default:
        break;
    }

    setPillList(pillListCopy);
    return visibilityCopy;
  };

  const handleCheck = (visibility: any, param: any, id: string) => {
    const visibilityCopy = JSON.parse(JSON.stringify(visibility));
    const pillListCopy: any = [...pillList];
    switch (param) {
      case "roles":
        // uncheck logic
        if (visibilityCopy.roles?.includes(id)) {
          visibilityCopy.roles = visibilityCopy.roles?.filter((role: any) => role !== id);
          setPillList(pillListCopy.filter((ele: any) => ele.id !== id));
        } else {
          // check logic
          visibilityCopy.roles?.push(id);
          pillListCopy.push({ text: id, type: "roles", id });
          setPillList(pillListCopy);
        }
        break;
      case "team_ids":
        // uncheck logic
        if (visibilityCopy.team_ids?.includes(id)) {
          visibilityCopy.team_ids = visibilityCopy.team_ids?.filter((teamId: any) => teamId !== id);
          setPillList(pillListCopy.filter((ele: any) => ele.id !== id));
        } else {
          // check logic
          visibilityCopy.team_ids?.push(id);
          pillListCopy.push({
            text: dataTeams?.fetchTeams?.find((team: any) => team.id === id)?.name,
            type: "team_ids",
            id,
          });
          setPillList(pillListCopy);
        }
        break;
      case "user_ids":
        // uncheck logic
        if (visibilityCopy.user_ids?.includes(id)) {
          visibilityCopy.user_ids = visibilityCopy.user_ids?.filter((repId: any) => repId !== id);
          setPillList(pillListCopy.filter((ele: any) => ele.id !== id));
        } else {
          // check logic
          visibilityCopy.user_ids?.push(id);
          pillListCopy.push({
            text: dataReps?.fetchReps?.find((rep: any) => rep.id === id)?.full_name,
            type: "user_ids",
            id,
          });
          setPillList(pillListCopy);
        }
        break;
      default:
        break;
    }

    return visibilityCopy;
  };

  const handlePrefillPills = (visibility: any) => {
    const pillList: any[] = [];

    visibility.roles?.forEach((role: string) => pillList.push({ text: role, type: "roles", id: role }));
    visibility.team_ids?.forEach((teamID: string) =>
      pillList.push({
        text: dataTeams?.fetchTeams?.find((team: any) => team.id === teamID)?.name,
        type: "team_ids",
        id: teamID,
      }),
    );
    visibility.user_ids?.forEach((userID: string) =>
      pillList.push({
        text: dataReps?.fetchReps?.find((rep: any) => rep.id === userID)?.full_name,
        type: "user_ids",
        id: userID,
      }),
    );

    setPillList(pillList);
  };

  const emailTemplateSchema = Yup.object().shape({
    template_name: Yup.string().required("Template Name is required.").trim(),
    subject: Yup.string().required("Email Subject is required.").trim(),
    content: Yup.string().required("Message is required.").trim(),
  });

  return (
    <>
      <DarkDiv />
      <ModalContainer direction="column" justify="space-between">
        <ModalHeader direction="column">
          <PhoenixIcon
            svg={xIcon}
            variant="brand"
            size={24}
            pointer
            onClick={() => {
              setEmailTemplateModal(false);
            }}
            style={{ width: "fit-content" }}
          />
          <AppText fontSize={16} fontWeight={600} style={{ width: "100%", textAlign: "center", whiteSpace: "nowrap" }}>
            {!!editEmailTemplateID ? "Edit" : "Create"} Email Template
          </AppText>
        </ModalHeader>

        <InfoHeader>
          <AppText fontSize={10} fontWeight={600} style={{ color: theme.NEUTRAL300, textTransform: "uppercase" }}>
            Step {step} of 2
          </AppText>
        </InfoHeader>

        {!!editEmailTemplateID && loadingTemplate ? (
          <Loading />
        ) : (
          <Formik
            initialValues={{
              type: "EMAIL",
              content: !!editEmailTemplateID ? dataTemplate?.fetchTemplates?.[0]?.content : "",
              subject: !!editEmailTemplateID ? dataTemplate?.fetchTemplates?.[0]?.subject : "",
              template_name: !!editEmailTemplateID ? dataTemplate?.fetchTemplates?.[0]?.name : "",
              template_id: !!editEmailTemplateID ? editEmailTemplateID : "",
              visibility: {
                user_ids: !!editEmailTemplateID ? dataTemplate?.fetchTemplates?.[0]?.visible_users : [],
                team_ids: !!editEmailTemplateID ? dataTemplate?.fetchTemplates?.[0]?.visible_teams : [],
                roles: !!editEmailTemplateID ? dataTemplate?.fetchTemplates?.[0]?.visible_roles : [],
              },
              use_signature: !!editEmailTemplateID ? dataTemplate?.fetchTemplates?.[0]?.use_signature : true,
            }}
            validationSchema={emailTemplateSchema}
            validateOnMount
            onSubmit={(e) => {
              const submitObjCopy = { ...e };
              submitObjCopy.content = handleLineHeights(submitObjCopy.content).innerHTML;

              // wipe visibility filters if switch is turned off
              if (!visibilityControls) submitObjCopy.visibility = { user_ids: [], team_ids: [], roles: [] };
              createOrEditEmailTemplate({
                variables: {
                  templateInput: submitObjCopy,
                },
              });
            }}
          >
            {({
              errors,
              submitForm,
              values,
              setFieldValue,
              isSubmitting,
              isValid,
              touched,
              validateForm,
            }: FormikProps<EmailFormikProps>) => (
              <>
                <ModalBody direction="column" gap={8}>
                  {step === 1 ? (
                    <>
                      <PhoenixInputField
                        name="template_name"
                        titleText="Template Name"
                        requiredStar
                        placeholder="Give your template a name"
                        labelWeight={500}
                        onChange={(e: any) => {
                          touched.template_name = true;
                          setFieldValue("template_name", e?.target?.value);
                        }}
                      />
                      <PhoenixInputField
                        id="emailSubjectFieldInput"
                        name="subject"
                        titleText="Email Subject"
                        requiredStar
                        placeholder="Provide a subject line"
                        labelWeight={500}
                        insiderightElementOverride={
                          <EmojiPicker
                            onEmojiSelect={(emoji) =>
                              setFieldValue("subject", handleEmojiInsert(values?.subject, emoji?.native))
                            }
                            onClick={() => {
                              document.getElementById("emailSubjectFieldInput")?.focus();
                            }}
                            top={12}
                            right={12}
                            zIndex={901}
                          />
                        }
                        onChange={(e: any) => {
                          touched.subject = true;
                          setFieldValue("subject", e?.target?.value);
                        }}
                      />
                      <TemplateEditorField
                        name="content"
                        titleText="Message"
                        requiredStar
                        titleFontWeight={500}
                        initialContent={
                          !!editEmailTemplateID ? dataTemplate?.fetchTemplates?.[0]?.content : values?.content
                        }
                        noMargin
                      />
                      {emailSignature && (
                        <FlexDiv gap={8} align="center" style={{ paddingBottom: "16px" }}>
                          <Switch
                            onChange={(checked: boolean) => setFieldValue("use_signature", checked)}
                            checked={values.use_signature}
                            onColor={theme.PRIMARY500}
                            offColor={theme.NEUTRAL200}
                            height={16}
                            width={32}
                            handleDiameter={12}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            activeBoxShadow="0 0 3px #000"
                          />
                          <AppText fontSize={12} fontWeight={500}>
                            Use email signature in this template
                          </AppText>
                        </FlexDiv>
                      )}
                      <Tokens loading={loadingTokens || loadingCustomFields} tokens={tokens} />
                    </>
                  ) : (
                    <>
                      <ControlWrapper justify="space-between" align="center">
                        <FlexDiv direction="column" gap={8}>
                          <AppText fontSize={14} fontWeight={500} color={theme.BLACK_COLOR}>
                            Visibility Controls
                          </AppText>
                          <AppText fontSize={14} fontWeight={500} color={theme.NEUTRAL300}>
                            Apply filters to control which users can access this template
                          </AppText>
                        </FlexDiv>
                        <Switch
                          onChange={(checked: boolean) => setVisibilityControls(checked)}
                          checked={visibilityControls}
                          onColor={theme.PRIMARY500}
                          offColor={theme.NEUTRAL200}
                          height={16}
                          width={32}
                          handleDiameter={12}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          activeBoxShadow="0 0 3px #000"
                        />
                      </ControlWrapper>
                      {visibilityControls && (
                        <>
                          <ControlWrapper direction="column" gap={16}>
                            <FlexDiv gap={4} wrap="wrap">
                              {!!pillList?.length ? (
                                pillList?.map((filter: any, index: number) => (
                                  <FilterPill type={filter?.type} text={filter?.text} key={`${filter.id}-pill`}>
                                    {filter.text}
                                    <PhoenixIcon
                                      svg={xIcon}
                                      size={12}
                                      pointer
                                      hoverColor={theme.DANGER700}
                                      color={
                                        filter.text === "AE"
                                          ? theme.WARNING600
                                          : filter.type === "user_ids"
                                          ? theme.PRIMARY500
                                          : theme.WHITE_COLOR
                                      }
                                      onClick={() => {
                                        setFieldValue(
                                          "visibility",
                                          handleCheck(values?.visibility, filter?.type, filter?.id),
                                        );
                                        setPillList([...pillList.slice(0, index), ...pillList.slice(index + 1)]);
                                      }}
                                    />
                                  </FilterPill>
                                ))
                              ) : (
                                <AppText
                                  fontSize={12}
                                  fontWeight={500}
                                  color={theme.NEUTRAL300}
                                  style={{ lineHeight: "24px" }}
                                >
                                  No filters selected.
                                </AppText>
                              )}
                            </FlexDiv>

                            <FlexDiv
                              gap={8}
                              align="center"
                              onClick={() => {
                                setFieldValue("visibility", {
                                  user_ids: [],
                                  team_ids: [],
                                  roles: [],
                                });
                                setPillList([]);
                              }}
                              style={{ cursor: "pointer", width: "fit-content" }}
                            >
                              <PhoenixIcon svg={refresh} variant="brand" size={12} pointer />
                              <AppText
                                fontSize={10}
                                fontWeight={500}
                                color={theme.PRIMARY500}
                                style={{ lineHeight: "14px", userSelect: "none" }}
                              >
                                Reset Filters
                              </AppText>
                            </FlexDiv>
                          </ControlWrapper>

                          <ControlWrapper direction="column" gap={18}>
                            <FlexDiv justify="space-between">
                              <AppText fontSize={12} fontWeight={500} color={theme.BLACK_COLOR}>
                                Roles
                              </AppText>
                              <PhoenixIcon
                                svg={rolesVisible ? chevron_up : chevron_down}
                                variant="brand"
                                pointer
                                onClick={() => setRolesVisible(!rolesVisible)}
                              />
                            </FlexDiv>

                            {rolesVisible && (
                              <FlexDiv gap={24}>
                                <FlexDiv align="center">
                                  <PhoenixCheckbox
                                    checked={values?.visibility?.roles?.includes("SDR")}
                                    onChange={() =>
                                      setFieldValue("visibility", handleCheck(values.visibility, "roles", "SDR"))
                                    }
                                  />
                                  <AppText fontSize={12} fontWeight={500} color={theme.BLACK_COLOR}>
                                    SDR
                                  </AppText>
                                </FlexDiv>
                                <FlexDiv align="center">
                                  <PhoenixCheckbox
                                    checked={values?.visibility?.roles?.includes("AE")}
                                    onChange={() =>
                                      setFieldValue("visibility", handleCheck(values.visibility, "roles", "AE"))
                                    }
                                  />
                                  <AppText fontSize={12} fontWeight={500} color={theme.BLACK_COLOR}>
                                    AE
                                  </AppText>
                                </FlexDiv>
                              </FlexDiv>
                            )}
                          </ControlWrapper>

                          <ControlWrapper direction="column" gap={18}>
                            <FlexDiv justify="space-between">
                              <AppText fontSize={12} fontWeight={500} color={theme.BLACK_COLOR}>
                                Teams
                              </AppText>
                              <PhoenixIcon
                                svg={teamsVisible ? chevron_up : chevron_down}
                                variant="brand"
                                pointer
                                onClick={() => setTeamsVisible(!teamsVisible)}
                              />
                            </FlexDiv>

                            {teamsVisible && (
                              <FlexDiv direction="column" gap={16}>
                                <PhoenixInput
                                  placeholder="Search Sales Teams"
                                  variant="primary"
                                  type="text"
                                  searchInput
                                  fontSize={10}
                                  fontWeight={400}
                                  displayNoContextText
                                  value={teamSearch}
                                  onChange={(e: any) => setTeamSearch(e.target.value)}
                                  height={40}
                                />

                                <FlexDiv
                                  gap={8}
                                  align="center"
                                  onClick={() =>
                                    setFieldValue("visibility", handleFilterReset(values.visibility, "team_ids"))
                                  }
                                  style={{ cursor: "pointer", width: "fit-content" }}
                                >
                                  <PhoenixIcon svg={refresh} variant="brand" size={12} pointer />
                                  <AppText
                                    fontSize={10}
                                    fontWeight={500}
                                    color={theme.PRIMARY500}
                                    style={{ lineHeight: "14px", userSelect: "none" }}
                                  >
                                    Reset Filters
                                  </AppText>
                                </FlexDiv>

                                <ListContainer direction="column" gap={16}>
                                  {loadingTeams ? (
                                    <Loading />
                                  ) : dataTeams?.fetchTeams?.filter((team: any) =>
                                      !!teamSearch.length
                                        ? team?.name?.toLowerCase()?.includes(teamSearch.toLowerCase())
                                        : true,
                                    )?.length === 0 ? (
                                    <AppText
                                      fontSize={12}
                                      fontWeight={500}
                                      color={theme.NEUTRAL300}
                                      style={{ lineHeight: "24px" }}
                                    >
                                      No teams found.
                                    </AppText>
                                  ) : (
                                    dataTeams?.fetchTeams
                                      ?.filter((team: any) =>
                                        !!teamSearch.length
                                          ? team?.name?.toLowerCase()?.includes(teamSearch.toLowerCase())
                                          : true,
                                      )
                                      ?.map((team: any) => (
                                        <FlexDiv align="center" key={team?.id}>
                                          <PhoenixCheckbox
                                            checked={values?.visibility?.team_ids?.includes(team?.id)}
                                            onChange={() =>
                                              setFieldValue(
                                                "visibility",
                                                handleCheck(values.visibility, "team_ids", team?.id),
                                              )
                                            }
                                          />
                                          <AppText fontSize={12} fontWeight={500} color={theme.BLACK_COLOR}>
                                            {team?.name}
                                          </AppText>
                                        </FlexDiv>
                                      ))
                                  )}
                                </ListContainer>
                              </FlexDiv>
                            )}
                          </ControlWrapper>

                          <ControlWrapper direction="column" gap={18}>
                            <FlexDiv justify="space-between">
                              <AppText fontSize={12} fontWeight={500} color={theme.BLACK_COLOR}>
                                Reps
                              </AppText>
                              <PhoenixIcon
                                svg={repsVisible ? chevron_up : chevron_down}
                                variant="brand"
                                pointer
                                onClick={() => setRepsVisible(!repsVisible)}
                              />
                            </FlexDiv>

                            {repsVisible && (
                              <FlexDiv direction="column" gap={16}>
                                <PhoenixInput
                                  placeholder="Search Reps"
                                  variant="primary"
                                  type="text"
                                  searchInput
                                  fontSize={10}
                                  fontWeight={400}
                                  displayNoContextText
                                  value={repSearch}
                                  onChange={(e: any) => setRepSearch(e.target.value)}
                                  height={40}
                                />

                                <FlexDiv
                                  gap={8}
                                  align="center"
                                  onClick={() =>
                                    setFieldValue("visibility", handleFilterReset(values.visibility, "user_ids"))
                                  }
                                  style={{ cursor: "pointer", width: "fit-content" }}
                                >
                                  <PhoenixIcon svg={refresh} variant="brand" size={12} pointer />
                                  <AppText
                                    fontSize={10}
                                    fontWeight={500}
                                    color={theme.PRIMARY500}
                                    style={{ lineHeight: "14px", userSelect: "none" }}
                                  >
                                    Reset Filters
                                  </AppText>
                                </FlexDiv>

                                <ListContainer direction="column" gap={16}>
                                  {loadingReps ? (
                                    <Loading />
                                  ) : dataReps?.fetchReps?.filter((rep: any) =>
                                      !!repSearch.length
                                        ? `${rep?.full_name}`.toLowerCase()?.includes(repSearch.toLowerCase())
                                        : true,
                                    )?.length === 0 ? (
                                    <AppText
                                      fontSize={12}
                                      fontWeight={500}
                                      color={theme.NEUTRAL300}
                                      style={{ lineHeight: "24px" }}
                                    >
                                      No reps found.
                                    </AppText>
                                  ) : (
                                    dataReps?.fetchReps
                                      ?.filter((rep: any) =>
                                        !!repSearch.length
                                          ? `${rep?.full_name}`.toLowerCase()?.includes(repSearch.toLowerCase())
                                          : true,
                                      )
                                      ?.map((rep: any) => (
                                        <FlexDiv align="center" key={rep?.id}>
                                          <PhoenixCheckbox
                                            checked={values?.visibility?.user_ids?.includes(rep?.id)}
                                            onChange={() =>
                                              setFieldValue(
                                                "visibility",
                                                handleCheck(values.visibility, "user_ids", rep?.id),
                                              )
                                            }
                                          />
                                          <AppText fontSize={12} fontWeight={500} color={theme.BLACK_COLOR}>
                                            {rep?.full_name}
                                          </AppText>
                                        </FlexDiv>
                                      ))
                                  )}
                                </ListContainer>
                              </FlexDiv>
                            )}
                          </ControlWrapper>
                        </>
                      )}
                    </>
                  )}
                </ModalBody>

                <ModalFooter justify="space-between">
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant={step === 1 ? "danger-outline" : "brand-outline"}
                    padding="12px 24px"
                    buttonTextFontSize={10}
                    uppercase
                    onClick={() => {
                      if (step === 1) {
                        setEmailTemplateModal(false);
                      } else {
                        setStep((prevState) => prevState - 1);
                      }
                    }}
                  >
                    {step === 1 ? "Cancel" : "Back"}
                  </PhoenixAppButton>
                  <PhoenixAppButton
                    buttonType="secondary"
                    padding="12px 24px"
                    buttonTextFontSize={10}
                    uppercase
                    disabled={!isValid || !!isSubmitting}
                    onClick={() => {
                      if (step === 1) {
                        setStep((prevState) => prevState + 1);
                        handlePrefillPills(values?.visibility);
                      } else {
                        submitForm();
                        setEmailTemplateModal(false);
                      }
                    }}
                  >
                    {step === 1 ? "Next Step" : "Save Template"}
                  </PhoenixAppButton>
                </ModalFooter>
              </>
            )}
          </Formik>
        )}
      </ModalContainer>
    </>
  );
};

interface FilterPillProps {
  type: "user_ids" | "team_ids" | "roles";
  text?: string;
}

const FilterPill = styled.div<FilterPillProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  width: fit-content;
  height: 24px;
  padding: 6px 8px;

  background-color: ${(props) => {
    switch (props.type) {
      case "roles":
        if (props.text === "SDR") {
          return theme.PRIMARY500;
        }
        return theme.WARNING500;
      case "team_ids":
        return theme.NEUTRAL300;
      case "user_ids":
        return theme.PRIMARY200;
    }
  }};
  color: ${(props) => {
    switch (props.type) {
      case "roles":
        if (props.text === "SDR") {
          return theme.WHITE_COLOR;
        }
        return theme.BLACK_COLOR;
      case "team_ids":
        return theme.WHITE_COLOR;
      case "user_ids":
        return theme.PRIMARY500;
    }
  }};

  border-radius: 4px;

  text-transform: uppercase;
  line-height: 12px;
  font-size: 8px;
  font-weight: 600;
`;

const ListContainer = styled(FlexDiv)`
  height: 200px;
  overflow-y: auto;
`;

const ControlWrapper = styled(FlexDiv)`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const InfoHeader = styled.div`
  padding: 24px 40px;
`;

const ModalHeader = styled(FlexDiv)`
  padding: 8px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ModalBody = styled(FlexDiv)`
  width: 100%;
  max-height: 80vh;
  padding: 0px 40px 24px 40px;

  margin-bottom: auto;
  overflow-y: overlay;
`;

const ModalFooter = styled(FlexDiv)`
  width: 100%;
  padding: 24px 40px 40px 40px;
`;

const slideIn = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: 600px;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ModalContainer = styled(FlexDiv)`
  position: fixed;
  z-index: 899;
  top: 0;
  right: 0;

  width: 600px;
  height: 100vh;

  background-color: ${theme.WHITE_COLOR};

  animation: ${slideIn} 0.5s ease forwards;
  & > * {
    animation: ${fadeIn} 0.75s ease forwards;
  }
`;
