import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import styled from "styled-components";
import { openUploadWidget } from "../../services/cloudinary";
import { removeNewLineAtEndOfString } from "../../utils/format";
import { theme } from "../../utils/theme";
import {
  CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_UPLOAD_PRESET,
  HTML_ATTRIBUTES_SAFE_TO_RENDER_IN_UI,
  HTML_TAGS_SAFE_TO_RENDER_IN_UI,
} from "../../utils/variables";
import { EmojiPicker } from "./EmojiPicker";
import { Loading } from "./Loading";

import * as DOMPurify from "dompurify";
import { BsImageFill } from "react-icons/bs";

import BlotFormatter from "quill-blot-formatter";
import { appToast } from "../../utils/toast";

const Size = Quill.import("attributors/style/size");
Size.whitelist = ["0.75em", "1em", "1.5em", "2.5em"];
Quill.register(Size, true);
const FontAttr = Quill.import("attributors/style/font");
FontAttr.whitelist = ["sans-serif", "serif", "monospace"];
Quill.register(FontAttr, true);

Quill.register("modules/blotFormatter", BlotFormatter);

const Link = Quill.import('formats/link');

class AutoPrefixedLink extends Link {
  static create(value: any) {
    if (typeof value === 'string' && value && !value.match(/^https?:\/\//i)) {
      value = `https://${value}`;
    }
    return super.create(value);
  }
}

Quill.register('formats/link', AutoPrefixedLink, true);

export interface TemplateEditorProps {
  onClick?: () => void;
  onContentChange?: (content: string) => void;
  titleText?: string;
  requiredStar?: boolean;
  titleFontWeight?: number;
  initialContent?: string | null;
  ableToEdit?: boolean;
  smsEditor?: boolean;
  maxKeyCount?: number;
  deliver?: boolean;
  setUserHasAlteredTemplate?: (hasAltered: boolean) => void;
  userHasAlteredTemplate?: boolean;

  // style overrides
  editorMinHeight?: string | number;
  editorMaxHeight?: string | number;
  height?: string | number;
  width?: string | number;
  noPaddingOnToolbar?: boolean;
  noMargin?: boolean;
  displayNoContextText?: boolean;
  contextText?: string | false;
  emojiPickerTop?: number;
  emojiPickerRight?: number;
  border?: boolean;

  // custom toolbar
  toolbarImg?: boolean;
  toolbarEmoji?: boolean;
  toolbarFontSize?: boolean;
  toolbarFont?: boolean;
  toolbarBold?: boolean;
  toolbarItalic?: boolean;
  toolbarUnderline?: boolean;
  toolbarColor?: boolean;
  toolbarAlign?: boolean;
  toolbarList?: boolean;
  toolbarIndent?: boolean;
  toolbarLink?: boolean;
}

//--------------------------- Toolbar ----------------------------------//

const Align = () => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const align = event.target.value;
  };

  return (
    <select className="ql-align" onChange={handleChange} defaultValue="">
      <option value="">Align</option>
      <option value="center">Center</option>
      <option value="right">Right</option>
      <option value="justify">Justify</option>
    </select>
  );
};

const FontSize = () => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const size = event.target.value;
  };

  return (
    <select
      className="ql-size"
      onChange={handleChange}
      placeholder="Style"
      style={{
        border: `1px solid ${theme.NEUTRAL300}`,
        borderRadius: "4px",
      }}
    >
      {/* <option value="Style">Style</option> */}
      <option value="0.75em">Small</option>
      <option value="1em">Normal</option>
      <option value="1.5em">Large</option>
      <option value="2.5em">Huge</option>
    </select>
  );
};

const Font = () => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const font = event.target.value;
  };

  return (
    <select
      className="ql-font"
      onChange={handleChange}
      placeholder="Font"
      style={{
        border: `1px solid ${theme.NEUTRAL300}`,
        borderRadius: "4px",
      }}
    >
      <option value="sans-serif">Sans Serif</option>
      <option value="serif">Serif</option>
      <option value="monospace">Monospace</option>
    </select>
  );
};

const CustomCloudinaryUploadButton = ({ quillRef }: { quillRef: React.MutableRefObject<ReactQuill | null> }) => {
  const handleClick = () => {
    if (quillRef?.current) {
      const quillEditor = quillRef.current.getEditor();
      const cursorPosition = quillEditor.getSelection()?.index || quillEditor.getLength() - 1 || 0;

      openUploadWidget((error: any, photos: any) => {
        if (!error && photos && photos.event === "success") {
          const imageURL = photos.info.public_id;

          // insert pWithImg into the editor
          quillEditor.insertEmbed(
            cursorPosition,
            "image",
            `https://res.cloudinary.com/rapptrlabs/image/upload/c_crop,g_custom/${imageURL}`,
          );

          // move cursor to the end of editor
          quillEditor.setSelection(cursorPosition + 1, cursorPosition + 1, "user");
        } else {
          console.log(error);
        }
      });
    }
  };

  return (
    <button
      className="ql-format"
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}
    >
      <BsImageFill />
    </button>
  );
};

const CustomToolbar = ({
  quillRef,
  editorContent,
  smsEditor = false,
  emojiPickerTop,
  emojiPickerRight,
  maxKeyCount,
  toolbarImg = true,
  toolbarEmoji = true,
  toolbarFontSize = true,
  toolbarFont = true,
  toolbarBold = true,
  toolbarItalic = true,
  toolbarUnderline = true,
  toolbarColor = true,
  toolbarAlign = true,
  toolbarList = true,
  toolbarIndent = true,
  toolbarLink = true,
}: {
  quillRef: React.MutableRefObject<ReactQuill | null>;
  editorContent: string;
  smsEditor?: boolean;
  emojiPickerTop?: number;
  emojiPickerRight?: number;
  maxKeyCount?: number;
  toolbarImg?: boolean;
  toolbarEmoji?: boolean;
  toolbarFontSize?: boolean;
  toolbarFont?: boolean;
  toolbarBold?: boolean;
  toolbarItalic?: boolean;
  toolbarUnderline?: boolean;
  toolbarColor?: boolean;
  toolbarAlign?: boolean;
  toolbarList?: boolean;
  toolbarIndent?: boolean;
  toolbarLink?: boolean;
}) => (
  <div id="toolbar">
    {!smsEditor && (
      <>
        {toolbarFontSize && <FontSize />}

        {toolbarFont && <Font />}
        {toolbarBold && <button className="ql-bold">Bold</button>}
        {toolbarItalic && <button className="ql-italic">Italic</button>}
        {toolbarUnderline && <button className="ql-underline">Underline</button>}
        {toolbarColor && <select className="ql-color"></select>}

        {toolbarAlign && <Align />}
        {toolbarList && <button className="ql-list" value="ordered"></button>}
        {toolbarList && <button className="ql-list" value="bullet"></button>}

        {toolbarIndent && <button className="ql-indent" value="+1"></button>}
        {toolbarIndent && <button className="ql-indent" value="-1"></button>}

        {toolbarLink && <button className="ql-link"></button>}
        {toolbarImg && <CustomCloudinaryUploadButton quillRef={quillRef} />}
      </>
    )}
    <div
      style={{
        display: "absolute",
      }}
    >
      <EmojiPicker
        onEmojiSelect={(emoji) => {
          if (
            !!maxKeyCount &&
            quillRef.current?.unprivilegedEditor?.getLength() &&
            quillRef.current?.unprivilegedEditor?.getLength() >= maxKeyCount
          ) {
            // dont insert emoji if we've reached out maxKeyCount
            return null;
          }
          if (quillRef.current) {
            const quillEditor = quillRef.current.getEditor();
            const cursorPosition = quillEditor.getSelection()?.index || quillEditor.getLength() - 1;
            quillEditor.insertText(cursorPosition, emoji.native);
          }
        }}
        top={emojiPickerTop || 12}
        right={emojiPickerRight || 12}
      />
    </div>
  </div>
);

//---------------------------Editor-------------------------------------------//

const TemplateEditor: React.FC<TemplateEditorProps> = ({
  onContentChange,
  titleText,
  requiredStar,
  titleFontWeight,
  initialContent = "",
  ableToEdit = true,
  width,
  noPaddingOnToolbar = false,
  displayNoContextText = false,
  contextText,
  smsEditor = false,
  emojiPickerTop,
  emojiPickerRight,
  editorMinHeight,
  height,
  maxKeyCount,
  noMargin,
  deliver,
  setUserHasAlteredTemplate,
  editorMaxHeight,
  toolbarImg = true,
  toolbarEmoji = true,
  toolbarFontSize = true,
  toolbarFont = true,
  toolbarBold = true,
  toolbarItalic = true,
  toolbarUnderline = true,
  toolbarColor = true,
  toolbarAlign = true,
  toolbarList = true,
  toolbarIndent = true,
  toolbarLink = true,
  border = true,
}) => {
  const sanitizedInitialContent = DOMPurify.sanitize(initialContent ?? "", {
    ALLOWED_TAGS: HTML_TAGS_SAFE_TO_RENDER_IN_UI,
    ALLOWED_ATTR: HTML_ATTRIBUTES_SAFE_TO_RENDER_IN_UI,
  });

  const quillRef = useRef<ReactQuill>(null);
  const [HTMLEditorContent, setHTMLEditorContent] = useState<string | null>(sanitizedInitialContent);
  const [manualLoadingState, setManualLoadingState] = useState(false);

  const modules = {
    toolbar: false as any,
    blotFormatter: {},
  };

  // add the custom toolbar to the quill editor
  if (ableToEdit) {
    modules.toolbar = {
      container: "#toolbar",
    };
  }

  // ---------------------------Data Logic-------------------------------------------//

  // ---------------------------Initial Content Change-------------------------------------------//

  useEffect(() => {
    if (HTMLEditorContent !== initialContent && deliver) setHTMLEditorContent(initialContent);
    setUserHasAlteredTemplate && setUserHasAlteredTemplate(false);
  }, [initialContent]);

  // ---------------------------Update Higher State-------------------------------------------//

  // update any higher level state with formatted content
  // this will change what is passed up based on editor type (sms or html)
  useEffect(() => {
    if (onContentChange) {
      if (smsEditor) {
        // return string content
        if (HTMLEditorContent) {
          // get text content from editor this needs to be done here to prevent race condition
          const textContent = quillRef.current?.getEditor().getText() || "";
          onContentChange(removeNewLineAtEndOfString(textContent));
        }
      } else {
        // return html content without the blue formatting
        if (HTMLEditorContent) {
          onContentChange(HTMLEditorContent);
        }
      }
    }
  }, [HTMLEditorContent]);

  // ---------------------------Helper Functions-------------------------------------------//

  const handleEditorChange = async (content: string, delta: any, source: any, editor: any) => {
    // local state update
    setHTMLEditorContent(content);

    // // check for image pastes and update editor content if needed
    await checkForUnformatedImages(content, setHTMLEditorContent, setManualLoadingState, smsEditor);
  };

  const uploadImageToCloudinary = async (imageSrc: string): Promise<any> => {
    console.log("imageSrc", imageSrc);
    const formData = new FormData();
    formData.append("file", imageSrc);

    const response = await fetch(
      `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/image/upload?upload_preset=${CLOUDINARY_UPLOAD_PRESET}`,
      {
        method: "POST",
        body: formData,
      },
    );

    console.log("response", response);

    const data = await response.json();

    return data;
  };

  const checkForUnformatedImages = async (
    content: string,
    setHTMLEditorContent: React.Dispatch<React.SetStateAction<string | null>>,
    setManualLoadingState: React.Dispatch<React.SetStateAction<boolean>>,
    smsEditor = false,
  ) => {
    if (ableToEdit === false) return;
    let updatedHTML = content;

    const allImages = updatedHTML.match(/<img[^>]+>/g);

    if (!allImages) return;

    // IMAGES ARE NOT SUPPORTED FOR SMS TEMPLATES
    if (smsEditor) {
      // remove all images and toast user
      allImages.forEach((image) => {
        updatedHTML = updatedHTML.replace(image, "");
      });

      appToast("Images are not supported for SMS templates.");
      setHTMLEditorContent(updatedHTML);
      return;
    }

    const unuploadedImages = allImages?.filter(
      (image) =>
        !image.includes("res.cloudinary") &&
        image.includes("src=") &&
        // failsafe for img sources that are blank(broken images)
        image.match(/src="([^"]*)/)?.[1],
    );

    if (!unuploadedImages?.length) return;

    setManualLoadingState(true);

    try {
      const uploadedImages = await Promise.all(
        unuploadedImages?.map(async (image) => {
          const imageSrc = image.match(/src="([^"]*)/)?.[1];
          if (!imageSrc) throw new Error("Image source not found.");

          const cloudinaryResponse = await uploadImageToCloudinary(imageSrc);
          const url = cloudinaryResponse?.url;

          console.log("cloudinaryResponse", cloudinaryResponse);
          if (!url) {
            // remove image from the editor to prevent infinite loop
            // otherwise the image will stay as base64 and try be uploaded again on the next change
            updatedHTML = updatedHTML.replace(image, "");
            throw new Error("Cloudinary URL not found.");
          }

          updatedHTML = updatedHTML.replace(imageSrc, url);
          return { oldSrc: imageSrc, newSrc: url };
        }),
      );

      setHTMLEditorContent(updatedHTML);
    } catch (error) {
      console.error(error);
      appToast("Error uploading your image. Please try uploading the image with the image icon in the editor.");

      setHTMLEditorContent(updatedHTML);
    }

    setManualLoadingState(false);
  };

  const checkCharCount = (event: any) => {
    const editor = quillRef?.current?.unprivilegedEditor;
    if (maxKeyCount && editor && editor?.getLength() > maxKeyCount && event?.key !== "Backspace")
      event.preventDefault();
  };

  // ---------------------------Render-------------------------------------------//

  return (
    <div>
      {!!titleText && (
        <InputTitle titleFontWeight={titleFontWeight}>
          {titleText}
          {requiredStar && <span style={{ color: theme.DANGER600 }}>*</span>}
        </InputTitle>
      )}
      {manualLoadingState ? (
        <Loading />
      ) : (
        <EditorMainDiv
          width={width}
          noPaddingOnToolbar={noPaddingOnToolbar}
          editorMinHeight={editorMinHeight}
          editorMaxHeight={editorMaxHeight}
          noMargin={noMargin}
          border={border}
        >
          <ReactQuill
            ref={quillRef}
            value={HTMLEditorContent || ""}
            onChange={(content, delta, source, editor) => {
              handleEditorChange(content, delta, source, editor);
              if (source === "user") setUserHasAlteredTemplate && setUserHasAlteredTemplate(true);
            }}
            modules={modules}
            theme="snow"
            readOnly={!ableToEdit}
            onKeyDown={(e) => !!maxKeyCount && checkCharCount(e)}
            
          />

          {ableToEdit && (
            <CustomToolbar
              quillRef={quillRef}
              editorContent={HTMLEditorContent || ""}
              smsEditor={smsEditor}
              emojiPickerTop={emojiPickerTop}
              emojiPickerRight={emojiPickerRight}
              maxKeyCount={maxKeyCount}
              // toolbar props
              toolbarImg={toolbarImg}
              toolbarEmoji={toolbarEmoji}
              toolbarFontSize={toolbarFontSize}
              toolbarFont={toolbarFont}
              toolbarBold={toolbarBold}
              toolbarItalic={toolbarItalic}
              toolbarUnderline={toolbarUnderline}
              toolbarColor={toolbarColor}
              toolbarAlign={toolbarAlign}
              toolbarList={toolbarList}
              toolbarIndent={toolbarIndent}
              toolbarLink={toolbarLink}
            />
          )}
        </EditorMainDiv>
      )}

      {displayNoContextText ? undefined : !!contextText ? (
        <InputContextText>{contextText}</InputContextText>
      ) : (
        <InputContextText>&nbsp;</InputContextText>
      )}
    </div>
  );
};

const EditorMainDiv = styled.div<{
  width?: string | number;
  noPaddingOnToolbar?: boolean;
  editorMinHeight?: string | number;
  editorMaxHeight?: string | number;
  height?: string | number;
  noMargin?: boolean;
  border?: boolean;
}>`
  width: ${(props) => (props.width ? (typeof props.width === "number" ? `${props.width}px` : props.width) : "100%")};
  min-height: ${(props) =>
    props.editorMinHeight
      ? typeof props.editorMinHeight === "number"
        ? `${props.editorMinHeight}px`
        : props.editorMinHeight
      : "200px"};
  border: ${(props) => (props.border ? `1px solid ${theme.NEUTRAL300}` : "none")};
  border-radius: 4px;
  padding: 10px;
  margin-bottom: ${(props) => (props.noMargin ? "unset" : "20px")};
  max-height: ${(props) =>
    props.editorMaxHeight
      ? typeof props.editorMaxHeight === "number"
        ? `${props.editorMaxHeight}px`
        : props.editorMaxHeight
      : "unset"};
  .ql-editor {
    height: 100%;
    min-height: ${(props) =>
      props.editorMinHeight
        ? typeof props.editorMinHeight === "number"
          ? `${props.editorMinHeight}px`
          : props.editorMinHeight
        : "200px"};
    max-height: ${(props) =>
      props.editorMaxHeight
        ? typeof props.editorMaxHeight === "number"
          ? `${props.editorMaxHeight - 40}px`
          : props.editorMaxHeight
        : "400px"};
    overflow: auto;
  }

  #toolbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => (props.noPaddingOnToolbar ? "0" : "0 5px")};
  }
  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor {
    padding: 0;
  }

  .ql-editor p {
    line-height: 18px;
  }

  .ql-toolbar.ql-snow {
    border: none;
  }

  .ql-size.ql-picker {
    font-size: 8px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 4px;
  }

  .ql-font.ql-picker {
    font-size: 8px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #toolbar > span.ql-font.ql-picker .ql-picker-label {
    svg {
      width: 0px !important;
    }
  }

  #toolbar > span.ql-size.ql-picker .ql-picker-label {
    svg {
      width: 0px !important;
    }
  }

  .ql-size {
    .ql-picker-label:after {
      content: "▾";
      position: absolute;
      top: 2px !important;
      right: 5px;
      font-size: 12px;
    }
  }

  // same for font
  .ql-font {
    .ql-picker-label:after {
      content: "▾";
      position: absolute;
      top: 2px !important;
      right: 5px;
      font-size: 12px;
    }
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="0.75em"]::before {
    content: "Small";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1em"]::before {
    content: "Normal";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1.5em"]::before {
    content: "Large";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="2.5em"]::before {
    content: "Huge";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
    content: "Small";
    font-size: 0.75em !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before {
    content: "Normal";
    font-size: 1em !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
    content: "Large";
    font-size: 1.5em !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
    content: "Huge";
    font-size: 2.5em !important;
  }
`;

const InputTitle = styled.p<{ titleFontWeight?: number }>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-bottom: 7px;
  text-align: left;
  font-weight: ${(props) => (props.titleFontWeight ? props.titleFontWeight : 400)};
`;

const InputContextText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  margin: 0;
  margin-top: 4px;
  text-align: left;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  text-shadow: 1px 1px 2px ${theme.WHITE_COLOR};
  color: ${theme.ATTENTION700};
`;

export { TemplateEditor };
