import React, { useState, ChangeEvent, useRef, useEffect, useMemo } from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { PhoenixInput, PhoenixTextArea, PhoenixAppButton, PhoenixMultiSelect, PhoenixIcon } from "../UI/Phoenix";
import { Modal } from "../modal/Modal";
import { gql, useQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { appToast } from "../../utils/toast";
import { useHistory, useLocation } from "react-router-dom";
import { TemplateOption } from "../../types";
import { AppText, FlexDiv, SkeletonBlock, StatCard } from "../UI";
import { sound_wave } from "../../images/NewDesign";
import Switch from "react-switch";
import { FiSettings } from "react-icons/fi";
import { DispositionReasonPill } from "../UI/Pills";
import { PersonSpokeToPill } from "../UI/Pills";
import { CallResultPill } from "../UI/Pills";
import { UserPill } from "../UI/Pills/UserPill";
import { LeadPill } from "../UI/Pills/LeadPill";

const FETCH_CONFERENCE_INFO = gql`
  query fetchConference($conference_id: String!) {
    fetchConference(conference_id: $conference_id) {
      id
      users {
        id
        full_name
        profile_image
      }
      lead {
        id
        full_name
        associate_parent_id
      }
      related_disposition_reason
      call_result {
        label
        phase_label
        computed_call_history_theme {
          completed_with_theme {
            label
            bg_color
            font_color
          }
          call_result_theme {
            label
            bg_color
            font_color
          }
        }
      }
    }
  }
`;

const FETCH_CONFERENCE_AI_TEMPLATES = gql`
  query fetchConferenceAiTemplates(
    $only_own_templates: Boolean
    $name_like: String
    $lead_spoken_with: CALLRESULT
    $lead_current_phase: PHASE
    $lead_call_result: DISPOSITIONTYPE
    $limit: Int
    $offset: Int
  ) {
    fetchConferenceAiTemplates(
      only_own_templates: $only_own_templates
      name_like: $name_like
      lead_spoken_with: $lead_spoken_with
      lead_current_phase: $lead_current_phase
      lead_call_result: $lead_call_result
      limit: $limit
      offset: $offset
    )
  }
`;

const TEST_AI_CALL_REPORT = gql`
  query TestAICallReport($conference_id: String!, $system_prompt: String, $user_prompt: String) {
    TestAICallReport(conference_id: $conference_id, system_prompt: $system_prompt, user_prompt: $user_prompt)
  }
`;

const CREATE_CONFERENCE_AI_TEMPLATE = gql`
  mutation createConferenceAiTemplate(
    $name: String!
    $template_notes: String
    $lead_spoken_with: CALLRESULT
    $lead_current_phase: PHASE
    $lead_call_result: DISPOSITIONTYPE
    $generative_model_selection: String
    $system_prompt: String!
    $user_prompt: String!
  ) {
    createConferenceAiTemplate(
      name: $name
      template_notes: $template_notes
      lead_spoken_with: $lead_spoken_with
      lead_current_phase: $lead_current_phase
      lead_call_result: $lead_call_result
      generative_model_selection: $generative_model_selection
      system_prompt: $system_prompt
      user_prompt: $user_prompt
    )
  }
`;

const UPDATE_CONFERENCE_AI_TEMPLATE = gql`
  mutation updateConferenceAiTemplate(
    $id: String!
    $name: String
    $template_notes: String
    $lead_spoken_with: CALLRESULT
    $lead_current_phase: PHASE
    $lead_call_result: DISPOSITIONTYPE
    $generative_model_selection: String
    $system_prompt: String
    $user_prompt: String
  ) {
    updateConferenceAiTemplate(
      id: $id
      name: $name
      template_notes: $template_notes
      lead_spoken_with: $lead_spoken_with
      lead_current_phase: $lead_current_phase
      lead_call_result: $lead_call_result
      generative_model_selection: $generative_model_selection
      system_prompt: $system_prompt
      user_prompt: $user_prompt
    )
  }
`;

const Container = styled.div`
  padding: 24px;
  padding-bottom: 64px;
  background: ${theme.NEUTRAL200};
  height: 100%;
  overflow: hidden;
`;

const DashboardContainer = styled.div`
  background: #f8fcff;
  border-radius: 8px;
  padding-bottom: 24px;
  height: 100%;
  overflow-y: auto;
`;

const HeaderBorder = styled.div`
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  background: ${theme.WHITE_COLOR};
  padding: 24px;
  border-radius: 8px 8px 0px 0px;
  overflow-x: auto;
  height: 121px;
`;

const Title = styled.h1`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 22px;
  font-weight: 500;
  color: ${theme.BLACK_COLOR};
  margin: 0;
`;

const ButtonContainer = styled.div`
  width: 140px;
  display: flex;
  > button {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  background: ${theme.WHITE_COLOR};
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
  margin: 40px;
  box-sizing: border-box;
  width: calc(100% - 80px);
  padding: 24px;
`;

const LeftPanel = styled.div`
  flex: 0 0 30%;
  background: ${theme.WHITE_COLOR};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;
  min-height: 100%;
`;

const RightPanel = styled.div`
  flex: 1;
  background: ${theme.WHITE_COLOR};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ResponseTitle = styled(Title)`
  font-size: 18px;
  margin-bottom: 8px;
`;

const InputLabel = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  font-weight: 600;
  color: ${theme.BLACK_COLOR};
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const RequiredIndicator = styled.span`
  color: ${theme.PILL_DARK_RED};
  font-size: 12px;
  font-weight: 600;
`;

const MarkdownContainer = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  white-space: pre-wrap;
  word-break: break-word;
`;

const SaveTemplateButton = styled.div`
  margin-top: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 60px; /* Ensure there's enough height for vertical centering */
`;

const HeaderItemTitle = styled.h3`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  font-weight: 500;
`;

const ResponseContainer = styled.div`
  background: ${theme.NEUTRAL100};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  min-height: 256px;
  margin-top: 24px;
`;

interface TemplatePanelProps {
  name: string;
  templates: TemplateOption[];
  selectedTemplate: TemplateOption | null;
  handleTemplateChange: (selected: TemplateOption | null) => void;
  templatesLoading: boolean;
  systemPrompt: string;
  userPrompt: string;
  systemPromptRef: React.RefObject<HTMLTextAreaElement>;
  userPromptRef: React.RefObject<HTMLTextAreaElement>;
  handleSystemPromptChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleUserPromptChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  isEditingTemplate: boolean;
  handleSaveTemplate: () => Promise<void>;
  isTemplateModified: boolean;
  setShowSaveAsModal: (show: boolean) => void;
  data?: any;
  loading?: boolean;
  error?: any;
}

const TemplatePanel: React.FC<TemplatePanelProps> = ({
  name,
  templates,
  selectedTemplate,
  handleTemplateChange,
  templatesLoading,
  systemPrompt,
  userPrompt,
  systemPromptRef,
  userPromptRef,
  handleSystemPromptChange,
  handleUserPromptChange,
  isEditingTemplate,
  handleSaveTemplate,
  isTemplateModified,
  setShowSaveAsModal,
  data,
  loading,
  error,
}) => {
  return (
    <TemplatePanelContainer>
      <FlexDiv direction="column">
        <TemplatePanelHeader>
          <TemplatePanelTitle>{name}</TemplatePanelTitle>
          <FlexDiv>
            <PhoenixMultiSelect
              name="ai-model-select"
              isMulti={false}
              options={[]}
              value={null}
              onChange={() => {}}
              placeholder="Select AI model"
              isClearable={true}
              marginBottom={false}
              error={null}
              hideErrorMessage={true}
              width={176}
              style={{
                display: "none",
              }}
            />
          </FlexDiv>
        </TemplatePanelHeader>
        <div style={{ marginTop: 24 }}>
          <InputLabel>
            Template
            <RequiredIndicator>*</RequiredIndicator>
          </InputLabel>
          <FlexDiv direction="row" align="center" gap={8}>
            <PhoenixMultiSelect
              name="template-select"
              isMulti={false}
              options={templates}
              value={selectedTemplate}
              onChange={handleTemplateChange}
              placeholder="Select a template"
              isClearable={true}
              marginBottom={false}
              error={null}
              isLoading={templatesLoading}
              hideErrorMessage={true}
              width={336}
              pointer={false}
            />
            <FiSettings
              size={20}
              style={{ cursor: "pointer", color: theme.PRIMARY500 }}
              onClick={() => setShowSaveAsModal(true)}
            />
          </FlexDiv>
        </div>
        <FlexDiv direction="row" gap={24} style={{ width: "100%", marginTop: 24 }}>
          <FlexDiv direction="column" style={{ flex: 1 }}>
            <InputLabel>System Prompt</InputLabel>
            <PhoenixTextArea
              ref={systemPromptRef}
              value={systemPrompt}
              onChange={handleSystemPromptChange}
              placeholder="Enter system prompt"
              minHeight={240}
              width="100%"
              style={{ resize: "vertical" }}
              error={isEditingTemplate && !systemPrompt ? "System prompt is required" : false}
            />
          </FlexDiv>
          <FlexDiv direction="column" style={{ flex: 1 }}>
            <InputLabel>User Prompt</InputLabel>
            <PhoenixTextArea
              ref={userPromptRef}
              value={userPrompt}
              onChange={handleUserPromptChange}
              placeholder="Enter user prompt"
              minHeight={240}
              width="100%"
              style={{ resize: "vertical" }}
            />
          </FlexDiv>
        </FlexDiv>
        <SaveTemplateButton>
          <FlexDiv justify="flex-end" gap={16} align="center" style={{ width: "100%" }}>
            <PhoenixAppButton
              buttonType="ghost-large"
              variant="brand"
              onClick={() => setShowSaveAsModal(true)}
              disabled={!systemPrompt.trim()}
              width="auto"
              style={{ whiteSpace: "normal", wordBreak: "break-word", maxWidth: "150px" }}
            >
              Save As
            </PhoenixAppButton>
            <PhoenixAppButton
              buttonType="primary"
              variant="brand"
              onClick={handleSaveTemplate}
              disabled={!selectedTemplate || !isTemplateModified}
              width="auto"
              style={{ whiteSpace: "normal", wordBreak: "break-word", maxWidth: "150px" }}
            >
              Save Template
            </PhoenixAppButton>
          </FlexDiv>
        </SaveTemplateButton>
        <ResponseContainer>
          <ResponseHeader>
            <ResponseTitle>Response Preview</ResponseTitle>
            {data?.TestAICallReport[0]?.tokens_used && (
              <AppText color={theme.NEUTRAL500} fontSize={10} fontWeight={400}>
                <TokenText>{data?.TestAICallReport[0]?.tokens_used}</TokenText> tokens used
              </AppText>
            )}
          </ResponseHeader>

          <MarkdownContainer>
            {data?.TestAICallReport[0]?.content && data.TestAICallReport[0].content}
          </MarkdownContainer>
        </ResponseContainer>
      </FlexDiv>
    </TemplatePanelContainer>
  );
};

interface BottomRunBarProps {
  handleRun: () => void;
  loading: boolean;
  abTestEnabled?: boolean;
  onAbTestToggle?: (checked: boolean) => void;
}

const BottomRunBar: React.FC<BottomRunBarProps> = ({ handleRun, loading, abTestEnabled = false, onAbTestToggle }) => {
  return (
    <BottomRunBarContainer>
      <BottomRunBarContent>
        <RightAlignedControls>
          <ABTestContainer>
            <Switch
              onChange={(checked: boolean) => onAbTestToggle && onAbTestToggle(checked)}
              checked={abTestEnabled}
              onColor={theme.PRIMARY500}
              offColor={theme.NEUTRAL200}
              height={20}
              width={40}
              handleDiameter={16}
              checkedIcon={false}
              uncheckedIcon={false}
            />
            <ABTestLabel>A/B Test</ABTestLabel>
          </ABTestContainer>
          <RunButtonContainer>
            <PhoenixAppButton buttonType="primary" variant="brand" onClick={handleRun} disabled={loading}>
              {loading ? "Running..." : "Run Test"}
            </PhoenixAppButton>
          </RunButtonContainer>
        </RightAlignedControls>
      </BottomRunBarContent>
    </BottomRunBarContainer>
  );
};

const HeaderLoader = () => {
  return (
    <HeaderRightContainer align="flex-start" gap={40}>
      <HeaderRightCell>
        <HeaderItemTitle>Conference ID</HeaderItemTitle>
        <FlexDiv align="center" direction="row" gap={4} style={{ flexWrap: "wrap", rowGap: "8px" }}>
          <SkeletonBlock width={150} height={24} borderRadius={4} delayNumber={1} />
        </FlexDiv>
      </HeaderRightCell>

      <HeaderRightCell>
        <HeaderItemTitle>Call Disposition</HeaderItemTitle>
        <FlexDiv align="center" direction="row" gap={4} style={{ flexWrap: "wrap", rowGap: "8px" }}>
          <SkeletonBlock width={150} height={24} borderRadius={4} delayNumber={3} />
        </FlexDiv>
      </HeaderRightCell>

      <HeaderRightCell>
        <HeaderItemTitle>Speakers</HeaderItemTitle>
        <FlexDiv align="center" direction="row" gap={4} style={{ flexWrap: "wrap", rowGap: "8px" }}>
          <SkeletonBlock width={150} height={24} borderRadius={4} delayNumber={5} />
        </FlexDiv>
      </HeaderRightCell>

      <HeaderRightCell>
        <HeaderItemTitle>Phase</HeaderItemTitle>
        <FlexDiv align="center" direction="row" gap={4} style={{ flexWrap: "wrap", rowGap: "8px" }}>
          <SkeletonBlock width={150} height={24} borderRadius={4} delayNumber={7} />
        </FlexDiv>
      </HeaderRightCell>
    </HeaderRightContainer>
  );
};

const ResponseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BottomRunBarContainer = styled.div`
  background: ${theme.WHITE_COLOR};
  border-top: 1px solid ${theme.NEUTRAL200};
  margin-top: 24px;
  width: 100%;
`;

const BottomRunBarContent = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 0 24px;
  background: ${theme.WHITE_COLOR};
  width: 100%;
`;

const RightAlignedControls = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ABTestContainer = styled.div`
  display: none;
  align-items: center;
  gap: 12px;
`;

const ABTestLabel = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  font-weight: 500;
  color: ${theme.BLACK_COLOR};
`;

const RunButtonContainer = styled(ButtonContainer)`
  width: 140px;
  padding: 0px;
`;

const TemplatePanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.WHITE_COLOR};
  border-radius: 8px;
`;

const TemplatePanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TemplatePanelTitle = styled.h4`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

const TemplateDescription = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  color: ${theme.NEUTRAL500};
  margin: 0;
  white-space: pre-line;
  line-height: 1.4;
`;

const TokenText = styled.span`
  font-weight: 600;
`;

const HeaderRightCell = styled(FlexDiv)`
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const DummyPill = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 24px;
  padding: 4px 8px;
  background-color: ${theme.fill.brand.secondary};
  border-radius: 4px;
  width: max-content;
  max-width: 100%;
  gap: 8px;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 8px;
  font-weight: 600;
  color: ${theme.text.brand.primary};
  cursor: pointer;
`;

const DashboardAI: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultConferenceId = queryParams.get("conference_id") || "";

  const [conferenceId, setConferenceId] = useState(defaultConferenceId);
  const [showAllParticipants, setShowAllParticipants] = useState(false);
  const { data: conferenceInfoData, loading: conferenceInfoLoading, error: conferenceInfoError } = useQuery(
    FETCH_CONFERENCE_INFO,
    {
      variables: { conference_id: conferenceId },
      skip: !conferenceId,
    },
  );

  interface Participant {
    name: string;
    profile_image: string;
    type: "user" | "lead";
    id: string;
    associate_parent_id: string;
  }

  const participants: Participant[] = useMemo(() => {
    const allUserParticipants =
      conferenceInfoData?.fetchConference?.users?.map((user: any) => ({
        name: user.full_name,
        profile_image: user.profile_image,
        type: "user",
        id: user.id,
      })) || [];

    const leadParticipant = conferenceInfoData?.fetchConference?.lead
      ? [
          {
            name: conferenceInfoData.fetchConference.lead.full_name,
            profile_image: conferenceInfoData.fetchConference.lead.profile_image,
            type: "lead",
            id: conferenceInfoData.fetchConference.lead.id,
            associate_parent_id: conferenceInfoData.fetchConference.lead.associate_parent_id,
          },
        ]
      : [];

    const allParticipants = [...allUserParticipants, ...leadParticipant];

    const filterOutDuplicateParticipants = allParticipants.filter(
      (participant, index, self) => index === self.findIndex((t: Participant) => t.name === participant.name),
    );

    return filterOutDuplicateParticipants;
  }, [conferenceInfoData]);

  const [systemPrompt, setSystemPrompt] = useState("");
  const [userPrompt, setUserPrompt] = useState("{call_transcript}");
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateOption | null>(null);
  const [isEditingTemplate, setIsEditingTemplate] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [isTemplateModified, setIsTemplateModified] = useState(false);
  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [abTestEnabled, setAbTestEnabled] = useState(false);

  const leftPanelRef = useRef<HTMLDivElement>(null);
  const systemPromptRef = useRef<HTMLTextAreaElement>(null);
  const userPromptRef = useRef<HTMLTextAreaElement>(null);

  const { data: templatesData, loading: templatesLoading, error: templatesError } = useQuery(
    FETCH_CONFERENCE_AI_TEMPLATES,
    {
      variables: {
        only_own_templates: false,
        limit: 200,
        offset: 0,
      },
      fetchPolicy: "network-only",
      onError: (error) => {
        Sentry.captureException(error);
        appToast("Failed to fetch templates", { type: "error" });
      },
    },
  );

  const templates = React.useMemo(() => {
    if (!templatesData?.fetchConferenceAiTemplates) return [];
    return templatesData.fetchConferenceAiTemplates.map((template: any) => ({
      value: template.id,
      label: template.name,
      name: template.name,
      systemPrompt: template.system_prompt,
      userPrompt: template.user_prompt,
    }));
  }, [templatesData]);

  const { data, loading, error, refetch, networkStatus } = useQuery(TEST_AI_CALL_REPORT, {
    variables: {
      conference_id: "74617f0a-e3cc-40a3-930c-7247115e9820",
      system_prompt: "test",
      user_prompt: "test",
    },
    notifyOnNetworkStatusChange: true,
  });

  const [createTemplate] = useMutation(CREATE_CONFERENCE_AI_TEMPLATE, {
    onCompleted: () => {
      appToast("Template created successfully", { type: "success" });
    },
    onError: (error) => {
      Sentry.captureException(error);
      appToast(error.message || "Failed to create template", { type: "error" });
    },
    refetchQueries: [{ query: FETCH_CONFERENCE_AI_TEMPLATES }],
  });

  const [updateTemplate] = useMutation(UPDATE_CONFERENCE_AI_TEMPLATE, {
    onCompleted: () => {
      appToast("Template updated successfully", { type: "success" });
    },
    onError: (error) => {
      Sentry.captureException(error);
      appToast(error.message || "Failed to update template", { type: "error" });
    },
    refetchQueries: [{ query: FETCH_CONFERENCE_AI_TEMPLATES }],
  });

  const handleRun = async () => {
    if (!conferenceId) {
      appToast("Please enter a conference ID", { type: "error" });
      return;
    }
    try {
      await refetch({
        conference_id: conferenceId,
        system_prompt: systemPrompt.trim(),
        user_prompt: userPrompt.trim(),
      });
    } catch (error) {
      Sentry.captureException(error);
      appToast("Failed to generate response", { type: "error" });
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConferenceId(e.target.value);
  };

  const handleSystemPromptChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setSystemPrompt(e.target.value);
    checkTemplateModified(e.target.value, userPrompt);
  };

  const handleUserPromptChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUserPrompt(e.target.value);
    checkTemplateModified(systemPrompt, e.target.value);
  };

  const checkTemplateModified = (currentSystemPrompt: string, currentUserPrompt: string) => {
    if (!selectedTemplate) {
      setIsTemplateModified(false);
      return;
    }

    const isModified =
      currentSystemPrompt !== selectedTemplate.systemPrompt || currentUserPrompt !== selectedTemplate.userPrompt;

    setIsTemplateModified(isModified);
  };

  const handleSaveTemplate = async () => {
    if (!selectedTemplate || !isTemplateModified) return;

    try {
      await updateTemplate({
        variables: {
          id: selectedTemplate.value,
          system_prompt: systemPrompt.trim(),
          user_prompt: userPrompt.trim(),
        },
      });
      setIsTemplateModified(false);
    } catch (error) {
      // Error handling is already implemented in the mutation
    }
  };

  const handleTemplateChange = (selected: TemplateOption | null) => {
    setSelectedTemplate(selected);
    if (selected) {
      setTemplateName(selected.name);
      setSystemPrompt(selected.systemPrompt || "");
      setUserPrompt(selected.userPrompt || "");
      setIsTemplateModified(false);
    }
  };

  const handleSaveAs = async () => {
    if (!newTemplateName.trim()) {
      appToast("Please enter a template name", { type: "error" });
      return;
    }

    try {
      await createTemplate({
        variables: {
          name: newTemplateName.trim(),
          system_prompt: systemPrompt.trim(),
          user_prompt: userPrompt.trim(),
        },
      });
      setShowSaveAsModal(false);
      setNewTemplateName("");
    } catch (error) {
      // Error handling is already implemented in the mutation
    }
  };

  return (
    <Container>
      <DashboardContainer>
        <HeaderBorder>
          <HeaderContainer>
            <FlexDiv align="center" gap={8}>
              <Title>ButchGPT Prompt Collection Manager</Title>
            </FlexDiv>
            {conferenceInfoLoading ? (
              <HeaderLoader />
            ) : (
              <HeaderRightContainer align="flex-start" gap={40}>
                <HeaderRightCell>
                  <HeaderItemTitle>Conference ID</HeaderItemTitle>
                  <FlexDiv align="flex-start" direction="row" gap={8}>
                    <PhoenixIcon svg={sound_wave} variant="brand" size={16} />
                    <AppText color={theme.text.neutral.primary} fontSize={12} fontWeight={400}>
                      {conferenceId}
                    </AppText>
                  </FlexDiv>
                </HeaderRightCell>
                <HeaderRightCell>
                  <HeaderItemTitle>Call Disposition</HeaderItemTitle>
                  <FlexDiv align="center" direction="row" gap={4}>
                    <PersonSpokeToPill
                      person={
                        conferenceInfoData?.fetchConference?.call_result?.computed_call_history_theme
                          ?.completed_with_theme?.label ?? "NA"
                      }
                      backgroundColorOverride={
                        conferenceInfoData?.fetchConference?.call_result?.computed_call_history_theme
                          ?.completed_with_theme?.bg_color ?? theme.PILL_LIGHT_GRAY
                      }
                      colorOverride={
                        conferenceInfoData?.fetchConference?.call_result?.computed_call_history_theme
                          ?.completed_with_theme?.font_color ?? "#000000"
                      }
                    />

                    <CallResultPill
                      result={
                        conferenceInfoData?.fetchConference?.call_result?.computed_call_history_theme?.call_result_theme
                          ?.label ?? "NA"
                      }
                      backgroundColorOverride={
                        conferenceInfoData?.fetchConference?.call_result?.computed_call_history_theme?.call_result_theme
                          ?.bg_color ?? theme.PILL_LIGHT_GRAY
                      }
                      colorOverride={
                        conferenceInfoData?.fetchConference?.call_result?.computed_call_history_theme?.call_result_theme
                          ?.font_color ?? theme.BLACK_COLOR
                      }
                    />
                    {conferenceInfoData?.fetchConference?.call_result?.related_disposition_reason && (
                      <DispositionReasonPill
                        reason={conferenceInfoData?.fetchConference?.call_result?.related_disposition_reason}
                        disposition={conferenceInfoData?.fetchConference?.call_result?.label || ""}
                      />
                    )}
                  </FlexDiv>
                </HeaderRightCell>
                <HeaderRightCell>
                  <HeaderItemTitle>Speakers</HeaderItemTitle>
                  <FlexDiv align="center" direction="row" gap={4}>
                    {participants.map((participant, index) => {
                      if (index > 0 && !showAllParticipants) {
                        return null;
                      }

                      if (participant.type === "lead") {
                        return (
                          <LeadPill
                            key={participant.name}
                            leadName={participant.name}
                            isAssociatedLead={!!participant?.associate_parent_id}
                          />
                        );
                      } else {
                        return (
                          <UserPill
                            key={participant.name}
                            userName={participant.name}
                            cloudinaryId={participant.profile_image || ""}
                          />
                        );
                      }
                    })}

                    {!showAllParticipants && participants?.length > 1 && (
                      <DummyPill onClick={() => setShowAllParticipants(true)}>+ {participants?.length - 1}</DummyPill>
                    )}
                  </FlexDiv>
                </HeaderRightCell>
                <HeaderRightCell>
                  <HeaderItemTitle>Phase</HeaderItemTitle>
                  <FlexDiv align="center" direction="row" gap={4}>
                    <CallResultPill
                      result={
                        conferenceInfoData?.fetchConference?.call_result?.phase ||
                        conferenceInfoData?.fetchConference?.call_result?.phase_label ||
                        ""
                      }
                    />
                  </FlexDiv>
                </HeaderRightCell>
              </HeaderRightContainer>
            )}
          </HeaderContainer>
        </HeaderBorder>
        <ContentWrapper>
          <TemplatePanel
            name="Test A"
            templates={templates}
            selectedTemplate={selectedTemplate}
            handleTemplateChange={handleTemplateChange}
            templatesLoading={templatesLoading}
            systemPrompt={systemPrompt}
            userPrompt={userPrompt}
            systemPromptRef={systemPromptRef}
            userPromptRef={userPromptRef}
            handleSystemPromptChange={handleSystemPromptChange}
            handleUserPromptChange={handleUserPromptChange}
            isEditingTemplate={isEditingTemplate}
            handleSaveTemplate={handleSaveTemplate}
            isTemplateModified={isTemplateModified}
            setShowSaveAsModal={setShowSaveAsModal}
            data={data}
            loading={loading}
            error={error}
          />
          <BottomRunBar
            handleRun={handleRun}
            loading={loading}
            abTestEnabled={abTestEnabled}
            onAbTestToggle={setAbTestEnabled}
          />
        </ContentWrapper>

        <Modal open={showSaveAsModal} onClose={() => setShowSaveAsModal(false)} style={{ width: 400 }}>
          <FlexDiv
            direction="column"
            gap={16}
            style={{ marginTop: 24, marginBottom: 16, marginLeft: 40, marginRight: 40 }}
          >
            <Title style={{ fontSize: 18, marginBottom: 8 }}>Edit Template Name</Title>
            <div>
              <InputLabel>
                Template Name
                <RequiredIndicator>*</RequiredIndicator>
              </InputLabel>
              <PhoenixInput
                value={newTemplateName}
                onChange={(e) => setNewTemplateName((e.target as HTMLInputElement)?.value)}
                placeholder="Enter template name"
              />
            </div>
            <FlexDiv gap={8} justify="flex-end">
              <PhoenixAppButton
                buttonType="primary"
                variant="danger-outline"
                uppercase
                buttonTextFontSize={10}
                style={{ letterSpacing: "1px" }}
                onClick={() => setShowSaveAsModal(false)}
              >
                Cancel
              </PhoenixAppButton>
              <PhoenixAppButton
                buttonType="primary"
                variant="brand"
                onClick={handleSaveAs}
                disabled={!newTemplateName.trim()}
              >
                Save
              </PhoenixAppButton>
            </FlexDiv>
          </FlexDiv>
        </Modal>
      </DashboardContainer>
    </Container>
  );
};

const HeaderRightContainer = styled(FlexDiv)``;

export { DashboardAI };
