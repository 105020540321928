import { gql, useQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { loggedInUser } from "../../apollo/cache";
import { ModalContext } from "../../context";
import { MixpanelActions } from "../../services/mixpanel";
import { SystemConfigNavShape } from "../../types";
import { theme } from "../../utils/theme";
import { STEP_SYSVIEW_PERSIST_KEY } from "../../utils/variables";
import {
  BulkReassignComponent,
  LeadFunnelComponent,
  LeadPoolsComponent,
  LeadSearchPage,
} from "../Segments/SystemView/";
import { AppText } from "../UI";
import { SaveToFolderModal } from "../modal";
import { LeadSearchFiltersModal } from "../Segments/SystemView/LeadSearchFIltersModal";
import { createSystemViewNav } from "src/utils/misc";
import { VoicemailDrops } from "./VoicemailDrops";

const ORGANIZATION_DATA = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      show_system_view
      show_rep_my_leads
      show_rep_all_leads
      reps_can_bulk_unassign
    }
  }
`;
interface ParamTypes {
  tab_name?: string;
  tab_type_id?: string;
}

const SystemView: React.FC = () => {
  const [step, setStep] = useState(1);
  const { voicemailDrops } = useFlags();
  const {
    saveCallToLibraryModal,
    setSaveCallToLibraryModal,
    currentConferenceID,
    currentTab,
    setCurrentTab,
    setShowTabNav,
    showFiltersModal,
  } = useContext(ModalContext);
  const { tab_name } = useParams<ParamTypes>()

  const { showLeadPool, showNewLeadSearch } = useFlags();

  const history = useHistory();

  const { data: dataOrg, loading: loadingOrg, error: errorOrg, refetch: refetchOrg } = useQuery(ORGANIZATION_DATA, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const tabNavData = useMemo(() => {
    return createSystemViewNav({
      userRole: loggedInUser().role || "",
      showLeadPool: showLeadPool || false,
      dataOrg: dataOrg || {},
      voicemailDrops: false,
    });
  }, [dataOrg, showLeadPool, loggedInUser().role]);

  useEffect(() => {
    setShowTabNav(true);
    if (!dataOrg) return;
    if (!tab_name) {
      if (dataOrg?.fetchOrganization?.show_rep_all_leads) {
        history.replace("all-leads");
        setCurrentTab("all-leads");
      } else if (dataOrg?.fetchOrganization?.show_rep_my_leads) {
        history.replace("my-leads");
        setCurrentTab("my-leads");
      }
    }

    return () => {
      setShowTabNav(false);

      if (currentTab) {
        setCurrentTab(undefined);
      }
    };
  }, [dataOrg]);

  useEffect(() => {
    setCurrentTab(tab_name);
  }, [tab_name]);

  // voicemail drops completly ignores the parent container styling of all leads/my leads
  if (tab_name === "voicemail-drops") {
    return <VoicemailDrops />;
  }

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
      {showNewLeadSearch && showFiltersModal && <LeadSearchFiltersModal />}
      <OnboardingColorWrapper>
        {saveCallToLibraryModal && (
          <SaveToFolderModal
            blinds={saveCallToLibraryModal}
            setBlinds={setSaveCallToLibraryModal}
            conferenceID={currentConferenceID}
          />
        )}
        <Main>
          <SimpleFlexDiv>
            <StepLocationText>
              {!!tabNavData?.length &&
                tabNavData[tabNavData.findIndex((step: any) => step.tab_name == tab_name)]?.title}
            </StepLocationText>
            {/* <QuestionMarkMoreInfo data-tip={stepDescription[step - 1].description}>?</QuestionMarkMoreInfo> */}
            <div />
          </SimpleFlexDiv>
          <ContentContainer>
            {tab_name === "lead-pool" && !!showLeadPool && <LeadPoolsComponent step={step} />}
            {tab_name === "lead-pool" && !showLeadPool && <>Under maintenance</>}
            {tab_name === "lead-funnel" && <LeadFunnelComponent step={step} />}
            {((loggedInUser().role === "AE" && dataOrg?.fetchOrganization?.show_rep_all_leads) ||
              (loggedInUser().role === "SDR" && dataOrg?.fetchOrganization?.show_rep_all_leads) ||
              (loggedInUser().visible_all_leads_sm && loggedInUser().role === "SM") ||
              loggedInUser().role === "ADMIN") &&
              tab_name === "all-leads" &&
              (showNewLeadSearch ? (
                <LeadSearchPage
                  my_leads={false}
                  display_bulk_unassign={
                    loggedInUser().role === "ADMIN" ||
                    loggedInUser().role === "SM" ||
                    !!dataOrg?.fetchOrganization?.reps_can_bulk_unassign
                  }
                />
              ) : (
                <BulkReassignComponent
                  my_leads={false}
                  display_bulk_unassign={
                    loggedInUser().role === "ADMIN" ||
                    loggedInUser().role === "SM" ||
                    !!dataOrg?.fetchOrganization?.reps_can_bulk_unassign
                  }
                />
              ))}
            {((loggedInUser().role === "AE" && dataOrg?.fetchOrganization?.show_rep_my_leads) ||
              (loggedInUser().role === "SDR" && dataOrg?.fetchOrganization?.show_rep_my_leads) ||
              loggedInUser().role === "ADMIN") &&
              tab_name === "my-leads" &&
              (showNewLeadSearch ? (
                <LeadSearchPage
                  my_leads={true}
                  display_bulk_unassign={
                    loggedInUser().role === "ADMIN" ||
                    loggedInUser().role === "SM" ||
                    !!dataOrg?.fetchOrganization?.reps_can_bulk_unassign
                  }
                />
              ) : (
                <BulkReassignComponent
                  my_leads={true}
                  display_bulk_unassign={
                    loggedInUser().role === "ADMIN" ||
                    loggedInUser().role === "SM" ||
                    !!dataOrg?.fetchOrganization?.reps_can_bulk_unassign
                  }
                />
              ))}
          </ContentContainer>
        </Main>
      </OnboardingColorWrapper>
    </Sentry.ErrorBoundary>
  );
};

const OnboardingColorWrapper = styled.div`
  background-color: ${theme.surface.neutral.tertiary};
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const StepLocationText = styled(AppText)`
  font-weight: 600;
  font-size: 22px;
`;

const SimpleFlexDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex: 0 0 auto;
  margin-bottom: 16px;
`;

const ContentContainer = styled.div`
  flex: 1 1 auto;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Main = styled.div<ModalOnProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: ${theme.WHITE_COLOR};
  padding: 24px 40px;
  border-radius: 8px;
`;

export { SystemView };
