import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { ColDef, GetRowIdFunc, GetRowIdParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import styled from "styled-components";
import { CustomFieldType } from "../../../../__generated__/graphql";
import { edit, eye, eye_off, reorder, trash } from "../../../../images/NewDesign";
import { theme } from "../../../../utils/theme";
import { appToast } from "../../../../utils/toast";
import { AppText, Loading } from "../../../UI";
import { PhoenixAppButton, PhoenixEyeToggle, PhoenixIcon } from "../../../UI/Phoenix";
import { DeleteFieldV2, UpdateCustomFieldV2 } from "../../../modal";
import { LeadCustomEmptyPage } from "./LeadCustomFieldEmptyTable";
import "./ObjectManager.css";
import { getUpIconHTML, getDownIconHTML } from "../../../../utils/misc";
import { useFirstRender } from "../../../../utils/hooks";

interface DisappearingDivProps {
  togglePageTitle?: () => void;
}

const FETCH_CUSTOM_FIELDS = gql`
  query FetchCustomFields {
    fetchCustomFields {
      id
      key
      type
      allow_reps_to_edit
      visible
      options
    }
  }
`;
const CHANGE_VISIBILITY = gql`
  mutation UpdateOneCustomField(
    $customFieldId: String!
    $key: String
    $type: CustomFieldType
    $allowRepsToEdit: Boolean
    $visible: Boolean
    $options: [String]
  ) {
    updateOneCustomField(
      custom_field_id: $customFieldId
      key: $key
      type: $type
      allow_reps_to_edit: $allowRepsToEdit
      visible: $visible
      options: $options
    ) {
      id
      key
      type
      visible
      allow_reps_to_edit
    }
  }
`;

const DELETE_CUSTOM_FIELD = gql`
  mutation DeleteCustomField($id: String!) {
    deleteCustomField(id: $id) {
      type
      key
      value
      id
      options
      allow_reps_to_edit
    }
  }
`;

const LeadCustomFieldTable: React.FC<DisappearingDivProps> = ({}) => {
  const isInitialLoad = useFirstRender();
  const gridRef = useRef<AgGridReact>(null);

  const defaultField = {
    id: "",
    keyName: "",
    type: "",
    value: "",
    options: [],
    allow_reps_to_edit: false,
    visible: true,
  };

  const [blinds, setBlinds] = useState(false);
  const [deleteBlinds, setDeleteBlinds] = useState(false);
  const [rowData, setRowData] = useState<CustomFieldData[]>([]);
  const [modalField, setModalField] = useState(defaultField);

  const history = useHistory();

  const [changeVisibility] = useMutation(CHANGE_VISIBILITY, {
    onCompleted({}) {
      appToast("Updated custom field.");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchCustomFields"],
  });

  const { data, loading, error, refetch } = useQuery(FETCH_CUSTOM_FIELDS, {
    fetchPolicy: "network-only",
  });

  const [deleteOneCustomField, { loading: loadingDelete }] = useMutation(DELETE_CUSTOM_FIELD, {
    onCompleted({ deleteOneCustomField }) {
      console.log("Delete custom field: ", deleteOneCustomField);
      appToast("Custom field deleted");
      if (!deleteOneCustomField) {
        return;
      }
    },
    onError({ message }) {
      console.log("Error in deleteOneCustomField: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `deleteOneCustomField GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["FetchCustomFields"],
  });

  const ManageCellRenderer = (params: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center", height: "40px", gap: "8px" }}>
        <PhoenixIcon
          svg={edit}
          size={16}
          onClick={() => {
            handleEdit(params);
          }}
        />
        <PhoenixIcon
          svg={trash}
          variant="danger"
          size={16}
          onClick={() => {
            handleDelete(params);
          }}
        />
      </div>
    );
  };
  const SwitchRenderer = (param: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center", height: "40px", gap: "8px" }}>
        <Switch
          checked={param?.data?.allow_reps_to_edit}
          onChange={(check) => {
            changeVisibility({
              variables: {
                customFieldId: param.data.id,
                allowRepsToEdit: check,
              },
            });
          }}
          onColor={theme.PRIMARY500}
          height={16}
          width={32}
          checkedIcon={false}
          uncheckedIcon={false}
          handleDiameter={12}
        />
      </div>
    );
  };

  useEffect(() => {
    if (data?.fetchCustomFields) {
      const rowData = data.fetchCustomFields || [];
      const alphabeticalRowData = [...rowData].sort((a: any, b: any) => a?.key.localeCompare(b?.key));
      setRowData(alphabeticalRowData);
    }
  }, [data]);

  const EyeRenderer = (props: any) => {
    const visible = props.data.visible;

    return (
      <PhoenixEyeToggle
        visible={visible}
        height={40}
        onChange={() => {
          changeVisibility({
            variables: {
              customFieldId: props.data.id,
              visible: !visible,
            },
          });
        }}
      />
    );
  };

  const handleEdit = (params: any) => {
    const data = params.data;
    setModalField({ ...data, keyName: data.key });
    setBlinds(true);
  };

  const handleDelete = (params: any) => {
    const data = params.data;
    setModalField({ ...data, keyName: data.key });
    setDeleteBlinds(true);
  };

  const renderModal = () => {
    if (blinds) {
      return (
        <UpdateCustomFieldV2
          blinds={blinds}
          is_custom_object={false}
          close={() => {
            setBlinds(false);
          }}
          {...modalField}
        />
      );
    }
    if (deleteBlinds) {
      return (
        <DeleteFieldV2
          customFields
          fieldType={`Custom Field: ${modalField.keyName}`}
          warningText={
            "Warning: If this custom field is used in a template, the token will be replaced with an empty value. For the best experience, review and remove this custom field from any templates where it's used."
          }
          loading={loadingDelete}
          blinds={deleteBlinds}
          close={() => setDeleteBlinds(false)}
          id={modalField.id}
          onDeleteFunction={async () => {
            await deleteOneCustomField({
              variables: {
                id: modalField.id,
              },
            });
          }}
        />
      );
    }
  };

  const colDefs = [
    {
      headerName: "Custom Field Name",
      field: "key",
      flex: 2,
      headerClass: "ag-object-manager-header",
      rowDrag: true,
      sortable: true,
    },
    {
      headerName: "Allow Reps to Edit",
      field: "allow_reps_to_edit",
      flex: 2,
      headerClass: "ag-object-manager-header",
      sortable: true,
      cellRenderer: SwitchRenderer,
    },
    {
      headerName: "Visibility",
      field: "visible",
      flex: 1,
      headerClass: "ag-object-manager-header",
      sortable: true,
      cellRenderer: EyeRenderer,
    },
    {
      headerName: "Custom Field Type",
      field: "type",
      flex: 2,
      headerClass: "ag-object-manager-header",
      sortable: true,
    },
    {
      headerName: "Manage Field",
      field: "manage",
      cellRenderer: ManageCellRenderer,
      flex: 2,
      headerClass: "ag-object-manager-header",
    },
  ];

  const repeatedCustomFieldKeys = rowData
    .map((item: CustomFieldData) => item?.key)
    .map((key: string) => {
      const count = rowData.filter((item: CustomFieldData) => item.key?.trim() === key?.trim()).length;
      return { key, count };
    })
    .filter((item: { key: string; count: number }) => item.count > 1)
    .filter((item, index, self) => self.findIndex((t: any) => t.key?.trim() === item.key?.trim()) === index);

  return (
    <BGContainer row_len={rowData.length}>
      {renderModal()}
      {!rowData.length ? (
        <LeadCustomEmptyPage
          blinds={blinds}
          setBlinds={setBlinds}
          defaultField={defaultField}
          setModalField={setModalField}
          type="Custom Field"
        />
      ) : (
        <SpaceContainer>
          {renderModal()}
          <PhoenixAppButton
            buttonType="secondary"
            onClick={() => {
              setModalField(defaultField);
              setBlinds(true);
            }}
          >
            ADD CUSTOM FIELD
          </PhoenixAppButton>
          {repeatedCustomFieldKeys?.length > 0 && (
            <>
              <AppText
                fontSize={14}
                fontWeight={500}
                variant="error"
                style={{ marginTop: "16px", marginBottom: "8px" }}
              >
                Warning
              </AppText>
              <AppText variant="error">The following field names are being used multiple times.</AppText>
              <AppText variant="error" style={{ marginBottom: "8px" }}>
                Please contact support to help fix your configuration.
              </AppText>
              {repeatedCustomFieldKeys.map((item: any) => (
                <AppText key={`duplicate-${item.key}-${item.count}`} variant="error">
                  Field name "{item.key}" is used {item.count} times.
                </AppText>
              ))}
            </>
          )}
          <div className="ag-theme-object-manager" style={{ minHeight: 500, width: "100%", padding: "24px 0" }}>
            {loadingDelete || (isInitialLoad && loading) ? (
              <Loading />
            ) : (
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={colDefs}
                rowDragEntireRow={true}
                suppressMovableColumns={true}
                suppressDragLeaveHidesColumns={true}
                tooltipMouseTrack={true}
                rowDragManaged
                animateRows={true}
                enableCellChangeFlash={true}
                // to do refactor this to work with PhoenixIcon (didn't work)
                icons={{
                  sortAscending: getUpIconHTML(),
                  sortDescending: getDownIconHTML(),
                }}
                suppressScrollOnNewData
              />
            )}
          </div>
        </SpaceContainer>
      )}
    </BGContainer>
  );
};

const BGContainer = styled.div<{ row_len: number }>`
  display: flex;
  width: 100%;
  height: 100%;
  background: ${(props) => (props.row_len === 0 ? theme.NEUTRAL100 : theme.WHITE_COLOR)};
`;

const SpaceContainer = styled.div`
  display: flex;
  margin: 24px;
  width: 100%;
  flex-direction: column;
`;
const VisibilityText = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-left: 8px;
`;

const EyeDiv = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

const EmptyCustomField = styled.div`
  background: ${theme.NEUTRAL100};
  width: 100%;
  height: 100%;
`;

const EmptyPageTextContainer = styled.div`
  width: 387px;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 200px;
  align-items: center;
`;
type CustomFieldData = {
  id: string;
  key: string;
  type: CustomFieldType;
  allow_reps_to_edit: boolean;
  visible: boolean;
  options: string[];
};

export { LeadCustomFieldTable };
