import React from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppErrorText, AppText, SkeletonBlock } from "../../UI";
import { PhoenixAppButton, PhoenixMultiSelect } from "../../UI/Phoenix";

import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";
import { PhoenixInputField } from "src/Components/Field/Phoenix";
import { useFirstRender } from "src/utils/hooks";
import { appToast } from "src/utils/toast";
import * as Yup from "yup";

const UPDATE_ORGANIZATION_SETTINGS = gql`
  mutation CreateOrUpdateOrganizationTokens($address: String!, $name: String!) {
    createOrUpdateOrganizationTokens(address: $address, name: $name) {
      address
      name
    }
  }
`;

const FETCH_ORGANIZATION_SETTINGS = gql`
  query FetchOrganizationSettings {
    fetchOrganization {
      organizationTokens {
        address
        name
      }
    }
  }
`;

export const StepOrganizationSettings: React.FC = () => {
  const {
    data: organizationSettings,
    loading: organizationSettingsLoading,
    error: organizationSettingsError,
  } = useQuery(FETCH_ORGANIZATION_SETTINGS, {
    fetchPolicy: "network-only",
    onError: (error) => {
      console.error(error);
      appToast("Unable to fetch organization settings");
    },
  });

  const [updateOrganizationSettings, { loading: updateOrganizationSettingsLoading }] = useMutation(
    UPDATE_ORGANIZATION_SETTINGS,
    {
      onError: (error) => {
        console.error(error);
        appToast("Unable to update organization settings");
      },
      onCompleted: () => {
        appToast("Organization settings updated");
      },
    },
  );

  const globalLoading = organizationSettingsLoading;

  const globalError = organizationSettingsError;

  if (globalLoading)
    return (
      <Main>
        <SkeletonBlock height="600px" width="100%" borderRadius={8} />
      </Main>
    );

  if (globalError) return <AppErrorText>Unable to fetch organization settings</AppErrorText>;

  return (
    <Main>
      <Formik
        enableReinitialize
        initialValues={{
          address: organizationSettings?.fetchOrganization?.organizationTokens?.address || "",
          name: organizationSettings?.fetchOrganization?.organizationTokens?.name || "",
        }}
        validationSchema={Yup.object().shape({
          address: Yup.string().notRequired(),
          name: Yup.string().notRequired(),
        })}
        onSubmit={(values) => {
          updateOrganizationSettings({
            variables: {
              address: values.address,
              name: values.name,
            },
          });
        }}
      >
        {({ submitForm }) => (
          <>
            <Header>
              <AppText fontSize={22} fontWeight={500} lineHeight={28}>
                Organization Settings
              </AppText>
              <PhoenixAppButton variant="brand" buttonType="secondary" onClick={submitForm}>
                Save
              </PhoenixAppButton>
            </Header>

            <Body>
              <PhoenixStyledTooltip id="organization-tokens-tooltip" />
              <Container>
                <ContainerTitle>Organization Tokens</ContainerTitle>
                <AppText fontSize={12} fontWeight={400} color={theme.text.neutral.secondary}>
                  Update the tokens used in email, sms, and voicemail script templates.
                </AppText>
                <SpacerDiv height={24} />
                <FlexColumn>
                  <TokenInput name="name" label="Organization Name" placeholder="Organization Name" />
                  <TokenInput name="address" label="Organization Address" placeholder="Organization Address" />
                </FlexColumn>
              </Container>
            </Body>
          </>
        )}
      </Formik>
    </Main>
  );
};

const FlexColumn = styled.div`
  display: flex;
  gap: 16px;
`;

const TokenInput = ({
  name,
  label,
  placeholder,
  disabled,
}: {
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
}) => {
  return (
    <PhoenixInputField
      name={name}
      label={label}
      placeholder={placeholder}
      width={376}
      titleText={label}
      titleTextSpacing={8}
      titleTextFontSize={12}
      titleTextFontWeight={500}
      disabled={disabled}
    />
  );
};

const TokenSelect = ({
  name,
  label,
  placeholder,
  options,
  value,
  setFieldValue,
  disabled,
}: {
  name: string;
  label: string;
  placeholder: string;
  options: any[];
  value: string;
  setFieldValue: (name: string, value: string) => void;
  disabled?: boolean;
}) => {
  return (
    <PhoenixMultiSelect
      name={name}
      titleText={label}
      isMulti={false}
      placeholder={placeholder}
      options={options}
      onChange={(value: { label: string; value: string }) => setFieldValue(name, value.value)}
      value={options.find((option) => option.value === value)}
      width={376}
      titleTextSpacing={8}
      marginBottom={false}
      menuPortal
      isDisabled={disabled}
    />
  );
};

const Main = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Container = styled.div`
  width: 100%;
  background-color: ${theme.WHITE_COLOR};
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${theme.NEUTRAL300};
  margin-bottom: 16px;
`;

const ContainerTitle = styled(AppText)`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding: 24px 40px;
  flex: 0 0 auto;
  border-bottom: 1px solid ${theme.border.neutral.secondary};
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  min-height: 0;
  gap: 24px;
  flex-wrap: wrap;
  padding: 24px;
  background-color: ${theme.surface.brand.secondary};
  overflow-y: auto;
`;

const SpacerDiv = styled.div<{ height?: number | string }>`
  height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
  min-height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
`;
